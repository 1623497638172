<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <!-- <div class="w3-card openjobs-public p-3">
        <h3>Open Jobs </h3>
          <a href="#"> <p> Job 1 </p>
          <button class="btn btn-primary right"> Apply</button>
          </a>
          <a href="#"> <p> Job 1 </p></a>
          <a href="#"> <p> Job 1 </p></a>
          <a href="#"> <p> Job 1 </p></a>
          <a href="#"> <p> Job 1 </p></a>
          <hr>
          <a href="#"> <p> view all </p></a>

      </div> -->

        <div class="columns" v-for="pac in packageList" :key="pac.packageId">
          <ul class="price">
            <li class="header">{{ pac.packageName }}</li>
            <li class="grey">Sale Price: Rs. {{ pac.salePrice }}</li>
            <li>Regular Price: Rs. {{ pac.regularPrice }}</li>
            <li>Duration: {{ pac.expiredAfter }} {{ pac.dateConvert }}</li>
            <li v-if="pac.unlimitedPosting">Unlimited Jobs</li>
            <li v-else>{{ pac.jobPostingLimit }} no of Jobs</li>
            <li>Featured Job Limit: {{ pac.featuredJobLimit }}</li>
            <!-- <li>31st December 2021</li>  -->
            <li class="grey">
              <button
                @click="BuyPackage(pac.packageId)"
                :disabled="isLoading"
                class="button"
              >
                Buy Package
              </button>
            </li>
          </ul>
        </div>
        <!--
<div class="columns">
  <ul class="price">
    <li class="header" style="background-color:#e62e6b">Gold</li>
    <li class="grey">Rs. 7,500  </li>
    <li>2000 CVs</li>
    <li>30 Days</li>
     <li class="grey"><button @click="BuyPackage(2)" :disabled="isLoading" class="button">Buy</button></li>
  </ul>
</div>

<div class="columns">
  <ul class="price">
    <li class="header">Platinum</li>
 <li class="grey">Rs. 15,000  </li>
    <li>Unlimited CVs</li>
    <li>90 Days</li>
    <li class="grey"><button @click="BuyPackage(3)" :disabled="isLoading" class="button">Buy</button></li>
  </ul>
</div> -->
      </div>
    </div>

    <div class="style-spacer"></div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name:"BuyPackage",

 components: {},
  data() {
    return {
      isLoading : false ,
      packageList: [],
      body: {
              EmployeeId: this.$auth.user().employeeId,
              JobPostPackageId: null,
              PaymentMethodId: 0,
              TransactionId: "",
              InvoiceId: 0,
      }
    };
  },
  computed: {},
  mounted() {
    //
    this.getAllPackages()


  },
  methods: {

    BuyPackage(x) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.JobPostPackageId = Number(x)

       console.log(this.body)

       axios
            .post("/api/EmployerJobPostPackage/InsertEmployerJobPostPackage", this.body)
            .then(res => {
              // code that we will 'try' to run
              //this.info = res.data;

              //console.log("Add Response portion");

              //this.packageList = res.data.data
              console.log("response : ", res);

            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              this.isLoading = false
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("Purchase Sucessful! ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: 'Package',
                  solid: true
                });
                /*if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }*/

              }
            });



    },

    getAllPackages(){

      axios
        .post("/api/Job/GetAllPackage",{
          "pageSize": 0,
          "pageNumber": 1,
          "totalRecord": 0,
          "text": ""
        })
        .then((res) => {
          console.log(res)
          if (res.status != "200") {
            console.log("error.....");
          }
          else{
            this.packageList = res.data.data
            console.log(this.packageList)
          }


        })
        .catch((error) => {
        })
        .finally(() => {

        });



    },
  }
  };
</script>
<style scoped>
.columns * {
  box-sizing: border-box;
}

.columns {
  float: left;
  width: 33.3%;
  padding: 8px;
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
}

.price .header {
  background-color: #1a1f31;
  color: white;
  font-size: 25px;
}

.price li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}

.button {
  background-color: #72147e;
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}
button:disabled,
button[disabled] {
  background-color: #6d4e71;
  color: #ececec;
  cursor: not-allowed !important;
}
@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}
</style>
