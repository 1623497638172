<template>
  <div id="main-container" class="mt-5 mb-5 ml-auto mr-auto">
    <div class="container">
      <nav
        v-if="isDataLoaded"
        class="navbar navbar-expand-lg navbar-dark mt-30 filter-nav"
      >
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <a class="nav-item nav-link active" href="#" style="border: 0px"
              >{{ jobdata.jobTitle }} <span class="sr-only">(current)</span></a
            >
          </div>
        </div>
      </nav>
      <div class="row mt-30">
        <div class="col-lg-12 text-center">
          <span v-if="isLoading" class="mb-2">
            <b-spinner small></b-spinner>
          </span>
          <b-alert :show="isMessageDismissed" dismissible fade variant="info">
            No Applicant(s) Found...
          </b-alert>
        </div>
      </div>
      <div
              v-if="isDataLoaded"
              class="w3-card-2 pt-3 pb-3 ml-auto mr-auto mt-30"
            >
              <header class="w3-container custom-row">
                <h3>Applicants</h3>
              </header>
              <hr />

              <button
                v-b-modal.modal-2
                class="btn btn-secondary"
                type="button"
                style="
                  background: rgb(114, 20, 126);
                  border-color: rgb(115, 21, 128);
                  width: 100%;
                  margin-top: 15px;
                  margin-bottom: 15px;
                "
              >
                Candidates Filter
              </button>

              <div
                style="max-height: 350px; overflow-y: auto; position: relative"
              >
                <div
                  v-for="appli in applicants.slice().reverse()"
                  :key="appli.jobApplicants"
                >
                  <div class="w3-container custom-row">
                    <div>
                      <p
                        class="
                          font-weight-bold
                          w3-text-pink
                          m-0
                          text-capitalize
                        "
                      >
                        {{ appli.employeeName }}
                      </p>
                      <!--                  <span>{{appli.employeeName}}</span>-->
                      <p class="font-weight-bold text-black m-0">
                        <span v-if="appli.appliedDate === 0">Today</span>
                        <span v-else-if="appli.appliedDate === 1"
                          >{{ appli.appliedDate }} Day Ago</span
                        >
                        <span v-else>{{ appli.appliedDate }} Days Ago</span>
                      </p>
                    </div>
                    <div class="ml-auto">
                      <a
                        v-on:click="FetchEmployee(appli.employeeId)"
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary ml-auto"
                        >View</a
                      >
                      <!-- <a
                        v-if="isApplicantViewd == true"
                        v-on:click="FetchEmployee(appli.employeeId)"
                        href="javascript:void(0)"
                        class="btn btn-outline-secondary ml-auto"
                        >Viewed</a
                      >
                      <a
                      v-if="isApplicantViewd == false"
                        href="javascript:void(0)"
                        v-on:click="FetchEmployee(appli.employeeId)"
                        class="btn btn-outline-secondary ml-auto"
                        >View</a
                      > -->
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
      <div v-if="isDataLoaded" class="row no-gutters w-100 mt-2">
        <div class="col-lg-12">
          <div class="">
            <div
              class="w3-card-4 w3-center custom-profile-card"
              v-if="isEmpLoaded"
            >
              <!--              <div class="pt-4 pb-4">
                              <div>
                                <div class="custom-profile-image-wrap" v-if="emp.employeeImage != null">
                                  <img :src="url+emp.employeeImage" alt="" class="w3-circle custom-profile-image">
                                  &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;
                                  <div class="custom-change-profile-image-btn-wrap">

                                  </div>
                                </div>
                                <div class="custom-profile-image-wrap" v-else>
                                  <div v-if="emp.gender == 1">
                                    <img src="../../assets/images/MaleUser.png" alt="" class="w3-circle custom-profile-image">
                                  </div>
                                  <div v-else>
                                    <img src="../../assets/images/FemaleUser1.png" alt="" class="w3-circle custom-profile-image">
                                  </div>

                                  &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;
                                  <div class="custom-change-profile-image-btn-wrap">

                                  </div>
                                </div>

                              </div>


                              <div class=" w3-center custom-profile-detail">
                                <h5 class="font-weight-bold text-capitalize">

                                  {{ emp.fullName }}

                                </h5>

                                <section class="cd-intro">
                                  <h5 class="cd-headline loading-bar">

                                  <span class="cd-words-wrapper">
                                    &lt;!&ndash; <b class=" ">{{ exp.fullName }}</b> &ndash;&gt;
                                    <b v-for="exp in empdata.eMPExperiences" :key="exp.empExperienceId ">
                                      {{ exp.jobTitle }}
                                    </b>

                                  </span>
                                  </h5>

                                </section>
                                &lt;!&ndash;                <p class="">
                                                  <span v-for="exp in employeeExperience" :key="exp.empExperienceId">
                                                      <span>{{ exp.jobTitle }}</span>
                                                    </span>
                                                </p>&ndash;&gt;
                                <div class="custom-profile-contacts">
                                  <div class="">
                                    <a class="text-decoration-none w3-hover-text-light-gray" :href="'tel:'+emp.mobileNumber">
                                      <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>

                                      <span class="">{{ emp.mobileNumber }}</span>
                                    </a>


                                  </div>

                                  <div class=" ">
                                    <a class="text-decoration-none w3-hover-text-light-gray" :href="'mailto:'+emp.email">
                                      <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                                      <span>{{ emp.email }}</span>
                                    </a>
                                  </div>
                                </div>
                                <div class="custom-row justify-content-center mt-2 " >
                                  <a :href="emp.facebookLink" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link" v-if="emp.facebookLink">
                                    <i class="fab fa-facebook-f"></i>
                                  </a>

                                  <a :href="emp.googlePlusLink" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link" emp.googlePlusLink>
                                    <i class="fab fa-google"></i>
                                  </a>
                                  <a :href="emp.linkedInLink" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link" emp.linkedInLink>
                                    <i class="fab fa-linkedin-in"></i>
                                  </a>
                                  <a :href="emp.twitterLink" class="custom-twitter-icon custom-icon-style  custom-social-link" emp.twitterLink>
                                    <i class="fab fa-twitter"></i>
                                  </a>

                                </div>
                              </div>



                            </div>-->
              <div class="pt-4 pb-4">
                <div>
                  <div class="custom-profile-image-wrap">
                    <div v-if="emp.employeeImage != null">
                      <img
                        :src="url + emp.employeeImage"
                        alt=""
                        class="rounded-circle custom-profile-image"
                      />
                    </div>
                    <div v-else>
                      <img
                        src="../../assets/images/no-image.png"
                        alt=""
                        class="w3-circle custom-profile-image"
                      />
                    </div>
                    <!--                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">-->
                  </div>
                </div>

                <div class="w3-center custom-profile-detail">
                  <h5 class="font-weight-bold text-capitalize">
                    {{ emp.fullName }}
                  </h5>

                  <div class="text-capitalize">
                    {{ latestExperience.jobTitle }}
                  </div>
                  <!--                 <section class="cd-intro">
                                     <h5 class="cd-headline loading-bar">

                                      <span class="cd-words-wrapper">
                  &lt;!&ndash;                      <b class="is-visible">.Net Developer</b>&ndash;&gt;
                                        <b class="is-visible" v-for="exp in employeeExperience" :key="exp.empExperienceId ">
                                          {{ exp.jobTitle }}
                                        </b>

                                      </span>
                                     </h5>

                                   </section>-->

                  <div class="custom-profile-contacts">
                    <div class="">
                      <a
                        class="text-decoration-none w3-hover-text-light-gray"
                        :href="phoneNumberLink + mobileNumber"
                      >
                        <span
                          ><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i
                        ></span>
                        <!--                       <span>{{ code }}</span>
                                               <span>-</span>
                                               <span>{{ networkCode }}</span>
                                               <span>-</span>-->
                        <span class="">{{ mobileNumber }}</span>
                      </a>
                    </div>

                    <div class="">
                      <a
                        class="text-decoration-none w3-hover-text-light-gray"
                        :href="emailLink + emp.Email"
                      >
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span class="" style="overflow-wrap: break-word">{{
                          emp.email
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="custom-row justify-content-center mt-2">
                    <a
                      :href="'https://' + emp.facebookLink"
                      type="url"
                      target="_blank"
                      class="
                        custom-facebook-icon custom-icon-style
                        mr-2
                        custom-social-link
                      "
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>

                    <a
                      :href="'https://' + emp.googlePlusLink"
                      type="url"
                      target="_blank"
                      class="
                        custom-googleplus-icon custom-icon-style
                        mr-2
                        custom-social-link
                      "
                    >
                      <i class="fab fa-google"></i>
                    </a>
                    <a
                      :href="'https://' + emp.linkedInLink"
                      type="url"
                      target="_blank"
                      class="
                        custom-linkedin-icon custom-icon-style
                        mr-2
                        custom-social-link
                      "
                    >
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a
                      :href="'https://' + emp.twitterLink"
                      type="url"
                      target="_blank"
                      class="
                        custom-twitter-icon custom-icon-style custom-social-link
                      "
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="container" v-if="isEmpLoaded">
            <div id="MyPersonal" class="">
              <div class="custom-profile-heading">
                <span>Personal Information</span>
              </div>
              <div class="custom-underline"></div>
              <div class="custom-information-list mt-2">
                <div class="row">
                  <div class="col-md-5">
                    <ul class="custom-list">
                      <li>
                        <span class="font-weight-bold">Age: </span
                        ><span>{{ calculatedAge }}</span>
                      </li>
                      <li>
                        <span class="font-weight-bold">CNIC: </span
                        ><span>{{ emp.cnic }}</span>
                      </li>
                      <li>
                        <span class="font-weight-bold">Company: </span>

                        <span>{{ latestExperience.company }} </span>
                      </li>
                      <li>
                        <span class="font-weight-bold">Current Salary: </span
                        ><span>{{ emp.currentSalary }} PKR</span>
                      </li>
                      <li>
                        <span class="font-weight-bold">Domicile: </span
                        ><span>{{ emp.domicile }}</span>
                      </li>
                      <li v-if="emp.pDfName != null">
               
               <a
               class="custom-default-btn"
               :href="url + emp.pDfName"
               target="_blank"
               title="Download Assessment"
               >
               <b-icon
               class="mr-2"
               icon="download"
               ></b-icon
               >Download Assessment</a>
             </li>
             <li v-else>
               <span class="" target="_blank">No Assessment Attached by Employee</span>
             </li>
                    </ul>
                  </div>
                  <div class="col-md-7">
                    <ul class="custom-list">
                      <li>
                        <span class="font-weight-bold">Marital Status: </span>

                        <span>
                          {{ emp.maritalstatus }}
                        </span>
                      </li>
                      <li>
                        <span class="font-weight-bold">Degree Title: </span>

                        <span>{{ latestDegreeLevel.degreeTitle }}</span>
                      </li>
                      <li>
                        <span class="font-weight-bold">Experience: </span
                        ><span>{{ emp.yearOfExperience }} Years</span>
                      </li>
                      <li>
                        <span class="font-weight-bold">Expected Salary: </span
                        ><span>{{ emp.expectedSalary }} PKR</span>
                      </li>
                      <li>
                        <span class="font-weight-bold">City: </span>

                        <span>
                          {{ emp.city }}
                        </span>
                      </li>
                      <li v-if="emp.resume != null">
               
               <a
               class="custom-default-btn"
               :href="url + emp.resume"
               target="_blank"
               title="Download CV"
               >
               <b-icon
               class="mr-2"
               icon="download"
               ></b-icon
               >Download CV</a>
             </li>
             <li v-else>
               <span class="" target="_blank">No Cv Attached by Employee</span>
             </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <ul class="custom-list">
                  <li>
                    <span class="font-weight-bold">Category: </span>
                    <ul class="custom-list pl-4">
                      <li>
                        <span v-if="emp.graduate === 24">Graduate</span>
                        <span v-else></span>
                      </li>
                      <li>
                        <span v-if="emp.momReturn === 25"
                          >Mums Return To Work</span
                        >
                        <span v-else></span>
                      </li>
                      <li>
                        <span v-if="emp.specialNeedyPerson === 26"
                          >Persons With Disabilities (PWD)</span
                        >
                        <span v-else></span>
                      </li>
                      <li>
                        <span v-if="emp.labourManPower === 27"
                          >Labour Man Power</span
                        >
                        <span v-else></span>
                      </li>
                      <li>
                        <span v-if="emp.hecancy === 30">Hecancy</span>
                        <span v-else></span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <!--            <h5>Choose a Category:</h5>
                            <select
                                class="custom-select-option form-control form-control-sm"
                                v-model="selectedCategory"
                            >
                              <option
                                  v-for="option in categories"
                                  :key="option.jobTypeId"
                                  :value="option.jobTypeId"
                              >
                                {{ option.jobTypeTitle }}
                              </option>
                            </select>-->
              </div>
            </div>

            <div class="mb-3">
              <div id="MyCategories" class="">
                <div class="accordion" role="tablist">
                  <b-card no-body class="mb-1" v-if="emp.graduate == 24">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle.accordion-1
                        class="custom-bg-color"
                        >Graduate</b-button
                      >
                    </b-card-header>
                    <b-collapse
                      id="accordion-1"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <div class="custom-information-list mt-2">
                            <div class="row">
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Available For Internship:
                                    </span>
                                    <span>{{
                                      emp.availableDate | formatDate
                                    }}</span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Work Placement:
                                    </span>

                                    <span>
                                      {{ emp.graduateWorkPlacementType }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              class="row"
                              v-if="
                                emp.graduateWorkPlacementType ===
                                'Remote and Flexible'
                              "
                            >
                              <div class="col-md-12">
                                <ul class="custom-list">
                                  <li>
                                    <div class="font-weight-bold">
                                      Service Description:
                                    </div>
                                    <div class="text-justify">
                                      {{ emp.serviceDescription }}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Category:
                                    </span>

                                    <span>
                                      {{ emp.skillsCategoryId }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Sub-Category:
                                    </span>

                                    <span>
                                      {{ emp.subCategory }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-1" v-if="emp.momReturn == 25">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle.accordion-2
                        class="custom-bg-color"
                        >Mums Return To Work</b-button
                      >
                    </b-card-header>
                    <b-collapse
                      id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <div class="custom-information-list mt-2">
                            <div class="row">
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Employee Status:
                                    </span>
                                    <span
                                      v-for="status in employeeStatuses"
                                      :key="status.value"
                                    >
                                      <span
                                        v-if="
                                          emp.currentEmployementStatus ===
                                          status.value
                                        "
                                      >
                                        {{ status.text }}
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul class="custom-list"></ul>
                              </div>
                            </div>
                            <h6 class="font-weight-bold">Career Pause</h6>
                            <div class="row">
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Start Career:
                                    </span>
                                    <span>{{
                                      emp.startCareer | formatDate
                                    }}</span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >End Career:
                                    </span>
                                    <span>{{
                                      emp.endCareer | formatDate
                                    }}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <h6 class="font-weight-bold">Description</h6>
                            <div class="text-justify">
                              {{ emp.cpDescription }}
                            </div>
                            <div class="row">
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Work Hours:
                                    </span>
                                    <span
                                      v-for="wh in employementWorkHours"
                                      :key="wh.value"
                                    >
                                      <span
                                        v-if="
                                          emp.weeklyWorkingHour === wh.value
                                        "
                                      >
                                        {{ wh.text }}
                                      </span>
                                    </span>
                                  </li>
                                  <li>
                                    <span class="font-weight-bold">
                                      <!--                                  Willing to Commute Distance: -->
                                      Travel Preference:
                                    </span>
                                    <!--                                <span v-for="dis in kms" :key="dis.value">-->
                                    <span v-if="emp.travelMiles === 1">
                                      Yes
                                    </span>
                                    <span v-else-if="emp.travelMiles === 2">
                                      No
                                    </span>
                                    <span v-else> </span>
                                    <!--                                </span>-->
                                  </li>
                                  <!--                              <li>
                                                                  <span class="font-weight-bold">Willing to Commute Distance: </span>
                                                                  <span v-for="dis in miles" :key="dis.value">
                                                                    <span v-if="emp.travelMiles === dis.value">
                                                                      {{ dis.text }} miles
                                                                    </span>
                                                                  </span>
                                                                </li>-->
                                </ul>
                              </div>
                              <div class="col-md-6">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Work Place:
                                    </span>
                                    <span
                                      v-for="wp in employementWorkWhere"
                                      :key="wp.value"
                                    >
                                      <span
                                        v-if="emp.workLocation === wp.value"
                                      >
                                        {{ wp.text }}
                                      </span>
                                    </span>
                                  </li>
                                  <li>
                                    <span class="font-weight-bold"
                                      >Percentage of Time Travelling:
                                    </span>
                                    <span
                                      v-for="travel in travels"
                                      :key="travel.value"
                                    >
                                      <span
                                        v-if="
                                          emp.travelTimePercent === travel.value
                                        "
                                      >
                                        {{ travel.text }}
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card
                    no-body
                    class="mb-1"
                    v-if="emp.specialNeedyPerson == 26"
                  >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle.accordion-3
                        class="custom-bg-color"
                        >Persons With Disabilities (PWD’s)</b-button
                      >
                    </b-card-header>
                    <b-collapse
                      id="accordion-3"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <b-card-text>
                          <div class="custom-information-list mt-2">
                            <div class="row">
                              <div class="col-md-5">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Disability Information:
                                    </span>
                                    <span>{{
                                      emp.disabilityInformationTitle
                                    }}</span>
                                  </li>
                                  <li>
                                    <span class="font-weight-bold"
                                      >Disability Certificate:
                                    </span>
                                    <span v-if="emp.disabilityCertificate === 1"
                                      >Yes</span
                                    >
                                    <span
                                      v-else-if="
                                        emp.disabilityCertificate === 2
                                      "
                                      >No</span
                                    >
                                    <span v-else></span>
                                  </li>

                                  <li>
                                    <span class="font-weight-bold"
                                      >Knowledge of Sign Language:
                                    </span>
                                    <span v-if="emp.isKnowSignLangauge === 1"
                                      >Yes</span
                                    >
                                    <span
                                      v-else-if="emp.isKnowSignLangauge === 2"
                                      >No</span
                                    >
                                    <span v-else></span>
                                  </li>
                                  <li>
                                    <span class="font-weight-bold"
                                      >Special Assistance is required:
                                    </span>
                                    <span>{{
                                      emp.specialAssistanceRequirment
                                    }}</span>
                                  </li>
                                  <li>
                                    <span class="font-weight-bold"
                                      >Tranportation:
                                    </span>
                                    <span v-if="emp.fillBy === 1">Yes</span>
                                    <span v-else-if="emp.fillBy === 2">No</span>
                                    <span v-else></span>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-md-7">
                                <ul class="custom-list">
                                  <li>
                                    <span class="font-weight-bold"
                                      >Disability Type:
                                    </span>

                                    <span>
                                      {{ emp.disabilityTypeId }}
                                    </span>
                                  </li>
                                  <li v-if="emp.disabilitySeverity === 1">
                                    <span class="font-weight-bold"
                                      >Disability Severity:
                                    </span>
                                    <span v-if="emp.disabilitySeverity === 1"
                                      >Mild</span
                                    >
                                  </li>
                                  <li v-else-if="emp.disabilitySeverity === 2">
                                    <span class="font-weight-bold"
                                      >Disability Severity:
                                    </span>
                                    <span v-if="emp.disabilitySeverity === 2"
                                      >Moderate</span
                                    >
                                  </li>
                                  <li v-else-if="emp.disabilitySeverity === 3">
                                    <span class="font-weight-bold"
                                      >Disability Severity:
                                    </span>
                                    <span v-if="emp.disabilitySeverity === 3"
                                      >Severe</span
                                    >
                                  </li>
                                  <li v-else>
                                    <span class="font-weight-bold"
                                      >Disability Severity:
                                    </span>
                                    <span v-if="emp.disabilitySeverity === 4"
                                      >Profound</span
                                    >
                                  </li>
                                  <li>
                                    <span class="font-weight-bold"
                                      >Cause Of Disability:
                                    </span>

                                    <span>
                                      {{ emp.causeOfDiabilityTypeId }}
                                    </span>
                                  </li>
                                  <li>
                                    <span class="font-weight-bold"
                                      >Job Preference:
                                    </span>
                                    <span>{{ emp.kindofJob }}</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>

            <!-- <div class="m-4">
              <div id="MyProfessional" class="w-100">
                <div class="custom-profile-heading">
                  <span>Professional Brief</span>
                </div>
                <div class="custom-underline"></div>
                <h4 class="text-secondary font-weight-bold">
                  I'm <span class="text-capitalize">{{ emp.fullName }}</span>
                </h4>
                <p class="text-justify">{{ emp.professionalSummary }}</p>
              </div>-->
              <!-- <div class="style-spacer"></div>
              <div id="MyExperience" class="w-100">
                <div class="custom-profile-heading">
                  <div class="custom-row w-100">
                    <span class="flex-grow-1">Experience</span>
                  </div>
                </div>
                <div class="custom-underline"></div>
                <div class="custom-row">
                  <div class="resume-icon flex-grow-1">
                    <i class="fas fa-briefcase"></i>
                  </div>
                  end resume-icon -->

                  <!-- <div class=""></div>
                           <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button" ><i -->
                                      <!-- class="fas fa-edit"></i></router-link>-->
                <!-- </div>
                <div
                  v-for="(experience, index) in employeeExperience"
                  :key="experience.empExperienceId"
                >
                  <div class="custom-row w-100">
                    <div class="flex-grow-1">
                      <h5
                        class="
                          font-weight-bold
                          custom-text-color
                          text-capitalize
                        "
                      >
                        {{ experience.jobTitle }}
                      </h5>
                    </div>

                    <div></div>

                    <div
                      class="w3-border-right ml-2 mr-2"
                      style="height: 5vh"
                    ></div>
                    <div></div>
                  </div>

                  <h6 class="font-weight-bold">Description</h6>
                  <div class="text-justify">
                    {{ experience.experienceDescription }}
                  </div> 
                  <div class="">
                    <div class="custom-information-list mt-2">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class="custom-list">
                            <li>
                              <span class="font-weight-bold"
                                >Company Name: </span
                              ><span>{{ experience.company }}</span>
                            </li>
 -->
                            <!-- <li v-if="experience.country != null">
                              <span class="font-weight-bold">Country: </span>

                              <span>
                                {{ experience.country }}
                              </span>
                            </li>
                            <li v-if="experience.countryId1 != null">
                              <span class="font-weight-bold"
                                >Reference Country:
                              </span>
                              <span>
                                {{ experience.countryId1 }}
                              </span>
                            </li>
                            <li>
                              <span class="font-weight-bold"
                                >Reference Email:
                              </span>
                              <span>{{ experience.referenceEmail }}</span>
                            </li>
                            <li v-if="experience.currentlyWorking">
                              <span class="font-weight-bold"
                                >Currently Working: </span
                              ><span>Yes</span>
                            </li>

                            <li v-else>
                              <span class="font-weight-bold">Start Date: </span
                              ><span>{{
                                experience.startDateMonthYear | formatDate
                              }}</span>
                            </li>
                            <li>
                              <span class="font-weight-bold">Salary: </span>
                              <span
                                v-for="option in salaries"
                                :key="option.value"
                              >
                                <span v-if="option.value == experience.salary">
                                  {{ option.text }}
                                </span>
                                <span v-else></span>
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class="custom-list">
                            <li>
                              <span class="font-weight-bold">Job Title: </span
                              ><span>{{ experience.jobTitle }}</span>
                            </li>

                            <li>
                              <span class="font-weight-bold"
                                >Reference Number:
                              </span>
                              <span>{{ experience.referenceNumber }}</span>
                            </li>

                            <li>
                              <span class="font-weight-bold">City: </span>

                              <span>
                                {{ experience.city }}
                              </span>
                            </li>

                            <li v-if="experience.currentlyWorking">
                              <span class="font-weight-bold">Start Date: </span
                              ><span>{{
                                experience.startDateMonthYear | formatDate
                              }}</span>
                            </li>
                            <li v-else>
                              <span class="font-weight-bold">End Date: </span
                              ><span>{{
                                experience.endDateMonthYear | formatDate
                              }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              SPACER -->
              <!-- <div class="style-spacer"></div>
              <div id="MyEducation" class="w-100">
                <div class="custom-profile-heading">
                  <span>Education</span>
                </div>
                <div class="custom-underline"></div>
                    <label class="form-label">Help us match you with your next job Desired Job Title</label>
                            <input type="text" class="form-control" placeholder="" />-->
                <!-- <div class="custom-row">
                  <div class="resume-icon flex-grow-1">
                    <i class="fas fa-graduation-cap"></i>
                  </div>
                  end resume-icon -->
                  <!-- <div class=""></div> -->

                  <!--                <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button"><i
                                      class="fas fa-edit"></i></router-link>-->
                <!-- </div>
                <div
                  v-for="(eedu, index) in employeeEducation"
                  :key="eedu.empEducationId"
                >
                  <div class="custom-row w-100">
                    <div class="flex-grow-1">
                      <h5
                        class="
                          font-weight-bold
                          custom-text-color
                          text-capitalize
                        "
                      >
                        {{ eedu.degreeTitle }}
                      </h5>
                    </div>

                    <div></div>
 -->
                    <!-- <div
                      class="w3-border-right ml-2 mr-2"
                      style="height: 5vh"
                    ></div>
                    <div></div>
                  </div>
                  <h6 class="font-weight-bold">Description</h6>
                  <div class="text-justify">
                    {{ eedu.educationDescription }}
                  </div>
                  <div class=""> -->
                    <!-- <div class="custom-information-list mt-2">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class="custom-list">
                            <li>
                              <span class="font-weight-bold"
                                >Degree Title: </span
                              ><span>{{ eedu.degreeTitle }}</span>
                            </li> -->
                            <!-- <li v-if="eedu.studentType == 'Student'">
                              <span class="font-weight-bold"
                                >Expected Graduation Year: </span
                              ><span>{{
                                eedu.expectedGraduationYear | formatDate
                              }}</span>
                            </li>
                            <li v-if="eedu.studentType == 'Graduate'">
                              <span class="font-weight-bold"
                                >Graduation Completion Year:
                              </span>
                              <span>{{
                                eedu.graduationCompletionYear | formatDate
                              }}</span>
                            </li>
                            <li>
                              <span class="font-weight-bold">Country: </span>

                              <span>
                                {{ eedu.country }}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class="custom-list">
                            <li>
                              <span class="font-weight-bold"
                                >Degree Level:
                              </span>
 -->
                              <!-- <span>
                                {{ eedu.degreeLevel }}
                              </span>
                            </li>
                            <li v-if="eedu.university == null">
                              <span class="font-weight-bold">Institute: </span
                              ><span>{{ eedu.institute }}</span>
                            </li>
                            <li v-else-if="eedu.university != null">
                              <span class="font-weight-bold">University: </span
                              ><span>{{ eedu.university }}</span>
                            </li>
                            <li v-else>
                              <span class="font-weight-bold">Institute: </span
                              ><span>{{ eedu.notInPakistan }}</span>
                            </li>
                            <li>
                              <span class="font-weight-bold"
                                >CGPA/Percentage:
                              </span>
                              <span>{{ eedu.cgpaPercentage }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
 -->
                      <!-- <div class="row">
                        <div class="col-lg-12">
                          <ul class="custom-list">
                            <li>
                              <div class="font-weight-bold">
                                Project Detail:
                              </div>
                              <div class="text-justify">
                                {{ eedu.projectDetails }}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="Skills">
                <div class="custom-profile-heading">
                  <span>Skills</span>
                </div>
                <div class="custom-underline"></div>
                <div class="custom-row">
                  <div class="resume-icon flex-grow-1">
                    <i class="fas fa-sliders-h"></i>
                  </div> -->
                  <!--end resume-icon-->

                  <!-- <div class=""></div> -->
                  <!--                <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button"><i
                                      class="fas fa-edit"></i></router-link>-->
                <!-- </div>
                <div
                  v-for="(skill, index) in empdata.eMPSkills"
                  :key="skill.empSkillId"
                >
                  <div class="custom-row w-100">
                    <div class="flex-grow-1">
                      <h5 class="font-weight-bold custom-text-color">
                        <i class="">Skill # {{ index + 1 }}</i>
                      </h5>
                    </div>

                    <div></div>

                    <div
                      class="w3-border-right ml-2 mr-2"
                      style="height: 5vh"
                    ></div>
                    <div></div>
                  </div>
                  <label class="">{{ skill.skillsSubCategoryTitle }}</label>
                  <b-progress
                    variant="secondary"
                    :max="100"
                    :value="skill.skillLevelOutof100"
                    height="3px"
                    class="mb-3"
                  ></b-progress>
                </div>
              </div>
              <div class="style-spacer"></div>
              <div id="MyProjects" class="w-100">
                <div class="custom-profile-heading">
                  <span>Projects</span>
                </div>
                <div class="custom-underline"></div> -->
                <!--            <label class="form-label">Help us match you with your next job Desired Job Title</label>
                            <input type="text" class="form-control" placeholder="" />-->
                <!-- <div class="custom-row">
                  <div class="resume-icon flex-grow-1">
                    <i class="fas fa-graduation-cap"></i>
                  </div> -->

                  <!--end resume-icon-->
                  <!--                <div class="">
                                    <a
                                        href="#addorEditModal"
                                        data-toggle="modal"
                                        @click="NewModalEducation()"
                                        title="Add Education"
                                        data-backdrop="static"
                                        data-keyboard="false"
                                    >
                                      <div class="custom-icon icon">
                                        <plus-icon size="2x" class="custom-class"></plus-icon>

                                      </div>
                                    </a>
                                  </div>-->

                  <!--                <router-link to="/Experience" type="button" class="pt-0 pb-0 pl-1 pr-1 custom-edit-button"><i
                                      class="fas fa-edit"></i></router-link>-->
                <!-- </div>
                <div
                  v-for="(epro, index) in empdata.project"
                  :key="epro.projectId"
                >
                  <div class="custom-row w-100">
                    <div class="flex-grow-1">
                      <h5
                        class="
                          font-weight-bold
                          custom-text-color
                          text-capitalize
                        "
                      >
                        {{ epro.projectTitle }}
                      </h5>
                    </div>

                    <div
                      class="w3-border-right ml-2 mr-2"
                      style="height: 5vh"
                    ></div>
                  </div>
                  <h6 class="font-weight-bold">Description</h6>
                  <span>{{ epro.projectDescription }}</span> -->
                  <!--                <div class="">


                                    <div class="custom-information-list mt-2">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <ul class="custom-list">
                                            <li><span class="font-weight-bold">Project Title: </span><span>{{ epro.projectTitle }}</span></li>

                                          </ul>
                                        </div>

                                      </div>

                                    </div>


                                  </div>-->
                <!-- </div>
              </div>
              <div class="style-spacer"></div>
              <div id="MyProjects" class="w-100">
                <div class="custom-profile-heading">
                  <span>Personality Assessment</span>
                </div>
                <div class="custom-underline"></div>
                   <label class="form-label">Help us match you with your next job Desired Job Title</label>
                            <input type="text" class="form-control" placeholder="" />-->
                <!-- <div class="custom-row">
                  <div class="resume-icon flex-grow-1">
                    <i class="fas fa-graduation-cap"></i>
                  </div>
                </div>
                <div
                  v-for="(epro, index) in empdata.assesment"
                  :key="epro.userid"
                > -->
                  <!-- <div class="custom-row w-100">
                    <div class="flex-grow-1">
                      <h5
                        class="
                          font-weight-bold
                          custom-text-color
                          text-capitalize
                        "
                      > -->
                        <!-- {{ epro.questionNo }}
                      </h5>
                    </div>

                    <div
                      class="w3-border-right ml-2 mr-2"
                      style="height: 5vh"
                    ></div>
                  </div>
                  <h6 class="font-weight-bold">Answers</h6>
                  <span>{{ epro.answers }}</span> --> 
                  <!--                <div class="">


                                    <div class="custom-information-list mt-2">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <ul class="custom-list">
                                            <li><span class="font-weight-bold">Project Title: </span><span>{{ epro.projectTitle }}</span></li>

                                          </ul>
                                        </div>

                                      </div>

                                    </div>


                                  </div>-->
                <!-- </div> -->
              <!-- </div> -->
            <!-- </div> -->
          <!-- </div> -->
        </div>
    </div>
    <div class="col-lg-2" v-if="isEmpLoaded">
          <div class="custom-profile-heading">
            <span>Applicant Status</span>
          </div>
          <div class="custom-underline custom-progress-underline"></div>
          <div></div>
          <div class="" style="top: 40vh">
            <!-- <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style="
                  background: #72147e;
                  border-color: #731580;
                  width: 170px;
                  margin-top: 15px;
                "
              >
                Change Status
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">Shortlisted</a>
                <a class="dropdown-item" href="#">Contacted</a>
                <a class="dropdown-item" href="#">Interviewed</a>
                <a class="dropdown-item" href="#">Rejected</a>
                <a class="dropdown-item" href="#">Hired</a>
                <a class="dropdown-item" v-b-modal.modal-1 href="#">Setup Interview</a>
              </div>
            </div> -->
            <button
              v-b-modal.modal-1
              v-on:click="SetStatusModel()"
              class="btn btn-secondary dropdown-toggle"
              type="button"
              style="
                background: #72147e;
                border-color: #731580;
                width: 170px;
                margin-top: 15px;
              "
            >
              Change Status
            </button>

            <ul class="verti-timeline list-unstyled" style="margin-top: 15px">
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'Applied' ? 'active emp-status' : ''
                  "
                >
                  <h5 data-id="MyPersonal">Viewed</h5>
                  <span>{{ empdata.viewedDate | formatDate }}</span>
                </a>
              </li>
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'ShortListed' ? 'active emp-status' : ''
                  "
                >
                  <h5 data-id="MyProfessional">Shortlisted</h5>
                  <span>{{ empdata.shortListedDate | formatDate }}</span>
                  <br />
                  <span>Remarks: {{ empdata.shortListedRemarks }}</span>
                </a>
              </li>
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'Contacted' ? 'active emp-status' : ''
                  "
                >
                  <h5 data-id="MyExperience">Contacted</h5>
                  <span>{{ empdata.contactedDate | formatDate }}</span>
                  <br />
                  <span>Remarks: {{ empdata.contactedRemarks }}</span>
                </a>
              </li>
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'Interviewed' ? 'active emp-status' : ''
                  "
                >
                  <h5 data-id="MyPrefrences">Interviewed</h5>
                  <span>{{ empdata.setupInterViewDate | formatDate }}</span>
                  <br />
                  <span>Remarks: {{ empdata.interviewRemarks }}</span>
                </a>
              </li>
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'Rejected' ? 'active emp-status' : ''
                  "
                >
                  <h5 data-id="MyEducation">Rejected</h5>
                  <span>{{ empdata.rejectedDate | formatDate }}</span>
                  <br />
                  <span>Remarks: {{ empdata.rejectedRemarks }}</span>
                </a>
              </li>
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'Hired' ? 'active emp-status' : ''
                  "
                >
                  <h5 data-id="MySkills">Hired</h5>
                  <span>{{ empdata.hiredDate | formatDate }}</span>
                  <br />
                  <span>Remarks: {{ empdata.hiredRemarks }}</span>
                </a>
              </li>
              <li class="event-list" style="padding-bottom: 10px">
                <a
                  href="javascript:void(0);"
                  v-bind:class="
                    empdata.status == 'SetupInterView'
                      ? 'active emp-status'
                      : ''
                  "
                >
                  <h5 data-id="MyProjects">Setup Interview</h5>
                  <span>{{
                    empdata.setupInterViewDate | formatDateAndTime
                  }}</span>
                  <br />
                  <span>Remarks: {{ empdata.setupInterViewRemarks }}</span>
                  <br />
                  <span>Interview Type: </span>
                  <span v-if="empdata.interViewType === 1"> In-Person </span>
                  <span v-else-if="empdata.interViewType === 2"> Phone </span>
                  <span v-else-if="empdata.interViewType === 3"> Video </span>
                  <br />
                  <span>Message: {{ empdata.message }}</span>
                </a>
              </li>
              <li v-if="emp.resume != null">
               
                <a
                class="custom-default-btn"
                :href="url + emp.resume"
                target="_blank"
                title="Download CV"
                >
                <b-icon
                class="mr-2"
                icon="download"
                ></b-icon
                >Download CV</a>
              </li>
              <li v-else>
                <span class="" target="_blank">No Cv Attached by Employee</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

      <!--SPACER-->
      <b-modal id="modal-1" title="Setup Interview" modal-footer="No">
        <form
          class="custom-validation"
          @submit.prevent="postCandidateStatus"
          method="post"
          novalidate=""
        >
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="mb-3 form-group row no-gutters">
                <label class="col-sm-3 col-form-label col-form-label-sm"
                  >Status</label
                >
                <div class="col-sm-9">
                  <select
                    class="form-control"
                    id="txtStatus"
                    name="txtStatus"
                    required=""
                    v-model="bodyCandi.status"
                    @change="onChangeValue"
                  >
                    <option value="" disabled>Select Option</option>
                    <option value="Applied">Applied</option>
                    <option value="ShortListed">ShortListed</option>
                    <option value="Contacted">Contacted</option>

                    <option
                      v-if="empdata != null && empdata.status != 'Hired'"
                      value="Rejected"
                    >
                      Rejected
                    </option>
                    <option value="SetupInterView">Setup Interview</option>
                    <option value="Interviewed">Interviewed</option>
                    <option value="Hired">Hired</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="bodyCandi.status == 'SetupInterView'">
            <div class="col-md-12">
              <div class="mb-3 form-group row no-gutters">
                <label class="col-sm-3 col-form-label col-form-label-sm"
                  >Interview Date</label
                >
                <div class="col-sm-9">
                  <input
                    v-model="bodyCandi.setupInterViewDate"
                    type="datetime-local"
                    class="form-control"
                    required=""
                    id="txtinterViewDate"
                    name="txtinterViewDate"
                    placeholder="Select Date and Time"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-3 form-group row no-gutters">
                <label class="col-sm-3 col-form-label col-form-label-sm"
                  >Interview Type</label
                >
                <div class="col-sm-9">
                  <select
                    class="form-control"
                    name="txtStatus"
                    required=""
                    v-model="bodyCandi.typeInterview"
                  >
                    <option value="" disabled>Select Option</option>
                    <option value="1">In-person</option>
                    <option value="2">Phone</option>
                    <option value="3">Video</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-3 form-group row no-gutters">
                <label class="col-sm-3 col-form-label col-form-label-sm"
                  >Interview Address</label
                >
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    required=""
                    name="txtinterViewDate"
                    placeholder="Interview Address"
                    v-model="bodyCandi.interviewAddress"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="mb-3 form-group row no-gutters">
                <label class="col-sm-3 col-form-label col-form-label-sm"
                  >Message to Candidate</label
                >
                <div class="col-sm-9">
                  <textarea
                    class="form-control"
                    required=""
                    maxlength="2000"
                    placeholder="message "
                    name="RolesandResponsibility"
                    rows="3"
                    v-model="bodyCandi.message"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="mb-3 form-group row no-gutters">
                <label class="col-sm-3 col-form-label col-form-label-sm"
                  >Remarks</label
                >
                <div class="col-sm-9">
                  <input
                    required=""
                    v-model="bodyCandi.remarks"
                    type="text"
                    class="form-control"
                    id="txtremarks"
                    name="txtremarks"
                    placeholder="Remarks"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="text-end mt-5">
            <button
              type="submit"
              class="btn btn-primary waves-effect waves-light me-1"
              :disabled="isLoading"
            >
              Submit</button
            ><button type="reset" class="btn btn-secondary waves-effect">
              Cancel
            </button>
          </div> -->
          <b-button
            type="submit"
            class="custom-form-btn custom-btn-gotopage"
            :disabled="isLoadingChangeButton"
            variant="outline-dark"
            data-dismiss="modal"
            >Save
          </b-button>
        </form>
        <!-- <template #modal-footer="{   }"> -->

        <!-- Emulate built in modal footer ok and cancel button actions -->
        <!-- <b-button size="sm" variant="success" @click="ok()">
          Save
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button> -->
        <!-- Button with custom close trigger value -->
        <!-- <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Forget it
        </b-button> -->
        <!-- </template> -->
      </b-modal>

      <b-modal id="modal-2" title="Filter Candidates" modal-footer="No">
        <form
          class="custom-validation"
          @submit.prevent="filterCandidates"
          method="post"
          novalidate=""
        >
          <div class="form-group" style="padding: 0px 15px">
            <label>Status</label>
            <select
              class="form-control"
              name="txtStatus"
              required=""
              v-model="bodyCandiFilter.applicantStatus"
            >
              <option value="0">All</option>
              <option value="Applied">Applied</option>
              <option value="ShortListed">ShortListed</option>
              <option value="Contacted">Contacted</option>
              <option value="Rejected">Rejected</option>
              <option value="SetupInterView">Setup Interview</option>
              <option value="Interviewed">Interviewed</option>
              <option value="Hired">Hired</option>
            </select>
          </div>

          <div class="form-group" style="padding: 0px 15px">
            <label>City</label>
            <select class="form-control" v-model="bodyCandiFilter.cityId">
              <option value="0">Select City</option>
              <option
                v-for="option in cities"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
          </div>

          <div class="form-group" style="padding: 0px 15px">
            <label>Experience</label>
            <select
              class="form-control"
              v-model="bodyCandiFilter.yearOfExperience"
            >
              <option value="0" selected="selected">
                Select Required job experience
              </option>
              <option
                v-for="option in yearOfExperiences"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
          </div>
          <div class="form-group" style="padding: 0px 15px">
            <label>Education</label>
            <select
              id="degreeLevel_id"
              class="custom-select-option form-control form-control-sm"
              v-model="bodyCandiFilter.educationLevel"
            >
              <option value="0" selected="selected">Select Degree</option>
              <option
                v-for="option in degreeLevels"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
          </div>

          <div class="form-group" style="padding: 0px 15px">
            <label>Expected Salary</label>
            <input
              required=""
              v-model="bodyCandiFilter.expectedSalary"
              type="number"
              class="form-control"
              name="txtremarks"
              placeholder="expected salary"
            />
          </div>

          <div class="form-group" style="padding: 0px 15px">
            <label>Present Salary</label>
            <input
              required=""
              v-model="bodyCandiFilter.currentSalary"
              type="number"
              class="form-control"
              name="txtremarks"
              placeholder="Present salary"
            />
          </div>

          <div class="form-group" style="padding: 0px 15px">
            <label>Career Level</label>
            <select class="form-control" v-model="bodyCandiFilter.careerLevels">
              <option value="0" selected="selected">Select Career level</option>
              <option
                v-for="option in careerLevels"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
          </div>

          <div class="form-group" style="padding: 0px 15px">
            <label>Department</label>
            <select
              class="form-control"
              v-model="bodyCandiFilter.departments"
              id=""
            >
              <option value="0" selected="selected">Select Department</option>
              <option
                v-for="option in departments"
                :key="option.key"
                :value="option.key"
              >
                {{ option.value }}
              </option>
            </select>
          </div>

          <b-button
            type="submit"
            class="custom-form-btn custom-btn-gotopage mr-2"
            :disabled="isLoadingCandiButton"
            variant="outline-dark"
            >Filter
          </b-button>
          <b-button
            type="reset"
            class="custom-form-btn custom-btn-gotopage"
            :disabled="isLoadingCandiButton"
            variant="outline-dark"
            >Reset
          </b-button>
        </form>
        <!-- <template #modal-footer="{   }"> -->

        <!-- Emulate built in modal footer ok and cancel button actions -->
        <!-- <b-button size="sm" variant="success" @click="ok()">
          Save
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Cancel
        </b-button> -->
        <!-- Button with custom close trigger value -->
        <!-- <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Forget it
        </b-button> -->
        <!-- </template> -->
      </b-modal>
      <div class="style-spacer"></div>
      <div class="row no-gutters">
        <div class="col-lg-2 w-100 h-100"></div>
        <div class="col-lg-8"></div>
        <div class="col-lg-2"></div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import moment from "moment";
import axios from "axios";
import _ from "underscore";
export default {
  name: "CandidatesApplied",
  data() {
    return {
      url:axios.defaults.baseURL,

      yearList: [],
      countryCodes:[
        {value: 1,text:"+92"},
        {value: 2,text:"+93"},
        {value: 3,text:"+94"},
      ],
      calculatedAge:0,
      networkCodes:[
        {value:1,text:"300"},
        {value:2,text:"301"},
        {value:3,text:"302"},
        {value:4,text:"303"},
        {value:5,text:"304"},
        {value:6,text:"305"},
        {value:7,text:"306"},
        {value:8,text:"307"},
        {value:9,text:"308"},
        {value:10,text:"309"},
        {value:11,text:"311"},
        {value:12,text:"312"},
        {value:13,text:"313"},
        {value:14,text:"314"},
        {value:15,text:"315"},
        {value:16,text:"320"},
        {value:17,text:"321"},
        {value:18,text:"322"},
        {value:19,text:"323"},
        {value:20,text:"324"},
        {value:21,text:"325"},
        {value:22,text:"330"},
        {value:23,text:"331"},
        {value:24,text:"332"},
        {value:25,text:"333"},
        {value:26,text:"334"},
        {value:27,text:"335"},
        {value:28,text:"336"},
        {value:29,text:"337"},

        {value:30,text:"340"},
        {value:31,text:"341"},
        {value:32,text:"342"},
        {value:33,text:"343"},
        {value:34,text:"344"},
        {value:35,text:"345"},
        {value:36,text:"346"},
        {value:37,text:"347"},

      ],
      employeeStatuses:[
        { value: 1, text:"Full Time" },
        { value: 2, text:"Part Time" },
        { value: 3, text:"Freelancing or On/Off projects" },
        { value: 4, text:"Not Currently Working" },
      ],
      selectedEmployementWorkHours:"0",
      employementWorkHours:[
        { value: 0, text:"Select Work Hours" },
        { value: 1, text:"1-20 hours per week" },
        { value: 2, text:"21-30 hours per week" },
        { value: 3, text:"31-40 hours per week" },
        { value: 4, text:"40 hours per week" },
      ],

      selectedEmployementWorkWhere:"0",
      employementWorkWhere:[
        { value: 0, text:"Select Work Place" },
        { value: 1, text:"At the office" },
        { value: 2, text:"Remote" },
        { value: 3, text:"Combination" },
        { value: 4, text:"Unsure right now" },
      ],
months:[
        { value: 0, text:"Select Month" },
        { value: 1, text:"1" },
        { value: 2, text:"2" },
        { value: 3, text:"3" },
        { value: 4, text:"4" },
        { value: 5, text:"5" },
        { value: 6, text:"6" },
        { value: 7, text:"7" },
        { value: 8, text:"8" },
        { value: 9, text:"9" },
        { value: 10, text:"10" },
        { value: 11, text:"11" },
        { value: 12, text:"12" }

      ],
      selectedMiles:"0",
      miles:[
        {value : 1, text: "0-5"},
        {value : 2, text: "5-10"},
        {value : 3, text: "10-20"},
        {value : 4, text: "20-30"},
        {value : 5, text: "30-40"},
        {value : 6, text: "40-50"},
        {value : 7, text: "50-60"},
        {value : 8, text: "60-70"},
        {value : 9, text: "70-80"},
        {value : 10, text: "80-90"},
        {value : 11, text: "90-100"},
        {value : 12, text: "More than 100"}
      ],
      selectedTravelPercent:"0",
      travels:[
        {value : 1, text: "not at all"},
        {value : 2, text: "Up to 10%"},
        {value : 3, text: "Up to 20%"},
        {value : 4, text: "Up to 30%"},
        {value : 5, text: "Up to 40%"},
        {value : 6, text: "Up to 50%"},
        {value : 7, text: "Up to 60%"},
        {value : 8, text: "Up to 70%"},
        {value : 9, text: "Up to 80%"},
        {value : 10, text: "Up to 90%"},
        {value : 11, text: "Up to 100%"}

      ],
      salaries:[
        { text: 'Choose One...', value: -1 },
        { text: '0-5000', value: 1 },
        { text: '5000-15000', value: 2 },
        { text: '15000-25000', value: 3 },
        { text: '25000-35000', value: 4 },
        { text: '35000-50000', value: 5 },
        { text: '50000-70000', value: 6 },
        { text: '70000-100000', value: 7 },
        { text: '100000-125000', value: 8 },
        { text: '125000-150000', value: 9 },
        { text: '150000-175000', value: 10 },
        { text: '175000-200000', value: 11 },
        { text: '200000-250000', value: 12 },
        { text: '300000-400000', value: 13 },
        { text: '400000-500000', value: 14 },
        { text: '500000-600000', value: 15 },
        { text: '600000-700000', value: 16 },
        { text: '700000-800000', value: 17 },
        { text: '800000-900000', value: 18 },
        { text: '900000-1000000', value: 19 },
        { text: '1000000+', value: 20 },
      ],
      isDataLoaded: false,
      isLoading: false,
      isMessageDismissed: false,
      isLoadingEmp : false,
      isEmpLoaded: false,
      isLoadingChangeButton: false,
      isLoadingCandiButton: false,
      jobId: 0,
      fullName: "",
      mobileNumber: null,
      code:0,
      networkCode:0,
      email: "",
      facebookLink: "",
      linkedInLink: "",
      twitterLink: "",
      googlePlusLink: "",
      cnic: "",
      currentSalary: 0,
      domicile: "",
      yearOfExperienceId: 0,
      expectedSalary: 0,
      maritalStatus: 0,
      cityId: 0,
      professionalSummary: "",
      phoneNumberLink: "tel:",
      linkMobileNo:"",
      emailLink: "mailto:",
      selectedCategory: "-1",



      //Dropdown Lists
      // cities:[],
      departments:[],
      yearOfExperiences:[],
      selectedCareerLevel:null,
      careerLevels:[],
      selectedDegreeLevel:null,
      degreeLevels:[],


      employeelist: null,
      employeeSkill: null,
      employeeExperience: null,
      employeeEducation: null,

      latestExperience:{},
      latestDegreeLevel:{},




      employeeProjects:[],
      maritalStatusList: [
        { value: 1, text: "Single" },
        { value: 2, text: "Married" },
        { value: 3, text: "Other" },
      ],
      body: {
        jobId: 0
      },

      bodyEmp: {
        jobId: 0,
        employeeId : 0
      },

      bodyCandi: {
        jobId: 0,
        employeeId : 0,
        remarks: null,
        interViewDate: null,
        //setup interview
        setupInterViewDate:null,
        typeInterview:0,
        interviewAddress:"",
        message:"",
        status: 0,
        JobApplicantsId: 0

      },


      bodyCandiFilter: {

        jobId: 0,
        cityId: 0,
        yearOfExperience: 0,
        experienceLevels: 0,
        currentSalary: 0,
        expectedSalary: 0,
        departments: 0,
        careerLevels: 0,
        educationLevel: 0,
        gender: 0,
        industry: 0,
        applicantStatus: 0

      },
      errored : false,
      // url: "Job",
      jobdata: null,
      applicants: [],
      isApplicantViewd :null,

      //Applied Date
      appliedDate:null,
      emp: null,
      empdata: null,
    };
  },
  computed:{
    cities(){
      return this.$store.getters.availableCities
    },
  },
  methods: {
    SetStatusModel(){
      console.log("this.empdata : ", this.empdata);
      this.bodyCandi.employeeId = this.emp.employeeId;
      this.bodyCandi.remarks = '';
      this.bodyCandi.typeInterview = this.empdata.interViewType
      this.bodyCandi.message = this.empdata.message
      this.bodyCandi.setupInterViewDate = this.empdata.setupInterViewDate
      this.bodyCandi.interviewAddress = this.empdata.interviewAddress
      this.bodyCandi.status = this.empdata.status
      this.bodyCandi.jobId = Number(this.jobId)
      this.bodyCandi.JobApplicantsId = Number(this.empdata.jobApplicantId)
      console.log(this.bodyCandi)

    },
    getJobInfo(){
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.isDataLoaded = false;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.jobId = Number(this.jobId)

       console.log(this.body)

       axios
            .post("/api/Job/GetJobApplicantByJobId", this.body)
            .then((res) => {

              console.log("Add Response portion");

              console.log("response : ", res);
              this.jobdata = res.data.data;
              this.applicants = this.jobdata.jobApplicants

              if (this.applicants.length == 0){
                this.showAlert();
              }
              else {
                this.hideAlert();
                debugger
                //Difference in number of days
                this.applicants.forEach(item =>{
                  debugger
                  var given = moment(item.appliedDate, "YYYY-MM-DD");
                  var current = moment().startOf('day');
                  this.appliedDate = moment.duration(current.diff(given)).asDays();
                  item.appliedDate = this.appliedDate;
                })
                // for (let date in this.applicants){
                //   var given = moment(this.applicants[date].appliedDate, "YYYY-MM-DD");
                //   var current = moment().startOf('day');
                //   this.appliedDate = moment.duration(current.diff(given)).asDays();
                //   if(this.applicants.c == date)
                //   item.appliedDate = this.appliedDate;
                //   console.log("appliedDate : ", this.appliedDate);
                // }
              }








              console.log("saiftest:",this.applicants)
              this.isDataLoaded = true;
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.isLoading = false
              if (this.errored) {

                console.log("Error : Sorry there is no response");

              } else {
                /*this.$bvToast.toast("Data Fetched! ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: 'Job',
                  solid: true
                });*/
                console.log("Data Fetched! ");

              }
            });
    },
    FetchEmployee(empdets) {


      if (this.isLoadingEmp) {
        return;
      }
      this.isLoadingEmp = true;
      this.isEmpLoaded = false;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.bodyEmp.jobId = Number(this.jobId)
      this.bodyEmp.employeeId = Number(empdets)


       console.log(this.bodyEmp)

       axios
            .post("/api/Job/GetAllApplicantView", this.bodyEmp)
            .then((res) => {

              console.log("Add Response portion");

              console.log("response : ", res);
              this.empdata = res.data.data[0]
              console.log("empdata",this.empdata)
              this.emp = res.data.data[0].employees
              console.log("ahmed test",this.emp)
              console.log('status ' , this.empdata.status)

              this.isEmpLoaded = true
              console.log("empdata", this.empdata);
              this.isApplicantViewd = this.empdata.isApplicantViewed

              console.log("isApplicantViewd", this.isApplicantViewd);
              this.calculatedAge = this.caculateAge(this.emp.dateofBirth);
              for (let option in this.countryCodes){
                if(this.emp.code === this.countryCodes[option].value){
                  this.code = this.countryCodes[option].text;
                }
              }
              console.log("country Code : ", this.code);
              //this.code = this.employeelist.code;
              for (let option in this.networkCodes){
                if(this.emp.networkCode === this.networkCodes[option].value){
                  this.networkCode = this.networkCodes[option].text;
                }
              }
              console.log("network Code : ", this.networkCode);
              //this.networkCode = this.employeelist.networkCode;

              this.mobileNumber = this.emp.mobileNumber;
              this.linkMobileNo = this.code+this.networkCode+this.mobileNumber;



              this.employeeExperience = _.sortBy(this.empdata.eMPExperiences, 'startDateMonthYear').reverse();

              if(this.employeeExperience.length != 0){
                console.log("employeeExperience : ", this.employeeExperience);
                this.employeeExperienceId = this.employeeExperience.empExperienceId;
                this.latestExperience = _.first(this.employeeExperience);
                console.log("this.latestExperience", this.latestExperience);
              }


              this.employeeEducation = _.sortBy(this.empdata.empEducations, 'degreeLevelId').reverse();

              if(this.employeeEducation.length != 0){
                console.log("employeeEducation : ", this.employeeEducation);

                this.latestDegreeLevel = _.first(this.employeeEducation);
                console.log("this.latestDegreeLevel", this.latestDegreeLevel);
              }


            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.isLoadingEmp = false
              if (this.errored) {

                console.log("Error : Sorry there is no response");

              } else {
                /*this.$bvToast.toast("Data Fetched! ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: 'Job',
                  solid: true
                });*/

                console.log("Data Fetched! ");
              }
            });

    },

    filterCandidates() {


      if (this.isLoadingCandiButton) {
        return;
      }
      this.isLoadingCandiButton = true;
      var app = this;
      this.bodyCandiFilter.jobId = Number(this.jobId)
      this.bodyCandiFilter.cityId = Number(this.bodyCandiFilter.cityId)
      this.bodyCandiFilter.yearOfExperience = Number(this.bodyCandiFilter.yearOfExperience)
      this.bodyCandiFilter.experienceLevels = Number(this.bodyCandiFilter.experienceLevels)
      this.bodyCandiFilter.currentSalary = Number(this.bodyCandiFilter.currentSalary)
      this.bodyCandiFilter.expectedSalary = Number(this.bodyCandiFilter.expectedSalary)
      this.bodyCandiFilter.departments = Number(this.bodyCandiFilter.departments)
      this.bodyCandiFilter.careerLevels = Number(this.bodyCandiFilter.careerLevels)
      this.bodyCandiFilter.educationLevel = Number(this.bodyCandiFilter.educationLevel)
      this.bodyCandiFilter.applicantStatus = this.bodyCandiFilter.applicantStatus == '0' ? Number(0) :  this.bodyCandiFilter.applicantStatus

      console.log(this.bodyCandiFilter)
      console.log(Date.now())

       axios
            .post("/api/Employee/SearchCandidates", this.bodyCandiFilter)
            .then((res) => {

              console.log("Add bodyCandiFilter portion");
              console.log(res.data.data)
              this.applicants = res.data.data

            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.isLoadingCandiButton = false;
                this.$bvToast.toast("Filter Applied! ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: 'Candidates',
                  solid: true
                });

              this.$bvModal.hide("modal-2")


            });

    },
    postCandidateStatus() {


      if (this.isLoadingChangeButton) {
        return;
      }
      this.isLoadingChangeButton = true;

      this.bodyCandi.jobId = Number(this.jobId)
      this.bodyCandi.employeeId = Number(this.emp.employeeId)
      this.bodyCandi.status =  this.bodyCandi.status
      this.bodyCandi.typeInterview = Number(this.bodyCandi.typeInterview)
      console.log("post Candidate Status: ", this.bodyCandi);
      console.log(Date.now())

       axios
            .post("/api/Job/JobPost", this.bodyCandi)
            .then((res) => {

              console.log("Add bodyCandi portion");
              var today = new Date();
              var datenow = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
              console.log("response : ", res);
              this.empdata.status = this.bodyCandi.status
              switch(this.bodyCandi.status) {
                case 'ShortListed':
                  this.empdata.shortListedDate = datenow

                  this.empdata.shortListedRemarks = this.bodyCandi.remarks
                  break;
                case "Rejected":
                  this.empdata.rejectedDate = datenow

                  this.empdata.rejectedRemarks = this.bodyCandi.remarks
                  break;

                case "Hired":
                  this.empdata.hiredDate = datenow

                  this.empdata.hiredRemarks = this.bodyCandi.remarks
                  break;

                case "SetupInterView":
                  this.empdata.setupInterViewDate = this.bodyCandi.setupInterViewDate
                  this.empdata.typeInterview = this.bodyCandi.typeInterview;
                  this.empdata.interviewAddress= this.bodyCandi.interviewAddress;
                  this.empdata.message= this.bodyCandi.message;

                  this.empdata.setupInterViewRemarks = this.bodyCandi.remarks

                  break;

                case "Interviewed":
                  this.empdata.interviewRemarks = this.bodyCandi.remarks
                  break;

                case "Contacted":
                  this.empdata.contactedDate = datenow
                  this.empdata.contactedRemarks = this.bodyCandi.remarks
                  break;
                default:
                  // code block
              }

            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.isLoadingChangeButton = false;
              if (this.errored) {

                console.log("Error : Sorry there is no response");

              } else {
                this.$bvToast.toast("Status Changed! ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: 'Job Applicant',
                  solid: true
                });
                this.$bvModal.hide("modal-1")

              }
            });

    },
    getAll() {
      this.employeelist = null;
      axios
        .get("/api/Employee/GetEmployeeProfile")
        .then((res) => {
          if (res.status != "200") {
            console.log("error.....");
            const error = new Error("Failed to fetch!");
            return error;
          }

          /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
          this.employeelist = res.data.employee;
          console.log("employeelist : ", this.employeelist);
          this.fullName = this.employeelist.fullName;
          this.mobileNumber = this.employeelist.mobileNumber;
          this.email = this.employeelist.email;
          this.facebookLink = this.employeelist.facebookLink;
          this.linkedInLink = this.employeelist.linkedInLink;
          this.twitterLink = this.employeelist.twitterLink;
          this.googlePlusLink = this.employeelist.googlePlusLink;
          this.cnic = this.employeelist.cnic;
          this.currentSalary = this.employeelist.currentSalary;
          this.domicile = this.employeelist.domicile;
          this.maritalStatus = this.employeelist.maritalStatus;
          this.yearOfExperienceId = this.employeelist.yearOfExperienceId;
          this.expectedSalary = this.employeelist.expectedSalary;
          this.cityId = this.employeelist.cityId;
          this.professionalSummary = this.employeelist.professionalSummary;
          this.employeeSkill = res.data.eMPSkills;
          console.log("employeeSkill : ", this.employeeSkill);
          this.employeeExperience = res.data.eMPExperiences;
          console.log("employeeExperience : ", this.employeeExperience);
          this.employeeEducation = res.data.empEducations;
          console.log("employeeEducation : ", this.employeeEducation);
          // });
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    caculateAge(dob){
      //var diff_ms = Date.now() - dob.getTime();
      //var age_dt = new Date(diff_ms);

      //return Math.abs(age_dt.getUTCFullYear() - 1970);

      var formatedDate = moment(String(dob)).format('YYYY/MM/DD');
      var today = new Date();
      var birthDate = new Date(formatedDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    getCityList(){
      this.$store.dispatch("getCityList");
      /*this.cities = null;
      let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });*/
    },
    getDepartmentList(){
      this.departments = [];
      let model = {
        type: "Department"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.departments = res.data.data;

            console.log("Add Response portion");

            console.log("departments : ", this.departments);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },
    getYearOfExperience() {
      this.yearOfExperiences = [];
      let ddBody = {
        type : "YearOfExperience"
      }
      axios.post('/api/KeyValuePair/GetDropDownListByIds', ddBody)
          .then(res => {
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            console.log("yearOfExperiences new: ",res.data.data[0].key)
            this.yearOfExperiences = res.data.data
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
            }
          });


    },

    getDegreeLevelList(){
      this.degreeLevels = null;
      let model = {
        type: "DegreeLevel"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.degreeLevels = res.data.data;

            console.log("Add Response portion");

            console.log("degreeLevels : ", this.degreeLevels);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },

    getCareerLevelList(){
      this.careerLevels = [];
      let model = {
        type: "CareeerLevel"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.careerLevels = res.data.data;

            console.log("Add Response portion");

            console.log("careerLevels : ", this.careerLevels);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },





    onChangeValue(){
      this.bodyCandi.remarks = null;
    },

    scrollTo(id) {
      //var id=event.target.attr("data-id");
      console.log(id);
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      });
    },
    showAlert() {
      this.isMessageDismissed = true
    },
    hideAlert() {
      this.isMessageDismissed = false
    },
  },
  mounted() {
    this.jobId = this.$route.params.id;

    this.getJobInfo();
    console.log("jobId : ",this.jobId)
    console.log("empId : ",this.$route.params.empId)
    if(this.$route.params.empId != 0){
      this.FetchEmployee(this.$route.params.empId);
    }

    this.getAll();
    this.getCityList();
    this.getDepartmentList();
    this.getYearOfExperience();
    this.getDegreeLevelList();
    this.getCareerLevelList();


  },
};
</script>
<style>
.event-list {
  border-left: 3px dashed #f6f6f6;
  position: relative;
  padding: 0 0 30px 30px;
}
.event-list::after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  left: -6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #ea216c;
  background-color: #fff;
}
.resume-icon {
  font-size: 52px;
  color: #ea216c;
}
.custom-edit-button {
  font-size: 30px;
}
#modal-1 .modal-footer,
#modal-2 .modal-footer {
  display: none !important;
}
</style>
