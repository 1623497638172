<template>
  <div class="container-fluid">
       <nav class="navbar navbar-expand-lg navbar-dark   mt-30 filter-nav">
   
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <a class="nav-item nav-link active" href="#">My Application <span class="sr-only">(current)</span></a>
      <a class="nav-item nav-link" href="#">Analytics</a>
      <a class="nav-item nav-link" href="#">Interviewed </a>
      <a class="nav-item nav-link" href="#">Shortlisted </a>
      <a class="nav-item nav-link" href="#">Hired </a>
      <a class="nav-item nav-link " href="#">Chat</a>
    </div>
  </div>
</nav>
    <div class=" mt-30"  >
             

          <div class="row analytics-card">
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">34,152</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                      Application Received
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">25,475</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                     CV's Viewed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">50,000</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                      Candidates shortlisted
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">15,00</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                      Candidates Contacted
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    <div class="style-spacer"></div>

<div class="row analytics-card2">
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">34,152</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                     Candidates Interviewed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">25,475</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                      Candidates Shortlisted after Interview
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">50,000</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                      Candidates Rejected after Interview
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
              <div class="w3-card">
                <div class="card-body">
                  <div class="float-end mt-2"><div></div></div>
                  <div>
                    <h4 class="mb-1 mt-1">
                      <span data-plugin="counterup">15,00</span>
                    </h4>
                    <p class="text-muted mb-0" style="font-size: 18px">
                      Candidates Hired
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

    <div class="style-spacer"></div>
      
    </div>

     <div class="row ">
      <div class="col-md-12  ">
          <div class="w3-card p-3" id="chart">
        <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
 
                
      </div>
 
    </div>

        <div class="style-spacer"></div>

     <div class="row ">
      <div class="col-md-6  ">
          <div class="w3-card p-3" id="chart">
        <apexchart type="bar" height="430" :options="chartOptions1" :series="series1"></apexchart>
      </div>
 
                
      </div>
            <div class="col-md-6  ">
          <div class="w3-card p-3" id="chart">
         <apexchart type="line" height="430" :options="chartOptions2" :series="series2"></apexchart>
      </div>
 
                
      </div>
 
    </div>
    <div class="style-spacer"></div>
  </div>
</template>
<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name:"EmployerAnalytics",
  data() {
    return {
          
          series: [{
            name: 'TEAM A',
            type: 'column',
            data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
          }, {
            name: 'TEAM B',
            type: 'area',
            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
          }, {
            name: 'TEAM C',
            type: 'line',
            data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
          }],
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              stacked: false,
            },
            stroke: {
              width: [0, 2, 5],
              curve: 'smooth'
            },
            plotOptions: {
              bar: {
                columnWidth: '50%'
              }
            },
            
            fill: {
              opacity: [0.85, 0.25, 1],
              gradient: {
                inverseColors: false,
                shade: 'light',
                // type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
              }
            },
            labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
              '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
            ],
            markers: {
              size: 0
            },
            xaxis: {
              type: 'datetime'
            },
            yaxis: {
              title: {
                text: 'Points',
              },
              min: 0
            },
            tooltip: {
              shared: true,
              intersect: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== "undefined") {
                    return y.toFixed(0) + " points";
                  }
                  return y;
            
                }
              }
            }
          },
          
           series1: [{
            data: [44, 55, 41, 64, 22, 43, 21]
          }, {
            data: [53, 32, 33, 52, 13, 44, 32]
          }],
          chartOptions1: {
            chart: {
              type: 'bar',
              height: 430
            },
            plotOptions: {
              bar: {
                // horizontal: true,
                dataLabels: {
                  position: 'top',
                },
              }
            },
            dataLabels: {
              enabled: true,
              offsetX: -6,
              style: {
                fontSize: '12px',
                colors: ['#fff']
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['#fff']
            },
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
            },
          },
          
          series2:[{
              name: "Desktops",
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
          }],
          chartOptions2: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Product Trends by Month',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            }
          },
          
        
        }
        }
}
</script>