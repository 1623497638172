import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/pages/Home.vue'

import SignIn from "@/pages/SignIn";
import SignUp from "@/pages/SignUp";
import ChangePassword from "@/pages/employee/ChangePassword";

import AddProfileDetail from "@/pages/employee/AddProfileDetail";
import LabourManPower from "@/pages/employee/LabourManPower";
import MumsReturnToWork from "@/pages/employee/MumsReturnToWork"
import SpecialNeededPerson from "@/pages/employee/SpecialNeededPerson"
import Graduate from "@/pages/employee/Graduate";

import EmployeeDashboard from "@/pages/employee/EmployeeDashboard"
import AllJobs from "@/pages/AllJobs";
import JobDetail from "@/pages/employee/JobDetail";

import EmployeeProfile from "@/pages/employee/EmployeeProfile"
import AllApplications from "@/pages/employee/AllApplications"
import EmployeeJobs from  "@/pages/employee/EmployeeJobs"
import AllFeaturedOrLatestJobs from "@/pages/employee/AllFeaturedOrLatestJobs";

import PurchaseHistory from "@/pages/employee/PurchaseHistory";
import Experience from "@/pages/employee/Experience"
import AboutUs from "@/pages/employee/AboutUs";
import ContactUs from "@/pages/employee/ContactUs"
import AboutCompany from "@/pages/employer/AboutUs";
import EmpCandidates from "@/pages/employer/Candidates.vue";
import EmployerAnalytics from "@/pages/employer/Analytics";
import EmployerDashboard from "@/pages/employer/EmployerDashboard";
import EmployerProfileInfo from "@/pages/employer/EmployerProfileInfo.vue";
import EmployerPostJob from "@/pages/employer/EmployerPostJob.vue";
import BuyPackage from "@/pages/employer/BuyPackage.vue";
import PackageHistory from "@/pages/employer/PackageHistory.vue";
import EmployerJobs from "@/pages/employer/EmpJobs.vue";
import AccountSetting from "@/pages/employer/AccountSetting.vue";
import AddTeam from "@/pages/employer/AddTeam.vue";
import TeamList from "@/pages/employer/TeamList.vue";
import CandidatesApplied from "@/pages/employer/CandidatesApplied.vue";
import CvSearch from "@/pages/employer/CvSearch.vue";



import EmailVerification from "@/pages/emailVerification/EmailVerification";
import EndUserLicenseAgreement from "@/pages/EndUserLicenseAgreement";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiePolicy from "../pages/CookiePolicy";
import Disclaimer from "../pages/Disclaimer";
import AllEmployers from "../pages/employee/AllEmployers";
// import robots from "../pages/robots.txt";




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: {
    //   auth: false
    // }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      auth: false
    }
  },
  {
    path: '/sign-up/:id',
    name: 'SignUp',
    component: SignUp
  },
  // {
  //   path: '/robots',
  //   name: 'robots',
  //   component: robots
  // },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      auth: true
    }
  },

  {
    path: '/detail/:id',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "Detail" */ '../pages/Detail.vue')
  },
  {
    path: '/add-profile-detail',
    name: 'AddProfileDetail',
    component: AddProfileDetail,
    meta: {
      auth: true
    }
  },
  {
    path: '/labour-man-power',
    name: 'LabourManPower',
    component: LabourManPower
  },
  {
    path: '/mums-return-to-work',
    name: 'MumsReturnToWork',
    component: MumsReturnToWork
  },
  {
    path: '/special-needed-person',
    name: 'SpecialNeededPerson',
    component: SpecialNeededPerson
  },
  {
    path: '/employee-dashboard',
    name: 'EmployeeDashboard',
    component: EmployeeDashboard,
    meta: {
      auth: true
    }
  },
  {
    path: '/job-board',
    name: 'AllJobs',
    component: AllJobs
  },
  {
    path: '/job-detail/:id',
    name: 'JobDetail',
    component: JobDetail
  },
  {
    path: '/graduate',
    name: 'Graduate',
    component: Graduate
  },
  {
    path: '/employee-profile',
    name: 'EmployeeProfile',
    component: EmployeeProfile,
    meta: {
      auth: true
    }
  },
  {
    path: '/all-applications',
    name: 'AllApplications',
    component: AllApplications
  },
  {
    path: '/employee-jobs',
    name: 'EmployeeJobs',
    component: EmployeeJobs,
    meta: {
      auth: true
    }
  },
  {
    path: '/all-featured-latest-jobs/:typeJob',
    name: 'AllFeaturedOrLatestJobs',
    component: AllFeaturedOrLatestJobs
  },
  {
    path: '/all-featured-brands/:brandtype',
    name: 'AllFeaturedBrands',
    component: () => import(/* webpackChunkName: "AllFeaturedBrands" */ '../pages/AllFeaturedBrands.vue')
  },
  {
    path: '/all-employers/:employerType',
    name: 'AllEmployers',
    component: AllEmployers
  },
  {
    path: '/purchase-history',
    name: 'PurchaseHistory',
    component: PurchaseHistory
  },
  {
    path: '/experience',
    name: 'Experience',
    component: Experience
  },

  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../pages/employee/Feedback.vue')
  },
  
  {
    path: '/employer-about-us/:id',
    name: 'AboutEmployer',
    component: AboutCompany
  },
  {
    path: '/employer-package',
    name: 'BuyPackage',
    component: BuyPackage
  },
  {
    path: '/employer-candidates/:id',
    name: 'EmpCandidates' ,
    component: EmpCandidates
  },
  {
    path: '/employer-analytics',
    name: 'EmployerAnalytics',
    component: EmployerAnalytics
  },

  {
    path: '/employer-postjob/:id',
    name: 'EmployerPostJob',
    component: EmployerPostJob
  },
  {
    path: '/employer-packagehistory',
    name: 'PackageHistory',
    component: PackageHistory
  },
  {
    path: '/employer-jobs',
    name: 'EmployerJobs',
    component: EmployerJobs
  },
  {
    path: '/employer-teamlist',
    name: 'TeamList',
    component: TeamList
  },
  {
    path: '/employer-addteam',
    name: 'AddTeam',
    component: AddTeam
  },
  {
    path: '/employer-accountsetting',
    name: 'AccountSetting',
    component: AccountSetting
  },
  {
    path: '/employer-candidatesapplied/:id,:empId',
    name: 'CandidatesApplied',
    component: CandidatesApplied
  },
  {
    path: '/cv-search',
    name: 'CvSearch',
    component: CvSearch
  },

  {
    path: '/employer-profile',
    name: 'EmployerProfileInfo',
    component: EmployerProfileInfo
  },
  {
    path: '/employer-dashboard',
    name: 'EmployerDashboard',
    component: EmployerDashboard
  },
  {
    path: '/email-verification/:id',
    name: 'EmailVerification',
    component: EmailVerification
  },

  {
    path: '/end-user-license-agreement',
    name: 'EndUserLicenseAgreement',
    component: EndUserLicenseAgreement
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/cookie-policy',
    name: 'CookiePolicy',
    component: CookiePolicy
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer
  },



  //  Employer Section Start
  // {
  //   path: '/employer-dashboard',
  //   name: 'EmployerDashboard',
  //   component: EmployerDashboard
  // },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("../pages/PageNotFound"),
  },
  { path: "*", name: "redirectPageNotFound", redirect: "/404" }
]

const router = new VueRouter({
  base: process.env.BASE_URL,


  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
