<template>
  <div class="container">
    <div class="container-fluid">
      <div
        class="
          custom-row custom-options-menu
          justify-content-between
          align-items-center
        "
      >
        <div class="customHeading">
          <nav>
            <div
              class="nav nav-tabs custom-nav-tabs"
              id="nav-tab"
              role="tablist"
            >
              <a
                class="
                  nav-item nav-link
                  custom-nav-link custom-nav-heading
                  active
                "
                id="activeJobs-tab"
                data-toggle="tab"
                href="#activeJobs"
                role="tab"
                @click="getProfileInfo(3)"
                aria-controls="activeJobs"
                aria-selected="true"
              >
                <span> Active </span>
              </a>
              <a
                class="nav-item nav-link custom-nav-link custom-nav-heading"
                id="draftJobs-tab"
                data-toggle="tab"
                href="#activeJobs"
                role="tab"
                @click="getProfileInfo(6)"
                aria-controls="draftJobs"
                aria-selected="false"
              >
                <span> Draft </span>
              </a>
              <a
                class="nav-item nav-link custom-nav-link custom-nav-heading"
                id="expiredJobs-tab"
                data-toggle="tab"
                href="#activeJobs"
                role="tab"
                @click="getProfileInfo(99)"
                aria-controls="expiredJobs"
                aria-selected="false"
              >
                <span> Expired Jobs </span>
              </a>
              <a
                class="nav-item nav-link custom-nav-link custom-nav-heading"
                id="pendingApproval-tab"
                data-toggle="tab"
                href="#activeJobs"
                role="tab"
                @click="getProfileInfo(2)"
                aria-controls="pendingApproval"
                aria-selected="false"
              >
                <span> Pending For Approval </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="container-fluid">
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="activeJobs"
          role="tabpanel"
          aria-labelledby="activeJobs-tab"
        >
          <div v-if="isDataFetched">
            <div
              class="pt-3 pb-3 ml-auto mr-auto"
              style="width: 100%; height: 30%"
            >
              <div class="row mt-30">
                <div class="col-lg-12 text-center">
                  <span v-if="isLoading" class="mb-2">
                    <b-spinner small></b-spinner>
                  </span>
                  <b-alert
                    :show="isMessageDismissed"
                    dismissible
                    fade
                    variant="info"
                  >
                    No Data Found...
                  </b-alert>
                </div>
              </div>
              <div class="row mt-30">
                <div
                  class="col-md-6"
                  v-for="(job, index) in arrays.slice().reverse()"
                  :key="job.jobId"
                >
                  <div class="custom-job-height w3-card-2 p-3">
                    <div class="row">
                      <div class="col-lg-12">
                        <h4
                          class="
                            custom-job-title custom-text-color
                            text-capitalize
                          "
                        >
                          {{ job.jobTitle }}
                        </h4>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p>
                          <b>{{ job.city }}</b> - <b>{{ job.countryName }}</b>
                        </p>
                        <span class="offersalary">
  Offer Salary: 
  <span v-if="job.packageTo === 0 || job.packageTo === 1">
    Market Competitive Salary
  </span>
  <span v-else>
    Rs. {{ job.packageFrom }} - {{ job.packageTo }}
  </span>
</span>
                      </div>

                      <div class="col-md-6">
                        <p class="job-posted">
                          Job Posted: {{ job.insertionDate | moment1 }}
                        </p>
                      </div>
                      <div class="col-md-6">
                        <p class="job-posted">
                          Job Expiry: {{ job.jobExpiryDate | moment1 }}
                        </p>
                      </div>

                      <div class="col-md-7">
                        <router-link
                          :to="{
                            name: 'CandidatesApplied',
                            params: { id: job.jobId, empId: 0 },
                          }"
                          type="button"
                          class="btn btn-primary"
                          >View Applicants</router-link
                        >
                        <br />
                        <router-link
                          class="jobbutton"
                          :to="{ name: 'JobDetail', params: { id: job.jobId } }"
                          ><span><i class="fas fa-file"></i></span
                          ><span> View</span></router-link
                        >
                        <router-link
                          :to="{
                            name: 'EmployerPostJob',
                            params: { id: job.jobId },
                          }"
                          ><span><i class="fas fa-pencil-square-o"></i></span
                          ><span> Edit</span></router-link
                        >
                        <!--                        <a class="jobbutton" href=""  ><span><i class="fas  fa-files-o"></i></span><span  > Copy</span></a>-->
                        <br />

                        <a class="jobbutton" href=""
                          ><span><i class="fas fa-power-off"></i></span
                          ><span> Deactivate</span></a
                        >
                      </div>

                      <div class="col-md-5">
                        <button type="button" class="btn btn-primary">
                          Instant Match
                        </button>
                        <br />
                        <a class="jobbutton" href=""
                          ><span><i class="fas fa-link"></i></span
                          ><span> Attach Course </span></a
                        >
                        <a
                          :id="'popover-target-' + index"
                          class="jobbutton"
                          href="javascript:void(0)"
                          ><span><i class="fas fa-share-alt"></i></span
                          ><span> Share</span></a
                        >
                        <b-popover
                          :target="'popover-target-' + index"
                          triggers="hover"
                          placement="bottom"
                        >
                          <div class="custom-row justify-content-center mt-2">
                            <a
                              :href="
                                whatsAppUrl +
                                encodeURIComponent(
                                  domainUrl + '/#/' + job.jobId
                                )
                              "
                              type="url"
                              target="_blank"
                              class="
                                custom-whatsapp-icon custom-icon-style
                                mr-2
                                custom-social-link
                              "
                            >
                              <i class="fab fa-whatsapp"></i>
                            </a>
                            <!--                      <a href="#" type="url" target="_blank" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                                                    <i class="fab fa-facebook-f"></i>
                                                  </a>-->
                            <ShareNetwork
                              class="
                                custom-facebook-icon custom-icon-style
                                mr-2
                                custom-social-link
                              "
                              network="facebook"
                              :url="domainUrl + '/#' + job.jobId"
                              :title="job.jobTitle"
                              :description="job.jobDescription"
                            >
                              <i class="fab fa-facebook-f"></i>
                            </ShareNetwork>

                            <ShareNetwork
                              class="
                                custom-linkedin-icon
                                custom-icon-style
                                custom-social-link
                              "
                              network="linkedin"
                              :url="domainUrl + '/#' + job.jobId"
                              :title="job.jobTitle"
                              :description="job.jobDescription"
                            >
                              <i class="fab fa-linkedin-in"></i>
                            </ShareNetwork>
                          </div>
                        </b-popover>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="draftJobs"
          role="tabpanel"
          aria-labelledby="draftJobs-tab"
        >
          <div>
            <div
              class="w3-card pt-3 pb-3 ml-auto mr-auto"
              style="width: 100%; height: 30%; overflow: auto"
            >
              <div class="w3-container custom-row">
                <img
                  src="../../assets/images/company.jpg"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
                />
                <div>
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Title:
                  </span>
                  <span>Human Resource (Male)</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Company Name:
                  </span>
                  <span>Itroos.pvt.ltd</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Accepted Date:
                  </span>
                  <span>Tuesday, 30th March 2021</span>
                </div>

                <!--                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more ">More</button>
                                  </div>
                                </div>-->
              </div>
              <hr />
              <div class="w3-container custom-row">
                <img
                  src="../../assets/images/company.jpg"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
                />
                <div>
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Title:
                  </span>
                  <span>Human Resource (Male)</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Company Name:
                  </span>
                  <span>Itroos.pvt.ltd</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Accepted Date:
                  </span>
                  <span>Tuesday, 30th March 2021</span>
                </div>

                <!--                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more ">More</button>
                                  </div>
                                </div>-->
              </div>
              <!--
                            <hr>
                            <div class="w3-container custom-row">

                              <img src="../../assets/images/company.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                              <div>
                                <p class="font-weight-bold w3-text-pink m-0">Job Name</p>
                                <span>Full Time</span>
                              </div>


                              <div class="ml-auto">
                                <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                <div>
                                  <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                </div>

                                <div>
                                  <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                </div>
                                <div>
                                  <button class="btn btn-primary btn-more">More</button>
                                </div>
                              </div>
                            </div>
              -->
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="expiredJobs"
          role="tabpanel"
          aria-labelledby="expiredJobs-tab"
        >
          <div>
            <div
              class="w3-card pt-3 pb-3 ml-auto mr-auto"
              style="width: 100%; height: 30%; overflow: auto"
            >
              <div class="w3-container custom-row">
                <img
                  src="../../assets/images/company.jpg"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
                />
                <div>
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Title:
                  </span>
                  <span>Human Resource (Male)</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Company Name:
                  </span>
                  <span>Itroos.pvt.ltd</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Expiration Date:
                  </span>
                  <span>Tuesday, 30th March 2021</span>
                </div>

                <!--                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more ">More</button>
                                  </div>
                                </div>-->
              </div>
              <hr />
              <div class="w3-container custom-row">
                <img
                  src="../../assets/images/company.jpg"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
                />
                <div>
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Title:
                  </span>
                  <span>Human Resource (Male)</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Company Name:
                  </span>
                  <span>Itroos.pvt.ltd</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Expiration Date:
                  </span>
                  <span>Tuesday, 30th March 2021</span>
                </div>

                <!--                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more ">More</button>
                                  </div>
                                </div>-->
              </div>
              <!--
                            <hr>
                            <div class="w3-container custom-row">

                              <img src="../../assets/images/company.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                              <div>
                                <p class="font-weight-bold w3-text-pink m-0">Job Name</p>
                                <span>Full Time</span>
                              </div>


                              <div class="ml-auto">
                                <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                <div>
                                  <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                </div>

                                <div>
                                  <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                </div>
                                <div>
                                  <button class="btn btn-primary btn-more">More</button>
                                </div>
                              </div>
                            </div>
              -->
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pendingApproval"
          role="tabpanel"
          aria-labelledby="pendingApproval-tab"
        >
          <div>
            <div
              class="w3-card pt-3 pb-3 ml-auto mr-auto"
              style="width: 100%; height: 30%; overflow: auto"
            >
              <div class="w3-container custom-row">
                <img
                  src="../../assets/images/company.jpg"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
                />
                <div>
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Title:
                  </span>
                  <span>Human Resource (Male)</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Company Name:
                  </span>
                  <span>Itroos.pvt.ltd</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Interview Date:
                  </span>
                  <span>Tuesday, 30th March 2021</span>
                </div>
              </div>
              <hr />
              <div class="w3-container custom-row">
                <img
                  src="../../assets/images/company.jpg"
                  alt="Avatar"
                  class="w3-left w3-circle w3-margin-right"
                  style="width: 60px"
                />
                <div>
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Title:
                  </span>
                  <span>Human Resource (Male)</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Company Name:
                  </span>
                  <span>Itroos.pvt.ltd</span>
                  <br />
                  <span class="font-weight-bold custom-text-color m-0"
                    >Job Interview Date:
                  </span>
                  <span>Tuesday, 30th March 2021</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--content area end-->

    <div class="style-spacer"></div>
  </div>
</template>


<script>
import Vue from 'vue'
import axios from "axios";
import moment from "moment";
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

export default {
  name: "EmployerJobs",
  components: {},
  data() {
    return {
      url:axios.defaults.baseURL,
      whatsAppUrl:"https://wa.me/?text=",
      linkedInUrl:"https://www.linkedin.com/sharing/share-offsite/?url=",
      domainUrl:"https://shemeanswork.com",

      // fullJobUrl:"",
       jobUrl:this.$route.fullPath,
      has_error: false,
      isDataFetched: false,
      isLoading: false,
      isMessageDismissed: false,
      EmployerId: this.$auth.user().employeeId,
      arrays: [],
      employerObj: null,
      Interviewed: 6,
      Hired : 4,
      Shortlisted : 7,
      body: {
        employerCompanyId: 0,
        companyEmail: null,
        companyName: null,
        ceoName: null,
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: null,
        countryId: 106,
        cityId: 0,
        address: null,
        establishedInYear: 0,
        websiteUrl: null,
        contactNumber: null,
        googleMapIframe: null,
      },
      bodyJobFilter: {
        jobStatus: 0,
        ExpiredJobsOnly : 0
      },
    };
  },
  computed: {
    sortedArray: function () {
      function compare(a, b) {
        if (a.jobId < b.jobId) return -1;
        if (a.jobId > b.jobId) return 1;
        return 0;
      }

      return this.arrays.sort(compare);
    },
  },
  mounted() {
    // /EmployerCompany/GetEmployerCompany
    if (this.$route.params.status == undefined){
      this.getProfileInfo(3);
    }
    else {
      this.navigate();
      console.log("status : ",this.$route.params.status)
    }


  },
  methods: {
    //   formatDate(date) {
    //   return moment(date).format("Do MMM YYYY")
    // },
    // sortArrays(arrays) {
    //         return _.orderBy(arrays, 'insertionDate', 'desc');
    //     },
    getProfileInfo(js) {
  this.arrays = [];
  this.isLoading = true;
  if (Number(js) === 99) {
    this.bodyJobFilter.ExpiredJobsOnly = 1;
    this.bodyJobFilter.jobStatus = 0;
  } else {
    this.bodyJobFilter.ExpiredJobsOnly = 0;
    this.bodyJobFilter.jobStatus = Number(js);
  }
  axios
    .post("/api/EmployerCompany/GetFilteredJobs", this.bodyJobFilter)
    .then((res) => {
      if (res.status !== 200) {
        console.log("Error: Failed to fetch data.");
        throw new Error("Failed to fetch data");
      }

      let resdata = res.data.data;
      console.log("Response data: ", resdata);

      if (resdata.length !== 0) {
      
        console.log("Updated job data: ", resdata[0]);
        this.jobData = resdata[0];
        this.setCompanyId = this.jobData.companyId;

        // Check if this.breadcrumbItems is defined and has at least 3 elements
        if (!this.breadcrumbItems || this.breadcrumbItems.length < 3) {
          this.breadcrumbItems = [{}, {}, {}];
        }
        this.breadcrumbItems[2].text = this.jobData.jobTitle;

        let currentDate = moment().format('YYYY-MM-DD');
        if (Number(js) !== 99) {
          resdata.forEach(data => {
            if (moment(data.jobExpiryDate).format('YYYY-MM-DD') >= currentDate) {
              this.arrays.push(data);
            }
          });
        } else {
          this.arrays = resdata;
        }
        this.isLoading = false;
        this.isDataFetched = true;
        this.arrays.length === 0 ? this.showAlert() : this.hideAlert();
      } else {
        console.log("No data available.");
      }
    })
    .catch((error) => {
      console.log("Error: ", error);
    })
    .finally(() => {
      // Cleanup or final actions
    });
},

    navigate(){
      if (this.$route.params.status === "1"){
        $('#nav-tab a:nth-child(1)').tab('show')
      }
      else if (this.$route.params.status === "2"){
        this.getProfileInfo(6);
        $('#nav-tab a:nth-child(2)').tab('show')
      }
      else if (this.$route.params.status === "3"){
        $('#nav-tab a:nth-child(3)').tab('show')
      }
      else if (this.$route.params.status === "4"){
        this.getProfileInfo(2);
        $('#nav-tab a:nth-child(4)').tab('show')
      }

    },
    showAlert() {
      this.isMessageDismissed = true
    },
    hideAlert() {
      this.isMessageDismissed = false
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).fromNow();
    },

    moment1: function (date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },

};
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #ea216c;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>
