<template>
  <div :id="mainContainer" :key="componentKey">
    <!-- Banner Section For Web Start -->
    <div
      id="carouselExampleControls"
      class="carousel slide"
      data-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active">
          <section class="webSection custom-landing-banner-section">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section">
                      <div
                        class="
                          col-lg-7
                          custom-column-banner-heading
                          custom-carousel-left-z-index
                        "
                      >
                        <div class="custom-banner-heading">
                          <span>Lets Start Your Job </span><br />
                          <span
                            >With
                            <span class="custom-text-color"
                              >She Means Work</span
                            ></span
                          >
                        </div>
                        <div class="custom-banner-p">
                          <p>
                            Find Jobs, Learn with Vii Learn, Strengthen your
                            business with Venture(H)er & Connect with HarShehar
                          </p>
                        </div>
                        <div class="row mt-4 w3-animate-bottom">
                          <div class="col-lg-6">
                            <div class="custom-form-submit-btn">
                              <router-link
                                :to="{ name: 'SignUp' ,params: { id: 2 }}"
                                class="
                                  btn
                                  custom-btn-search custom-employee-btn
                                  my-2
                                "
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employee
                                </span>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="custom-form-submit-btn">
                              <router-link
                                :to="{ name: 'SignUp',params: { id: 3 } }"
                                class="
                                  btn
                                  custom-btn-search custom-employer-btn
                                  my-2
                                "
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employer
                                </span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col-lg-5
                          custom-column-form-widget
                          custom-carousel-right-z-index
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section">
                      <div
                        class="
                          col-lg-7
                          custom-column-banner-heading
                          custom-carousel-left-z-index
                        "
                      >
                        <div class="custom-banner-heading">
                          <span
                            >Empower yourself with
                            <span class="custom-text-color"
                              >Employment</span
                            ></span
                          ><br />
                        </div>
                        <div class="custom-banner-p">
                          <p>
                            Find Jobs, Learn with Vii Learn, Strengthen your
                            business with Venture(H)er & Connect with HarShehar
                          </p>
                        </div>
                        <div class="row mt-4 w3-animate-bottom">
                          <div class="col-lg-6">
                            <div class="custom-form-submit-btn">
                              <router-link
                                :to="{ name: 'SignUp' }"
                                class="
                                  btn
                                  custom-btn-search custom-employee-btn
                                  my-2
                                "
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employee
                                </span>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="custom-form-submit-btn">
                              <router-link
                                :to="{ name: 'SignUp' }"
                                class="
                                  btn
                                  custom-btn-search custom-employer-btn
                                  my-2
                                "
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employer
                                </span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col-lg-5
                          custom-column-form-widget
                          custom-carousel-right-z-index
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="carousel-item">
          <section class="webSection custom-landing-banner-section">
            <div class="custom-banner-background-overlay"></div>
            <div class="custom-section">
              <div class="custom-row-wrap">
                <div class="custom-row-element">
                  <div class="custom-row-element-wrap">
                    <div class="row custom-row-section">
                      <div
                        class="
                          col-lg-7
                          custom-column-banner-heading
                          custom-carousel-left-z-index
                        "
                      >
                        <div class="custom-banner-heading">
                          <span>Connecting the Right </span
                          ><span class="custom-text-color">People</span><br />
                          <span>to the right </span
                          ><span class="custom-text-color">jobs</span>
                        </div>
                        <div class="custom-banner-p">
                          <p>
                            Find Jobs, Learn with Vii Learn, Strengthen your
                            business with Venture(H)er & Connect with HarShehar
                          </p>
                        </div>
                        <div class="row mt-4 w3-animate-bottom">
                          <div class="col-lg-6">
                            <div class="custom-form-submit-btn">
                              <router-link
                                :to="{ name: 'SignUp' }"
                                class="
                                  btn
                                  custom-btn-search custom-employee-btn
                                  my-2
                                "
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employee
                                </span>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="custom-form-submit-btn">
                              <router-link
                                :to="{ name: 'SignUp' }"
                                class="
                                  btn
                                  custom-btn-search custom-employer-btn
                                  my-2
                                "
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employer
                                </span>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="
                          col-lg-5
                          custom-column-form-widget
                          custom-carousel-right-z-index
                        "
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <a
        class="carousel-control-prev custom-carousel-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a
        class="carousel-control-next custom-carousel-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>

    <section class="webSection custom-landing-banner-section-form w-100">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element">
            <div class="custom-row-element-wrap">
              <div class="row custom-row-section">
                <div
                  class="
                    col-lg-8
                    custom-column-banner-heading
                    custom-widget-section-left-z-index
                  "
                ></div>
                <div
                  class="
                    col-lg-4
                    custom-column-form-widget
                    custom-widget-section-right-z-index
                  "
                >
                  <div class="custom-column-wrap">
                    <div class="custom-row-wrap">
                      <div class="custom-widget-heading">
                        <div class="custom-widget-heading-title">
                          <span
                            class="font-weight-bold custom-underline w-100 pb-2"
                            >FIND YOUR JOB!
                          </span>
                        </div>
                      </div>
                      <div class="custom-widget-form-control">
                        <div class="form-group">
                          <div class="">
                            <input
                              type="text"
                              class="
                                form-control
                                custom-input-gotopage custom-widget-form-group
                              "
                              placeholder="What are you looking for?"
                              v-model="jobSearchByNameOrCity.jobTitle"
                              @keydown.enter.prevent="setSearchFiter()"
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="">
                            <div class="input-group mb-3">
                              <select
                                id="city_id"
                                class="
                                  form-control
                                  custom-input-gotopage custom-widget-form-group
                                "
                                v-model="selectedCity"
                              >
                                <option value="0">Select City</option>
                                <option
                                  v-for="option in cities"
                                  :key="option.key"
                                  :value="option.key"
                                >
                                  {{ option.value }}
                                </option>
                              </select>
                              <div class="input-group-append">
                                <span
                                  class="
                                    input-group-text
                                    custom-widget-form-group
                                    custom-widget-location-inputGroup
                                  "
                                  id="basic-addon2"
                                >
                                  <i class="fas fa-map-marker-alt"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="custom-form-submit-btn">
                              <router-link :to="{name: 'AllJobs'}" class="btn custom-btn-search my-2 my-sm-0">
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-search"></i>
                                  See all
                                </span>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="custom-form-submit-btn">
                              <a
                                href="javascript:void(0)"
                                @click="setSearchFiter()"
                                class="btn custom-btn-search my-2 my-sm-0"
                              >
                                <span
                                  class="custom-btn-more-text font-weight-bold"
                                >
                                  <i class="fas fa-search"></i>
                                  Search
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner Section For Web End -->

    <!--SPACER-->
    <div class="style-spacer"></div>

    <!--SPACER-->
    <div class="style-spacer p-3"></div>
    <!--  Topemployer Start  -->
    <div class="style-upskill-topemployer">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="style-adv-dividers">
              <div href="#" class="style-no-outline style-latest-jobs mt-3">
                <div class="text-center">MAKE YOUR COME BACK PROGRAM (MYCB).!</div>
              </div>
              <div class="text-center">
                Calling all female professionals who want to come back to work. Philip Morris (Pakistan) Limited (PMPKL) is currently hiring through their “Make Your Come Back” (MYCB) program. Apply on the link given below
                </div>
                <a 
                href="https://www.pmi.com/careers/job-details?id=77864"
                style="display:flex; justify-content: center; color:#007bff;">
                  https://www.pmi.com/careers/job-details?id=77864
                </a>
                <div
                  v-if="homeUpSkillVlearnAdd.length != 0"
                  class="style-image text-center"
                  style="padding:5px;"
                  >
                  <a
                    href="https://www.pmi.com/careers/job-details?id=77864"
                    :title="homeUpSkillVlearnAdd[0].url"
                    target="_blank"
                  >
                    <img :src="url + homeUpSkillVlearnAdd[0].image" />
                  </a>
                </div>
                <div v-else class="style-image">
                  <img src="../assets/images/e-learning.png" />
                </div>
              
              
            </div>
          </div>

          <div class="col-lg-6">
            <div class="style-adv-dividers">
              <div class="row">
                <div class="col-lg-12">
                  <div class="style-no-outline mt-3 style-latest-jobs">
                    <div class="text-center">Friends With SMW</div>
                  </div>
                  <div class="text-center">
                    Associates with the top-notch industry players in Pakistan, to bring collective actions with effective results.
                  </div>
                </div>
              </div>

              <div class="style-slider mt-4 ml-4 mr-4">
                <div>
                  <VueSlickCarousel
                    class="container"
                    v-bind="settingsForFeaturedBrands"
                    v-if="brands.length != 0"
                  >
                    <div
                      class="style-brands bg-white"
                      v-for="br in featuredbrands"
                      :key="br.brandId"
                    >
                      <a
                        :href="br.brandUrl"
                        target="_blank"
                        :title="br.brandName"
                      >
                        <img :src="url + br.brandImage" />
                      </a>
                    </div>
                  </VueSlickCarousel>
                </div>
                <div class="text-center clear-bottom mt-3">
                  <router-link
                    :to="{
                      name: 'AllFeaturedBrands',
                      params: { brandtype: 'FeatureBrand' },
                    }"
                    class="btn btn-theme"
                  >
                    <i aria-hidden="true" class="fa fa-plus-circle"> </i> View
                    More</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--SPACER-->
    <div class="style-spacer"></div>
    <!-- Feature Jobs Start-->
    <div v-if="featuredJobs != null">
      <div class="container">
        <div class="custom-row">
          <div class="flex-grow-1">
            <div class="style-latest-jobs">
              <div class="">
                <span>Featured Jobs</span>
              </div>
            </div>
          </div>
          <div class="">
            <div class="float-right">
              <a @click="showPreviousLatestJob()" class="style-hand-icon">
                <span
                  ><arrow-left-circle-icon
                    size="1.9x"
                    class="custom-class back-btn"
                  ></arrow-left-circle-icon
                ></span>
              </a>

              <a @click="showNextLatestJob()" class="style-hand-icon">
                <span
                  ><arrow-right-circle-icon
                    size="1.9x"
                    class="custom-class forward-btn"
                  ></arrow-right-circle-icon
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="style-latest-jobs-slides">
        <div class="container">
          <div class="row no-gutters">
            <div class="col-12">
              <div class="style-multirow-slide">
                <VueSlickCarousel
                  class="container"
                  v-bind="settingsForLatestOrFeaturedJobs"
                  ref="carousel_latest_jobs"
                  v-if="featuredJobs.length != 0"
                >
                  <div
                    class="row-slider-item custom-home-jobs-height"
                    v-for="jt in featuredJobs"
                    :key="jt.jobId"
                  >
                    <div class="row no-gutters align-items-center">
                      <div class="col-sm-9">
                        <router-link
                          class="text-decoration-none"
                          :to="{ name: 'JobDetail', params: { id: jt.jobId } }"
                          title="Job Detail"
                        >
                          <div class="row no-gutters align-items-center">
                            <div class="col-sm-2">
                              <div class="style-left-img">
                                <span
                                  class="
                                    b-avatar
                                    badge-info
                                    rounded-circle
                                    custom-b-avatar
                                  "
                                >
                                  <span class="b-avatar-img">
                                    <span
                                      v-if="
                                        jt.companyLogoAddress != null &&
                                        jt.companyLogoAddress != ''
                                      "
                                    >
                                      <img :src="url + jt.companyLogoAddress" />
                                    </span>
                                    <span v-else>
                                      <img
                                        src="../assets/images/no-image.png"
                                      />
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-10">
                              <div
                                class="
                                  custom-job-title
                                  text-dark text-capitalize
                                  style-text-14
                                "
                              >
                               <u> {{ jt.jobTitle }}</u>
                              </div>
                              <div
                                class="
                                  style-company
                                  custom-muted-text
                                  style-text-14
                                "
                              >
                                {{ jt.companyName }}
                              </div>
                              <div class="style-job-metas">
                                <div
                                  class="
                                    style-job-salary
                                    custom-muted-text
                                    style-text-14
                                  "
                                >
                                  <i class="fas fa-money-bill"></i>
                                  <span v-if="jt.packageTo === 0 || jt.packageTo === 1">Market Competitive Salary</span>
    <span v-else>Rs {{ jt.packageFrom }} - {{ jt.packageTo }}/Month</span>

                                </div>
                                <div
                                  class="
                                    location
                                    custom-muted-text
                                    style-text-14
                                  "
                                >
                                  <span>
                                    <i
                                      class="text-second fa fa-map-marker"
                                      aria-hidden="true"
                                      data-v-3c8a640f=""
                                    ></i>
                                  </span>
                                  <span
                                    >{{ jt.cityName }},
                                    {{ jt.countryName }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>

                      <div class="col-sm-3">
                        <div class="mb-3">
                          <span>
                            <a
                              @click="showOrHideModal(jt)"
                              class="
                                btn btn-primary
                                custom-employer-btn custom-btn-apply-now
                              "
                              >EasyApply Now</a
                            >
                          </span>
                        </div>

                        <div class="">
                          <a
                            class="
                              btn btn-primary
                              custom-employer-btn
                              custom-btn-apply-now
                              custom-btn-bookmark
                            "
                            @click="onSavingJob(jt)"
                            >Save</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="text-center clear-bottom">
                <router-link
                  :to="{
                    name: 'AllFeaturedOrLatestJobs',
                    params: { typeJob: 'FeatureJob' },
                  }"
                  class="btn btn-theme text-decoration-none"
                  href="#"
                >
                  <i aria-hidden="true" class="fa fa-plus-circle"> </i> View
                  More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Feature Jobs End-->
    <!--SPACER-->
    <div class="style-spacer"></div>
    <!-- Latest Jobs Start-->
    <div v-if="latestJobs != null">
      <div class="container">
        <div class="custom-row">
          <div class="flex-grow-1">
            <div class="style-latest-jobs">
              <div class="">
                <span>Latest Jobs</span>
              </div>
            </div>
          </div>
          <div class="">
            <div class="">
              <a @click="showPreviousExecutiveJobs()" class="style-hand-icon">
                <span
                  ><arrow-left-circle-icon
                    size="1.9x"
                    class="custom-class back-btn"
                  ></arrow-left-circle-icon
                ></span>
              </a>

              <a @click="showNextExecutiveJobs()" class="style-hand-icon">
                <span
                  ><arrow-right-circle-icon
                    size="1.9x"
                    class="custom-class forward-btn"
                  ></arrow-right-circle-icon
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="style-latest-jobs-slides">
        <div class="container">
          <div class="row no-gutters">
            <div class="col">
              <div class="style-multirow-slide">
                <VueSlickCarousel
                  class="container"
                  v-bind="settingsForLatestOrFeaturedJobs"
                  ref="carousel_Executive_Jobs"
                  v-if="latestJobs.length != 0"
                >
                  <div
                    class="row-slider-item custom-home-jobs-height"
                    v-for="jt in latestJobs"
                    :key="jt.jobId"
                  >
                    <div class="row no-gutters align-items-center">
                      <div class="col-sm-9">
                        <router-link
                          class="text-decoration-none"
                          :to="{ name: 'JobDetail', params: { id: jt.jobId } }"
                          title="Job Detail"
                        >
                          <div class="row no-gutters align-items-center">
                            <div class="col-sm-2">
                              <div class="style-left-img">
                                <span
                                  class="
                                    b-avatar
                                    badge-info
                                    rounded-circle
                                    custom-b-avatar
                                  "
                                >
                                  <span class="b-avatar-img">
                                    <span
                                      v-if="
                                        jt.companyLogoAddress != null &&
                                        jt.companyLogoAddress != ''
                                      "
                                    >
                                      <img :src="url + jt.companyLogoAddress" />
                                    </span>
                                    <span v-else>
                                      <img
                                        src="../assets/images/no-image.png"
                                      />
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div class="col-sm-10">
                              <div
                                class="
                                  custom-job-title
                                  text-dark text-capitalize
                                  style-text-14
                                "
                              >
                               <u> {{ jt.jobTitle }}</u>
                              </div>
                              <div
                                class="
                                  style-company
                                  custom-muted-text
                                  style-text-14
                                "
                              >
                                {{ jt.companyName }}
                              </div>
                              <div class="style-job-metas">
                                <div
                                  class="
                                    style-job-salary
                                    custom-muted-text
                                    style-text-14
                                  "
                                >
                                  <i class="fas fa-money-bill"></i>
                                  <span v-if="jt.packageTo === 0 || jt.packageTo === 1">Market Competitive Salary</span>
    <span v-else>Rs {{ jt.packageFrom }} - {{ jt.packageTo }}/Month</span>
                                </div>
                                <div
                                  class="
                                    location
                                    custom-muted-text
                                    style-text-14
                                  "
                                >
                                  <span>
                                    <i
                                      class="text-second fa fa-map-marker"
                                      aria-hidden="true"
                                      data-v-3c8a640f=""
                                    ></i>
                                  </span>
                                  <span
                                    >{{ jt.cityName }},
                                    {{ jt.countryName }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>

                      <div class="col-sm-3">
                        <div class="mb-3">
                          <span>
                            <a
                              @click="showOrHideModal(jt)"
                              class="
                                btn btn-primary
                                custom-employer-btn custom-btn-apply-now
                              "
                              >EasyApply Now</a
                            >
                          </span>
                        </div>

                        <div class="">
                          <a
                            class="
                              btn btn-primary
                              custom-employer-btn
                              custom-btn-apply-now
                              custom-btn-bookmark
                            "
                            @click="onSavingJob(jt)"
                            >Save</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="text-center clear-bottom">
                <router-link
                  :to="{
                    name: 'AllFeaturedOrLatestJobs',
                    params: { typeJob: 'LatestJob' },
                  }"
                  class="btn btn-theme text-decoration-none"
                  href="#"
                >
                  <i aria-hidden="true" class="fa fa-plus-circle"> </i> View
                  More</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Latest Jobs End-->
    <!--SPACER-->
    <div class="style-spacer"></div>

    <!--SPACER-->
    <div class="style-spacer"></div>
    <!-- Events Start-->
    <div class="container">
      <div class="custom-row">
        <div class="flex-grow-1">
          <div class="style-latest-jobs">
            <div class="">
              <span>Our Events</span>
            </div>
          </div>
        </div>
        <div class="">
          <div class="">
            <a @click="showPrevious()" class="style-hand-icon">
              <span
                ><arrow-left-circle-icon
                  size="1.9x"
                  class="custom-class back-btn"
                ></arrow-left-circle-icon
              ></span>
            </a>

            <a @click="showNext()" class="style-hand-icon">
              <span
                ><arrow-right-circle-icon
                  size="1.9x"
                  class="custom-class forward-btn"
                ></arrow-right-circle-icon
              ></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col">
          <VueSlickCarousel
            class="container"
            v-bind="settingsForThreeRows"
            ref="carousel"
            v-if="events.length != 0"
          >
            <div v-for="ev in events" :key="ev.id">
              <article class="mb-5">
                <div class="post-container">
                  <div class="post-thumbnail mt-4">
                    <div class="sh-ratio">
                      <div class="">
                        <div class="custom-post-large-image">
                          <img v-if="ev.image != null" :src="url + ev.image" />
                          <img v-else src="../assets/images/no-image.png" />
                        </div>
                      </div>
                    </div>
                    <router-link
                      :to="{ name: 'Detail', params: { id: ev.id } }"
                      :title="ev.title"
                      class="post-overlay"
                    >
                      <div class="">
                        <span></span> <span></span> <span></span>
                      </div>
                    </router-link>
                  </div>
                  <div class="container">
                    <div class="post-content-container mt-3">
                      <div class="post-meta">
                        <div class="post-meta-content">
                          <span class="post-auhor-date custom-row">
                            <span class="flex-grow-1">
                              <b-icon
                                class="icon"
                                icon="clock-history"
                              ></b-icon>
                              <span class="post-author ml-2">{{
                                ev.date | formatDate
                              }}</span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <!-- Events End-->
    <!--SPACER-->

    <!--ADVERTISEMENT START--->
    <div class="style-advertisement-portion">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="style-category-wrapper-item">
              <div
                v-if="homeBeforeFooterAdd.length != 0"
                class="w-100 text-center"
              >
                <a
                  :href="homeBeforeFooterAdd[0].url"
                  :title="homeBeforeFooterAdd[0].url"
                  target="_blank"
                  class="navbar-brand mr-0"
                >
                  <img
                    :src="url + homeBeforeFooterAdd[0].image"
                    class="w-100"
                  />
                </a>
              </div>
              <a v-else href="#">
                <div class="text-center">Advertisement</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--SPACER-->
    <div class="style-spacer"></div>

    <!--SPACER-->
    <div class="style-spacer"></div>

    <div class="style-full-page-slide w-100">
      <div class="row no-gutters align-items-center w-100 h-100">
        <!--        <div class="col-lg-1"></div>-->
        <div class="col-lg-12">
          <!--          <vueper-slides :dragging-distance="50"
                         autoplay
                         :pause-on-hover="pauseOnHover"
          >
            <vueper-slide
                v-for="(slide, i) in fullPageslides"
                :key="i"

                :title="slide.title"
                :content="slide.content"


            />
          </vueper-slides>-->

          <div class="style-slider mt-4">
            <div class="text-white text-center">
              <VueSlickCarousel
                class="container"
                v-bind="settingsForThreeRows"
                ref="testimonials_carousel"
                v-if="testimonials.length != 0"
              >
                <div
                  class="blog-mini-post blog-mini-post-large pl-3 pr-3"
                  v-for="tes in testimonials"
                  :key="tes.id"
                >
                  <!--                  <span class="b-avatar badge-info rounded-circle custom-rounded-avatar">
                    <span  class="b-avatar-img">
                      <span v-if="false">
                         <img :src="url+jt.companyLogoAddress">
                       </span>
                       <span v-else>

                            <img src="../assets/images/no-image.png" >


                       </span>
                      &lt;!&ndash;                                  {{ usernamefirstletter }}&ndash;&gt;
                      &lt;!&ndash;                        <img src="../assets/images/avatar-2.jpg">&ndash;&gt;

                    </span>

                  </span>-->
                  <div>
                    <div class="blog-mini-post-thumb sh-post-review-mini">
                      <div class="">
                        <img
                          v-if="tes.image != null"
                          :src="url + tes.image"
                          class="custom-post-small-image"
                        />
                        <img
                          v-else
                          src="../assets/images/no-image.png"
                          class="custom-post-small-image"
                        />
                      </div>
                    </div>
                  </div>
                  <p
                    class="mt-3 font-italic text-white-50"
                    v-html="tes.description"
                  ></p>
                  <div class="post-meta">
                    <div class="post-meta-content">
                      <span class="post-auhor-date text-capitalize">
                        <span class="">
                          <!--                            <b-icon class="icon" icon="clock-history"></b-icon>-->
                          <span class="post-author ml-2 text-white">{{
                            tes.name
                          }}</span>
                        </span>
                        <span>
                          <!--                                  <users-icon size="1.5x" class="custom-text-color custom-class"></users-icon>-->
                          <span class="post-author ml-2 custom-text-color"
                            >({{ tes.designation }})</span
                          >
                        </span>
                      </span>
                      <!--                        <a
                                                href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                class="post-comments">
                                                <i class="icon icon-bubble"></i> 0
                                              </a>
                                              <span class="post-readtime">
                                                <i class="icon icon-clock"></i> 1 min <span>read</span>
                                              </span>-->
                    </div>
                  </div>
                </div>
                <!--                <div class="">
                  <img src="../assets/images/apus.jpg" class="center" />
                  <p align="center">Itroos Pvt ltd.</p>
                </div>
                <div class="">
                  <img src="../assets/images/apus.jpg" class="center" />
                  <p align="center">Itroos Pvt ltd.</p>
                </div>
                <div class="">
                  <img src="../assets/images/company.jpg" class="center" />
                  <p align="center">Itroos Pvt ltd.</p>
                </div>
                <div class="">
                  <img src="../assets/images/company.jpg" class="center" />
                  <p align="center">Itroos Pvt ltd.</p>
                </div>
                <div class="">
                  <img src="../assets/images/company.jpg" class="center" />
                  <p align="center">Itroos Pvt ltd.</p>
                </div>-->
              </VueSlickCarousel>
            </div>
            <div class="text-center">
              <div class="pt-3">
                <a
                  @click="showPreviousTestimonials()"
                  class="style-hand-icon pr-1 style-grey-font"
                >
                  <span
                    ><arrow-left-circle-icon
                      size="1.9x"
                      class="custom-class back-btn"
                    ></arrow-left-circle-icon
                  ></span>
                </a>

                <a
                  @click="showNextTestimonials()"
                  class="style-hand-icon style-grey-font"
                >
                  <span
                    ><arrow-right-circle-icon
                      size="1.9x"
                      class="custom-class forward-btn"
                    ></arrow-right-circle-icon
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="col-lg-1"></div>-->
      </div>
    </div>

    <div class="container-fluid style-grey-bg style-brand-spaces">
      <div class="container">
        <div class="row no-gutters">
          <!--          <div class="col-2">
            <div class="style-brands-btns">
              <a
                @click="showPreviousBrandsCar()"
                class="style-hand-icon vertical-center style-grey-font"
              >
                <span>
                  <arrow-left-circle-icon size="1.9x" class="custom-class back-btn">
                  </arrow-left-circle-icon
                ></span>
              </a>
            </div>
          </div>-->
          <div class="col-12">
            <VueSlickCarousel
              class="container"
              v-bind="settingsForBrands"
              ref="brands_carousel"
              v-if="brands.length != 0"
            >
              <div class="style-brands" v-for="br in brands" :key="br.brandId">
                <a :href="br.brandUrl" target="_blank" :title="br.brandName">
                  <img :src="url + br.brandImage" />
                </a>
              </div>
              <!--              <div class="style-brands">
                <img src="../assets/images/balint.jpg" />
              </div>
              <div class="style-brands">
                <img src="../assets/images/advanced.jpg" />
              </div>
              <div class="style-brands">
                <img src="../assets/images/arc.jpg" />
              </div>
              <div class="style-brands">
                <img src="../assets/images/artwater.jpg" />
              </div>
              <div class="style-brands">
                <img src="../assets/images/casca.jpg" />
              </div>-->
            </VueSlickCarousel>
            <div class="text-center">
              SheMeansWork is Bound by Vision and driven by Partnership, Our Collaboration Catalyzes Excellence and Empowers Success Together. 
             </div>
             <div class="text-center">
             We are determined and resilient in making a future through fueling Progress Through Partnership.
            </div>
            </div>
          <!--          <div class="col-2">
            <div class="style-brands-btns">
              <a
                @click="showNextBrandsCar()"
                class="style-hand-icon vertical-center style-grey-font"
              >
                <span
                  ><arrow-right-circle-icon
                    size="1.9x"
                    class="custom-class forward-btn"
                  ></arrow-right-circle-icon
                ></span>
              </a>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <!-- Complete profile Modal-->
    <div class="">
      <!--      <CPModal v-if="isModalVisible" :signInOrSignUpSection="isSignInOrSignUpSection" :employeeProfileFormSection="isEmployeeProfileFormSection" :selectedJobId="jobID" @get-strength="recievedStrength" @toggle-modal="toggleModal">

      </CPModal>-->
      <CPModal
        v-if="isModalVisible"
        :save-job-status="isSaveJobStatus"
        :signInOrSignUpSection="isSignInOrSignUpSection"
        :employeeProfileFormSection="isEmployeeProfileFormSection"
        :show-alert-msg="isShowAlertMsg"
        :show-profile-info-card="isShowProfileInfoCard"
        :apply-job-confirmation-section="isApplyJobConfirmationSection"
        :selected-job-record="jobRecord"
        @get-strength="recievedStrength"
        @toggle-modal="toggleModal"
      >
      </CPModal>
      <ConfirmationModal
        v-if="isConfirmationModalVisible"
        @toggle-modal="closeModal"
      ></ConfirmationModal>
    </div>
  </div>
</template>

<script>
import CPModal from "../components/employeeComponents/CompleteProfileModalComponent"
import ConfirmationModal from "../components/ConfirmationModal";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

import VueSlickCarousel from "vue-slick-carousel";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { ArrowLeftCircleIcon } from "vue-feather-icons";
import { ArrowRightCircleIcon } from "vue-feather-icons";
import axios from "axios";
import moment from "moment";

export default {
  name: "Home",
  components: {
    CPModal,
    ConfirmationModal,
    VueperSlides,
    VueperSlide,
    VueSlickCarousel,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,

  },
  data() {
    return {
      mainContainer:"main-container",

      //Find Job Form
      // cities:null,
      selectedCity:0,
      /*jobTitle:"",*/



      slides: [
        {
          title: "John Doe",
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require("@/assets/images/avatar-2.jpg"),
        },
        {
          title: "John Doe",
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require("@/assets/images/avatar-2.jpg"),
        },
        {
          title: "John Doe",
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require("@/assets/images/avatar-2.jpg"),
        },
        {
          title: "John Doe",
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require("@/assets/images/avatar-2.jpg"),
        },
        {
          title: "John Doe",
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require("@/assets/images/avatar-2.jpg"),
        },
        {
          title: "John Doe",
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require("@/assets/images/avatar-2.jpg"),
        },
      ],
      settingsForLatestOrFeaturedJobs: {

        infinite: true,
        slidesToShow: 2,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,

        arrows: false,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,

              infinite: true

            }
          }
        ]
      },
      settingsForTopEmployers: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 2,
        slidesPerRow: 1,
        //arrows: false,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
      },

      settingsForThreeRows: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: false,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
      },
      settingsForBrands: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 3,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 2,

              "infinite": true,

            }
          }
        ]
      },
      settingsForFeaturedBrands: {
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
      },
      settingsForTwoSlides: {
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        "autoplay": true,
        "autoplaySpeed": 3000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [

          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
        /*focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 5,*/
      },


      settingsForOneSlide: {
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        "autoplay": true,
        "autoplaySpeed": 2000,
        arrows: false,
        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [

          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
        /*focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 5,*/
      },
      // pauseOnHover: true,
      // autoPlaying: true,
      // internalAutoPlaying: true,

      // fullPageslides: [
      //   {
      //     title: "El Teide Volcano, Spain",
      //     content: "Photo by Max Rive",
      //     // You can also provide a URL for the image.
      //     image: require("@/assets/images/slider11.jpg"),
      //     link:
      //       "https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk",
      //   },
      //   {
      //     title: "El Teide Volcano, Spain",
      //     content: "Photo by Max Rive",
      //     // You can also provide a URL for the image.
      //     image: require("@/assets/images/slider12.jpg"),
      //     link:
      //       "https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk",
      //   },
      //   {
      //     title: "El Teide Volcano, Spain",
      //     content: "Photo by Max Rive",
      //     // You can also provide a URL for the image.
      //     image: require("@/assets/images/slider13.jpg"),
      //     link:
      //       "https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk",
      //   },
      // ],




      apiURL: "",
      url:axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      //jobs
      featuredJobs:[],
      latestJobs:[],
      topEmployers:null,


      // Brands
      brands:[],
      featuredbrands:[],
      //Events
      events: [],
      //Testimonials
      testimonials : [],

      //Job Filter Model
      jobSearchByNameOrCity:{
        jobTitle: "",
        cityId: null
      },

      //Apply job
      job:{

        jobId: 0,
        employeeId: 0,
        expectedSalary: 0,
        status: 1,
        isApplicantViewed: false
      },
      saveJob:{
        saveJobId: 0,
        jobId: 0,
        employeeId: 0,
        isActive: true

      },

      //Complete Profile Modal
      jobID:0,
      jobRecord:null,
      jobsStatuses:null,
      employeelist:null,
      employeeId:0,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,
      profileStrength:0,


      isSaveJobStatus:false,
      isModalVisible:false,
      isSignInOrSignUpSection:false,
      isEmployeeProfileFormSection:false,
      isShowAlertMsg:false,
      isShowProfileInfoCard:false,
      isApplyJobConfirmationSection:false,
      modalTitle:"",
      showAlertMsg:false,
      modalDescription:"",

      isConfirmationModalVisible:false,
      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,
    };
  },
  //carousel_latest_jobs
  methods: {
    getAllEmployees(){
      this.rerender();
      //this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }



            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


            this.employeeId = this.employeelist.employeeId;
            this.job.expectedSalary = this.employeelist.expectedSalary;

            this.employeeSkill = res.data.eMPSkills;
            console.log("employeeSkill : ", this.employeeSkill);
            this.employeeExperience = res.data.eMPExperiences;



            this.employeeEducation = res.data.empEducations;
            console.log("employeeEducation : ", this.employeeEducation);


            this.CalculatePercentage();
            //this.appliedJobsStatus();

            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },

    showOrHideModal(jobrecord){
      this.jobRecord = jobrecord;
      if(this.$auth.check()){
        if(this.$auth.user().user.role != 'Employer'){
          console.log("chek : ",this.$auth.check());
          //this.$router.push({ name: "JobDetail" });




          if(this.profileStrength > 80){
            console.log("total strength inside if statement after Employee Skill section: ", this.profileStrength)

            this.isModalVisible = true;

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = false;
            this.isShowProfileInfoCard = true;
            this.isApplyJobConfirmationSection = true;
            console.log("job strength inside if statement: ",this.profileStrength)
            console.log("jobRecord inside if statement: ",this.jobRecord)
          }
          else{
            this.$bvToast.toast("Your profile Strength Must be Over 80%, Please Update your Profile", {
              toaster: "b-toaster-top-center",
              variant: "warning",

              solid: true
            });

            this.isModalVisible = true;
            console.log("job strength inside else statement: ",this.profileStrength)

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = true;
            this.isShowProfileInfoCard = false;
            this.isApplyJobConfirmationSection = false;



          }
        }
        else {
          this.isConfirmationModalVisible = true;
        }



      }
      else{
        console.log("chek : ",this.$auth.check());
        this.isModalVisible = true;
        this.isEmployeeProfileFormSection = false;
        //this.modalTitle = "Attention";
        this.isSignInOrSignUpSection=true;
        this.isShowAlertMsg = false;
        this.isShowProfileInfoCard = false;
        this.isApplyJobConfirmationSection = false;
        //this.modalDescription = "Please Register/Login Before Applying a Job"
      }
    },
    onSavingJob(jobrecord){
      this.jobRecord = jobrecord;
      if(this.$auth.check()){
        this.saveJob.jobId = Number(jobrecord.jobId);
        this.saveJob.employeeId = Number(this.employeeId);
        console.log("jobId + Emp ID : ", this.saveJob.jobId + this.saveJob.employeeId)
        axios
            .post("/api/Job/SaveJob", this.saveJob)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info.msg);
            })
            .catch(error => {
              console.log("Errorrrrrr : ",error);
              this.errored = true;
            })
            .finally(() => {


              if(this.info.msg == "Job Already Save"){
                this.$bvToast.toast("You have Saved to the Job Already. ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
                });
              }
              else {
                this.$bvToast.toast("Job Saved Successfully", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
              }
              this.getAll();
              this.getAllEmployees();


            });
      }
      else {
        this.$bvToast.toast("Please Sign Up / Sign In, Before Saving a job", {
          toaster: "b-toaster-top-center",
          variant: "warning",

          solid: true
        });
        setTimeout(this.showSignUpOrSignInModal(),1000)
      }

    },

    showSignUpOrSignInModal(){
      this.isModalVisible = true;
      this.isSaveJobStatus = true;
      this.isEmployeeProfileFormSection = false;

      this.isSignInOrSignUpSection=true;
      this.isShowAlertMsg = false;
      this.isShowProfileInfoCard = false;
      this.isApplyJobConfirmationSection = false;
    },

    /*onApplyingJob(id){

      this.job.jobId = Number(id);
      this.job.employeeId = Number(this.employeeId);

      axios
          .post("/api/Job/ApplyJob", this.job)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info.msg);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {


            if(this.info.msg == "Employee Already Exist"){
              this.$bvToast.toast("You have applied to the Job Already. ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true
              });
            }
            else {
              this.$bvToast.toast("Job Applied Successfully", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });
            }
            this.getAllEmployees();

          });
    },*/
    /*appliedJobsStatus(){
      this.jobsStatuses = null;
      axios.get('/Job/GetAllJobStatus')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /!*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*!/
            this.jobsStatuses = res.data.data;
            console.log("jobsStatuses : ", this.jobsStatuses);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },*/
    toggleModal(){
      this.isModalVisible = !this.isModalVisible;
      this.getAllEmployees();
    },

    closeModal(){
      // $("#addorEditModal").modal("hide");
      this.isConfirmationModalVisible = false;
    },

    recievedStrength(value){
      this.profileStrength = value;
      console.log("On Home Page: ", this.profileStrength);

    },
    CalculatePercentage() {

      //Employee

      let profilePercentUponSignUp = 50;
      let profilePercent = 40;
      // let expPercent = 20;
      // let eduPercent = 20;
      let skillPercent = 10;

      let percent = 0;

      if (this.employeelist != {}) {
        if ((this.employeelist.fullName != "" || this.employeelist.fullName != null) && (this.employeelist.mobileNumber != "" || this.employeelist.mobileNumber != null)) {
          percent += profilePercentUponSignUp;
          console.log("percent in signup : ", percent)
        }

        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);


        // if (this.employeelist.fatherName != "" && this.employeelist.fatherName != null && this.employeelist.cnic != "" && this.employeelist.dateofBirth != null && this.employeelist.gender != 0 && this.employeelist.gender != null) {
        
        if (this.employeelist.gender != 0 && this.employeelist.gender != null) {
          percent += profilePercent;

          console.log("percent in Profile : ", percent)
        }
        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);




      // if (this.employeeExperience.length != 0) {
      //   percent += expPercent;

      // }

      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Experience section : ", this.profileStrength);





      // if (this.employeeEducation.length != 0) {
      //   percent += eduPercent;

      // }

      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Education section : ", this.profileStrength);

      if (this.employeeSkill.length != 0) {
        percent += skillPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Skill section : ", percent);


    },
    getAllJobs(){
      this.list = null;
      this.featuredJobs = [];
      this.latestJobs = [];
      /*let model = {
        TypeJob: 1
      };*/

      axios.get('/api/Job/GetAlljobsOfEmployeer')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            let list = res.data.data;
            this.list = list.slice().reverse();
            console.log("All Jobs : ", this.list);
            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

            for (let job in this.list){
              if(this.list[job].jobType === "FeatureJob" && this.list[job].status === "Approved"  && moment(String(this.list[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                this.featuredJobs.push(this.list[job]);
              }

            }

            console.log("Featured Jobs : ", this.featuredJobs);
            for (let ljob in this.list){
              if(this.list[ljob].status === "Approved" && moment(String(this.list[ljob].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                this.latestJobs.push(this.list[ljob]);
              }
            }
            console.log("latestJobs Jobs : ", this.latestJobs);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },


    getCompanies(){
      this.topEmployers = [];

      axios.get('/api/EmployerCompany/GetCompany')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }







            let list = res.data.data;

            let companies = []

            for (let job in list){
              if(list[job].isFeature === true){
                companies.push(list[job]);
              }

            }
            this.topEmployers = companies.slice().reverse();
            console.log("companies : ", this.topEmployers);

            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },

    //Get Dropdowns List
    getCityList(){
      this.$store.dispatch("getCityList");
     /* this.cities = null;
      let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });*/
    },



    //Job Filter
    setSearchFiter(){

      this.jobSearchByNameOrCity.cityId = (this.selectedCity == 0 || this.selectedCity == null) ? null : Number(this.selectedCity);
        this.getJobsByNameAndId(this.jobSearchByNameOrCity);
      this.$root.$emit('data-of-search', this.jobSearchByNameOrCity)
        this.$router.push(
            { name: 'AllJobs' })

      },
    getJobsByNameAndId(jobFilter){
      console.log("jobFilter : ", jobFilter)
      this.$store.dispatch("getJobsByNameAndCityId", jobFilter)
      //this.$router.push({name: 'AllJobs', params: { jobTitle }})
    },



    showPrevious() {
      this.$refs.carousel.prev();
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPreviousLatestJob() {
      this.$refs.carousel_latest_jobs.prev();
    },
    showNextLatestJob() {
      this.$refs.carousel_latest_jobs.next();
    },
    showPreviousExecutiveJobs() {
      this.$refs.carousel_Executive_Jobs.prev();
    },
    showNextExecutiveJobs() {
      this.$refs.carousel_Executive_Jobs.next();
    },
    showPreviousTopEmployee() {
      this.$refs.carousel_Top_Employee.prev();
    },
    showNextTopEmployee() {
      this.$refs.carousel_Top_Employee.next();
    },
    showPreviousBrandsCar() {
      this.$refs.brands_carousel.prev();
    },
    showNextBrandsCar() {
      this.$refs.brands_carousel.next();
    },
    showPreviousTestimonials() {
      this.$refs.testimonials_carousel.prev();
    },
    showNextTestimonials() {
      this.$refs.testimonials_carousel.next();
    },


    getAdds(){
      this.$store.dispatch("fetchAdds");
    },

    getBrands(){
      this.brands = [];
      this.featuredbrands = [];

      let brandsModel = {
        "pageSize": 0,
        "pageNumber": 1,
        "totalRecord": 0,
        "text": ""
      }

      axios
          .post("/api/Adds/GetBrand", brandsModel)
          .then(res => {
            // code that we will 'try' to run

            let brands = res.data.data;
            //this.brands = brands
            /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
             for(let job in jobList){
                 if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                     adds.push(jobList[job]);
                 }
             }*/


            console.log("Add Response portion");
            for (let data in brands){
              if(brands[data].isFeature === false){
                this.brands.push(brands[data]);
              }

            }

            console.log("brands : ", this.brands);
            for (let data in brands){
              if(brands[data].isFeature === true){
                this.featuredbrands.push(brands[data]);
              }

            }

            console.log("Featured brands : ", this.featuredbrands);

          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {

              /*this.$bvToast.toast("Updated Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });*/


            }
          });
    },
    getEvents(){
      this.events = [];

      let model = {
        "pageSize": 0,
        "pageNumber": 1,
        "totalRecord": 0,
        "text": "",
        "type": "Events"
      }

      axios
          .post("/api/Employee/GetTestimonialandEvents", model)
          .then(res => {
            // code that we will 'try' to run

            let events = res.data.data;
            this.events = events.slice().reverse();
            /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
             for(let job in jobList){
                 if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                     adds.push(jobList[job]);
                 }
             }*/


            console.log("Add Response portion");

            console.log("events : ", this.events);


          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {

              /*this.$bvToast.toast("Updated Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });*/


            }
          });
    },
    getTestimonials(){
      this.testimonials = [];

      let model = {
        "pageSize": 0,
        "pageNumber": 1,
        "totalRecord": 0,
        "text": "",
        "type": "Testimonials"
      }

      axios
          .post("/api/Employee/GetTestimonialandEvents", model)
          .then(res => {
            // code that we will 'try' to run

            let testimonials = res.data.data;
            this.testimonials = testimonials
            /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
             for(let job in jobList){
                 if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                     adds.push(jobList[job]);
                 }
             }*/


            console.log("Add Response portion");

            console.log("testimonials : ", this.testimonials);


          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {

              /*this.$bvToast.toast("Updated Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });*/


            }
          });
    },


    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },
  },

  computed:{
    cities(){
      return this.$store.getters.availableCities
    },
    homeAfterSliderAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableHomeAfterSliderAdd)
      return this.$store.getters.availableHomeAfterSliderAdd
    },
    homeUpSkillVlearnAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableHomeUpSkillVlearnAdd)
      return this.$store.getters.availableHomeUpSkillVlearnAdd
    },
    homeBeforeFooterAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableHomeBeforeFooterAdd)
      return this.$store.getters.availableHomeBeforeFooterAdd
    }
  },
  mounted() {
    this.getAdds();
    this.getAllEmployees();

    this.getAllJobs();

    //this.getCompanies();
    this.getCityList();

    this.getBrands();
    this.getEvents();
    this.getTestimonials();
  }
};
</script>
