<template>
  <div class="m-5">

    <div class="container">
      <!--content area start-->
      <div class="mt-2">
        <!--      <div class="custom-row">
                <router-link :to="{ name: 'Employees' }">
                  <span class="btn custom-back-btn custom-btn-gotopage"
                  ><arrow-left-icon
                      size="1.5x"
                      class="custom-class"
                  ></arrow-left-icon>
                  </span>
                </router-link>

              </div>-->

        <div
            class="custom-row custom-options-menu justify-content-between align-items-center"
        >
          <div class="customHeading">
            <nav>
              <div
                  class="nav nav-tabs custom-nav-tabs"
                  id="nav-tab"
                  role="tablist"
              >
                <a
                    class="nav-item nav-link custom-nav-link custom-nav-heading active"
                    id="employee-detail-tab"
                    data-toggle="tab"
                    href="#employee-detail"
                    role="tab"
                    aria-controls="employee-detail"
                    aria-selected="true"
                >

                  <span v-if="$route.params.typeJob == 'FeatureJob'"> All Featured Jobs </span>
                  <span v-else> All Latest Jobs </span>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="custom-accordion-separator">
        <div class="custom-separator"></div>
      </div>
      <div :key="componentKey">
        <div  class=" row-slider-item" v-for="jt in allJobs" :key="jt.jobId">
          <div class="row no-gutters align-items-center">
            <div class="col-sm-9">
              <router-link
                  class="text-decoration-none"
                  :to="{ name: 'JobDetail',
                               params: { id: jt.jobId }
                             }"
                  title="Job Detail">
                <div class="row no-gutters align-items-center">

                  <div class="col-sm-2">
                    <div class="style-left-img">
                       <span class="b-avatar rounded-circle custom-rounded-avatar">
                                      <span  class="b-avatar-img">
                                        <span v-if="jt.companyLogoAddress != null && jt.companyLogoAddress != ''">
                                           <img :src="url+jt.companyLogoAddress">
                                         </span>
                                         <span v-else>

                                              <img src="../../assets/images/no-image.png" >


                                         </span>
                                        <!--                                  {{ usernamefirstletter }}-->
                                        <!--                        <img src="../assets/images/avatar-2.jpg">-->

                                      </span>

                                    </span>

                    </div>
                  </div>
                  <div class="col-sm-10">

                    <div class="custom-job-title text-dark text-capitalize style-text-14"><u>{{ jt.jobTitle }}</u></div>
                    <div class="style-company custom-muted-text style-text-14">{{ jt.companyName }}</div>
                    <div class="style-job-metas">
                      <div class="custom-row">
                        <span class="mr-2"><i class="fas fa-money-bill"></i></span>
                        <span v-if="jt.packageTo === 0 || jt.packageTo === 1">Market Competitive Salary</span>
    <span v-else>Rs {{ jt.packageFrom }} - {{ jt.packageTo }}/Month</span>
     </div>
                      <div class="location custom-muted-text style-text-14">
                        <span>
                                  <i
                                      class="text-second fa fa-map-marker"
                                      aria-hidden="true"
                                      data-v-3c8a640f=""
                                  ></i>
                                </span>
                        <span>{{ jt.cityName }}, {{ jt.countryName }}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </router-link>
            </div>


            <div class="col-sm-3">
              <div class="mb-3" >
               <span class="flex-grow-1" >

                  <span>
                    <a @click="showOrHideModal(jt)" class="btn btn-primary custom-employer-btn custom-btn-apply-now  mb-2  ">Easy Apply Now</a>
                  </span>
                 <!--                                  <span  v-else>
                                                     <a type="button" class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2 custom-btn-bookmark ">Applied</a>
                                                   </span>-->

              </span>


              </div>

              <div class="">
                <a class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark " @click="onSavingJob(jt.jobId)">Save</a>
              </div>
              <!--                      <div class="row no-gutters">
                                      <div class="col-3">
                                        <div class="style-job-favourite">
                                          <a>
                                            <i class="fas fa-heart fvrt-color"></i>
                                          </a>
                                        </div>
                                      </div>
                                      <div class="col-9">
                                        <div class="style-btns">
                                          <button class="btn btn-info btn-freelance">FREELANCE</button>
                                        </div>

                                        <div>
                                          <button @click="showOrHideModal" class="btn btn-primary btn-apply">APPLY</button>
                                        </div>
                                      </div>
                                    </div>-->
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="w3-card-2 pt-3 pb-3 ml-auto mr-auto" style="width:100%;height: 30%;overflow: auto">
      &lt;!&ndash;        <header class="w3-container custom-row ">
                <h3>Applications</h3>
                <button class="btn btn-outline-secondary ml-auto">View All Applications</button>
              </header>
              <hr>&ndash;&gt;
              <div class="w3-container custom-row">

                <img src="../../assets/images/avatar-2.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                <div>
                  <p class="font-weight-bold text-info m-0">Profile Name</p>
                  <span>Job Name</span>
                </div>


                <div class="ml-auto">
                  <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                  <a href="#">Portfolio</a>
                </div>
              </div>
              <hr>
              <div class="w3-container custom-row">

                <img src="../../assets/images/avatar-2.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                <div>
                  <p class="font-weight-bold text-info m-0">Profile Name</p>
                  <span>Job Name</span>
                </div>


                <div class="ml-auto">
                  <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                  <a href="#">Portfolio</a>
                </div>
              </div>
              <hr>
              <div class="w3-container custom-row">

                <img src="../../assets/images/avatar-2.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                <div>
                  <p class="font-weight-bold text-info m-0">Profile Name</p>
                  <span>Job Name</span>
                </div>


                <div class="ml-auto">
                  <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                  <a href="#">Portfolio</a>
                </div>
              </div>

            </div>-->

      <!--content area end-->
    </div>
    <CPModal v-if="isModalVisible" :signInOrSignUpSection="isSignInOrSignUpSection" :employeeProfileFormSection="isEmployeeProfileFormSection" :show-alert-msg="isShowAlertMsg" :show-profile-info-card="isShowProfileInfoCard" :apply-job-confirmation-section="isApplyJobConfirmationSection" :selected-job-record="jobRecord" @get-strength="recievedStrength"  @toggle-modal="toggleModal">

    </CPModal>
    <ConfirmationModal v-if="isConfirmationModalVisible"  @toggle-modal="closeModal"></ConfirmationModal>
  </div>
</template>
<script>
import axios from "axios";
import CPModal from "../../components/employeeComponents/CompleteProfileModalComponent";
import ConfirmationModal from "../../components/ConfirmationModal";
import moment from "moment";
export default {
  name: "AllFeaturedOrLatestJobs",
  components:{
    CPModal,
    ConfirmationModal
  },
  data(){
    return{
      url:axios.defaults.baseURL,
      list:[],
      allJobs : [],
      latestJobs :[],

      //Apply job
      job:{

        jobId: 0,
        employeeId: 0,
        expectedSalary: 0,
        status: 1,
        isApplicantViewed: false
      },

      saveJob:{
        saveJobId: 0,
        jobId: 0,
        employeeId: 0,
        isActive: true

      },
      isApplyNow_btn:true,

      //Complete Profile Modal
      jobID:0,
      jobRecord:null,
      jobsStatuses:null,
      employeelist:null,
      employeeId:0,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,
      profileStrength:0,



      isModalVisible:false,
      isSignInOrSignUpSection:false,
      isEmployeeProfileFormSection:false,
      isShowAlertMsg:false,
      isShowProfileInfoCard:false,
      isApplyJobConfirmationSection:false,
      modalTitle:"",
      showAlertMsg:false,
      modalDescription:"",

      isConfirmationModalVisible:false,

      componentKey:0,

    };
  },
  methods:{
    getAll() {
  this.rerender();
  console.log("job type: ", this.$route.params.typeJob);
  this.featuredJobs = null;
  
  let model = {
    TypeJob: Number(this.$route.params.typeJob)
  };

  axios.post('/api/Job/GetEmployerAllJobs', model)
    .then(res => {
      if (res.status !== 200) {
        console.log('Failed to fetch jobs');
        throw new Error('Failed to fetch jobs');
      }

      let resdata = res.data.data;
      if (resdata.length !== 0) {
        resdata.forEach(job => {
          if (job.packageTo === 0 || job.packageTo === 1) {
            job.packageFrom = null; 
          }
        });
      }

      this.featuredJobs = resdata;
      console.log("featuredJobs : ", this.featuredJobs);
    })
    .catch(error => {
      console.error("Error fetching jobs: ", error);
      this.errored = true;
    })
    .finally(() => {
      if (this.errored) {
        console.log("Error : Sorry there is no response");
        // this.$bvToast.toast("Sorry there is no response ", {
        //   toaster: "b-toaster-top-center",
        //   variant: "danger",
        //   solid: true,
        // });
      }
    });
},
    getAllJobs() {
  console.log("job type: ", this.$route.params.typeJob);
  this.list = [];
  this.allJobs = [];
  
  axios.get('/api/Job/GetAlljobsOfEmployeer')
    .then(res => {
      if (res.status !== 200) {
        console.log('Failed to fetch jobs');
        throw new Error('Failed to fetch jobs');
      }
      
      let resdata = res.data.data;
      if (resdata.length !== 0) {
        if (resdata[0].packageTo === 0 || resdata[0].packageTo === 1) {
          resdata[0].packageFrom = null; 
        }
        console.log("Updated job data: ", resdata[0]);
        this.jobData = resdata[0];
        this.setCompanyId = this.jobData.companyId;
      }

      let currentDate = moment().format('YYYY-MM-DD');
      
      resdata.forEach(job => {
        if (this.$route.params.typeJob === "FeatureJob") {
          if (job.jobType === "FeatureJob" && job.status === "Approved" && moment(job.jobExpiryDate).format('YYYY-MM-DD') >= currentDate) {
            this.allJobs.push(job);
          }
        } else if (this.$route.params.typeJob === "LatestJob") {
          if (job.status === "Approved" && moment(job.jobExpiryDate).format('YYYY-MM-DD') >= currentDate) {
            this.allJobs.push(job);
          }
        }
      });

      console.log("Filtered Jobs : ", this.allJobs);
    })
    .catch(error => {
      console.error("Error fetching jobs: ", error);
    });
},

    getAllEmployees(){
      //this.rerender();
      //this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }



            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);


            this.employeeId = this.employeelist.employeeId;
            this.job.expectedSalary = this.employeelist.expectedSalary;

            this.employeeSkill = res.data.eMPSkills;
            console.log("employeeSkill : ", this.employeeSkill);
            this.employeeExperience = res.data.eMPExperiences;



            this.employeeEducation = res.data.empEducations;
            console.log("employeeEducation : ", this.employeeEducation);


            this.CalculatePercentage();


            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    showOrHideModal(jobrecord){
      this.jobRecord = jobrecord;
      if(this.$auth.check()){


        if(this.$auth.user().user.role != 'Employer'){
          console.log("chek : ",this.$auth.check());
          //this.$router.push({ name: "JobDetail" });




          if(this.profileStrength > 80){
            console.log("total strength inside if statement after Employee Skill section: ", this.profileStrength)

            this.isModalVisible = true;

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = false;
            this.isShowProfileInfoCard = true;
            this.isApplyJobConfirmationSection = true;
            console.log("job strength inside if statement: ",this.profileStrength)
            console.log("jobRecord inside if statement: ",this.jobRecord)
          }
          else{
            this.$bvToast.toast("Your profile Strength Must be Over 80%, Please Update your Profile", {
              toaster: "b-toaster-top-center",
              variant: "warning",

              solid: true
            });

            this.isModalVisible = true;
            console.log("job strength inside else statement: ",this.profileStrength)

            this.isSignInOrSignUpSection = false;
            this.isEmployeeProfileFormSection = true;
            this.isShowAlertMsg = true;
            this.isShowProfileInfoCard = false;
            this.isApplyJobConfirmationSection = false;



          }
        }
        else {
          this.isConfirmationModalVisible = true;
        }



      }
      else{
        console.log("chek : ",this.$auth.check());
        this.isModalVisible = true;
        this.isEmployeeProfileFormSection = false;
        //this.modalTitle = "Attention";
        this.isSignInOrSignUpSection=true;
        this.isShowAlertMsg = false;
        this.isShowProfileInfoCard = false;
        this.isApplyJobConfirmationSection = false;
        //this.modalDescription = "Please Register/Login Before Applying a Job"
      }
    },

    onSavingJob(id){

      this.saveJob.jobId = Number(id);
      this.saveJob.employeeId = Number(this.employeeId);
      console.log("jobId + Emp ID : ", this.saveJob.jobId + this.saveJob.employeeId)
      axios
          .post("/api/Job/SaveJob", this.saveJob)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info.msg);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {


            if(this.info.msg == "Job Already Save"){
              this.$bvToast.toast("You have Saved to the Job Already. ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true
              });
            }
            else {
              this.$bvToast.toast("Job Saved Successfully", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });
            }
            this.getAll();
            this.getAllEmployees();


          });
    },
    toggleModal(){
      this.isModalVisible = !this.isModalVisible;
      this.getAll();
    },


    closeModal(){
      // $("#addorEditModal").modal("hide");
      this.isConfirmationModalVisible = false;
    },
    recievedStrength(value){
      this.profileStrength = value;
      console.log("On All JOb Page: ", this.profileStrength);

    },
    CalculatePercentage() {

      //Employee

      let profilePercentUponSignUp = 50;
      let profilePercent = 40;
      // let expPercent = 20;
      // let eduPercent = 20;
      let skillPercent = 10;

      let percent = 0;

      if(this.employeelist != {}){
        if((this.employeelist.fullName != "" || this.employeelist.fullName != null) && (this.employeelist.mobileNumber != "" || this.employeelist.mobileNumber != null)){
          percent += profilePercentUponSignUp;
          console.log("percent in signup : ", percent)
        }

        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



        if (this.employeelist.gender != 0 && this.employeelist.gender != null) {
          percent += profilePercent;

          console.log("percent in Profile : ", percent)
        }
        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);



      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);




      // if(this.employeeExperience.length != 0){
      //   percent += expPercent;

      // }

      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Experience section : ", this.profileStrength);





      // if(this.employeeEducation.length != 0){
      //   percent += eduPercent;

      // }

      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Education section : ", this.profileStrength);





      if(this.employeeSkill.length != 0){
        percent += skillPercent;

      }

      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Skill section : ", percent);


    },
    rerender() {
      this.componentKey += 1;
    },
  },
  mounted() {
    this.getAllJobs();
    this.getAllEmployees();
  }
}
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* .custom-nav-tabs { */
  /* border-bottom: 3px solid #19aa4b !important; */
/* } */

.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #EA216C;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>
