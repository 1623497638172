<template>
  <div class="container">
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="cookie-policy-tab"
                  data-toggle="tab"
                  href="#cookie-policy"
                  role="tab"
                  aria-controls="cookie-policy"
                  aria-selected="true"
              >
                <span> Cookie Policy </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="cookie-policy"
            role="tabpanel"
            aria-labelledby="cookie-policy-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body text-justify">



                    Updated at 2021-01-04


                    <h5 class="font-weight-bold">Definitions and key terms</h5>

                    To help explain things as clearly as possible as is Cookie Policy, every time any of these terms are referenced, are strictly defined

                    <ul>
                      <li>
                        <b>Cookie:</b> small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information
                      </li>
                      <li>
                        <b>Company:</b> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road Lahore that is responsible for your information under this Privacy Policy.
                      </li>

                      <li>
                        <b>Device:</b> any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ‘Shemeanswork and use the services.
                      </li>


                      <li>
                        <b>Personal Data:</b> any information that directly, indirectly, or in connection with other information identification number — allows for the identification or identifiability of a natural person.
                      </li>
                      <li>
                        <b>Service:</b> refers to the service provided by Shemeanswork as described in the relative terms (if available) and on this platform.
                      </li>
                      <li>
                        <b>Third-party service:</b> refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.
                      </li>
                      <li>
                        <b>Website:</b> Shemeanswork's site, which can be accessed via this URL:
                        <a class="text-decoration-none w3-hover-text-gray" href="https://www.shemeanswork.com" target="_blank">
                          <!--              <span>Phone: </span> -->
                          <span class="custom-user-contacts">https://www.shemeanswork.com</span>
                        </a>.
                      </li>
                      <li>
                        <b>You:</b> a person or entity that is registered with Shemeanswork to use the Services including a personal.
                      </li>

                    </ul>

                    <h5 class="font-weight-bold">Introduction</h5>
                    This Cookie Policy explains how Shemeanswork and its affiliates (collectively "Shemeanswork’, "we", "us", and “ours"), use cookies and similar technologies to recognize you when you visit our platform, including without limitation http://www.shemeanswork.com and any related URLs, mobile or localized versions and related domains / sub-domains (‘Websites’). It explains what these technologies, are and why we use them, as well as the choices for how to control them.
                    <h5 class="font-weight-bold">What is a cookie?</h5>
                    A cookie is a small text file that is stored on your computer or other internet connected device in order to identify your browser, provide analytics, remember information about you such as your language preference or login information. They're completely safe and can't be used to run programs or deliver viruses to your device.
                    <h5 class="font-weight-bold">Why do we use cookies?</h5>
                    We use first party and/or third party cookies on our platform for various purposes such as:
                    <ul>
                      <li>
                        To facilitate the operation and functionality of our platform;
                      </li>
                      <li>
                        To improve your experience of our platform and make navigating around them quicker and easier,
                      </li>
                      <li>
                        To allow us to make a bespoke user experience for you and for us to understand what is useful or of interest to you:
                      </li>
                      <li>
                        To analyze how our platform is used and how best we can customize it;
                      </li>
                      <li>
                        To identify future prospects and personalize marketing and sales interactions with it;
                      </li>
                      <li>
                        To facilitate the tailoring of online advertising to your interests.
                      </li>
                    </ul>






                    <h5 class="font-weight-bold">What type of cookies does Shemeanswork use?</h5>
                    <p>Cookies can either be session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years. Cookies placed by the website you're visiting are called “first party cookies”</p>
                    <p>Strictly Necessary cookies are necessary for our platform to function and cannot be switched off in our systems. They are essential in order to enable you to navigate around the platform and use its features. If you remove or disable these cookies, we cannot ‘guarantee that you will be able to use our platform.</p>
                    We use the following types of cookies in our platform
                    <h5 class="font-weight-bold">Essential Cookies</h5>
                    <p>We use essential cookies to make our platform work. These cookies are strictly necessary to enable core functionality such as security, network management, your cookie preferences and accessibility. Without them you wouldn't be able to use basic services.</p>
                    <p>You may disable these by changing your browser settings, but this may affect how the Websites function</p>
                    <h5 class="font-weight-bold">Performance and Functionality Cookies</h5>
                    ‘These cookies are used to enhance the performance and functionality of our platform but are non-essential to their use. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the platform as we would not be able to remember that you had logged in previously
                    <h5 class="font-weight-bold">Marketing Cookies</h5>
                    ‘These account-based marketing cookies enable us to identify future prospects and personalize sales and marketing interactions with them.
                    <h5 class="font-weight-bold">Analytics and Customization Cookies</h5>
                    <p>‘These cookies collect information that is used to help us understand how our platform is being used or how effective our marketing campaigns are, or to help us customize our platform for you.</p>
                    <p>We use cookies served by Google Analytics to collect limited data directly from end-user browsers to enable us to better understand your use of our platform. Further information on how Google collects and uses this data can be foundation: https://www.google.com/policies/privacy/partners/You can opt-out of all Google supported analytics on our Websites by visiting: https:/tools.google.com/id/page/gaoptout.</p>
                    <h5 class="font-weight-bold">Advertising Cookies</h5>
                    These cookies collect information over time about your online activity on the platform and other online services to make online advertisements more relevant and effective to you. This is known as interest-based advertising. They also perform functions like preventing the same ad from continuously reappearing and ensuring that ads are properly displayed for advertisers. Without cookies, its really hard for an advertiser to reach its audience, or to know how many ads were shown and how many clicks they received.
                    <h5 class="font-weight-bold">Social Media Cookies</h5>
                    ‘These cookies are used when you share information using a social media sharing button or “like” button on our Site or you link your ‘account or engage with our content on or through a social networking website such as Facebook, Twitter or Google+. The social network will record that you have done this. These cookies may also include certain code that has been placed on the platform to help track conversions from ads, optimize ads based on collected data, build targeted audiences for future ads, and remarket to ‘qualified users who have already taken certain action on the platform.
                    <h5 class="font-weight-bold">Third Party Cookies</h5>
                    ‘Some cookies that have been set on our platform are not set on a first party basis by Shemeanswork. The Websites can be ‘embedded with content from third parties to serve advertising. These third party service providers may set their own cookies on your web browser. Third party service providers control many of the performance and functionality, advertising, marketing and analytics cookies described above. We do not control the use of these third party cookies as cookies can only be accessed by the third party that originally set them.
                    <h5 class="font-weight-bold">How you can manage cookies?</h5>
                    <p>‘Most browsers allow you to control cookies through their settings’ preferences. However, if you limit the ability of websites to set, ‘cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information. Browser manufacturers provide help pages relating to cookie management in their products.</p>
                    <p>Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more information.</p>
                    <ul>
                      <li>Google Chrome</li>
                      <li>Internet Explorer</li>
                      <li>Mozilla Firefox</li>
                      <li>Safari (Desktop)</li>
                      <li>Safari (Mobile)</li>
                      <li>Android Browser</li>
                      <li>Opera</li>
                      <li>Opera Mobile</li>
                    </ul>

                    <h5 class="font-weight-bold">Blocking and disabling cookies and similar technologies</h5>
                    Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block our essential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its features and services. You should also be aware that you may also lose some saved information (e.g. saved login details, site preferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser, you should visit your browser's help menu for more information
                    <h5 class="font-weight-bold">Changes to Our Cookie Policy</h5>
                    We may change our Service and policies, and we may need to make changes to this Cookie Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to this Cookie Policy and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Cookie Policy. If you do not want to agree to this or any updated Cookie Policy, you can delete your account.
                    <h5 class="font-weight-bold">Your Consent</h5>
                    By using our platform, registering an account, or making a purchase, you hereby consent to our Cookie Policy and agree to its terms.


                    <h5 class="font-weight-bold">Contact Us</h5>
                    Don't hesitate to contact us if you have any questions regarding our Cookie Policy.
                    <ul>
                      <li>Via
                        <a class="text-decoration-none w3-hover-text-gray" href="mailto:info@shemeanswork.com">
                          <span><i class="fas fa-envelope fa-md fa-fw mr-2"></i></span>
                          <!--            <span>Email:</span>-->



                          <span class="custom-user-contacts">info@shemeanswork.com</span>
                        </a>
                      </li>
                      <li>Via
                        <a class="text-decoration-none w3-hover-text-gray" href="tel:0092 423 7243810">
                          <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
                          <!--              <span>Phone: </span> -->
                          <span class="custom-user-contacts">0092 423 7243810</span>
                        </a>
                      </li>
                      <li>Via
                        <a class="text-decoration-none w3-hover-text-gray" href="https://www.shemeanswork.com" target="_blank">
                          <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                          <!--              <span>Phone: </span> -->
                          <span class="custom-user-contacts">https://www.shemeanswork.com</span>
                        </a>
                      </li>
                      <li>Via <span><i class="fas fa-map-marker fa-md fa-fw mr-2"></i></span> 23 The Mall Road Lahore</li>
                    </ul>





                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "CookiePolicy",
  components:{

  },
}
</script>