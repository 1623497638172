
import Vue from "vue"
import GAuth from 'vue-google-oauth2'

const gauthOption = {
  clientId: '687431962450-ekkj198uohfda5ai3qugv6gkgjfhn8hu.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}
Vue.use(GAuth, gauthOption)