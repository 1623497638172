<template>
  <div :key="componentKey">
    <form
        id="experienceForm"
        v-if="show"
        class="needs-validation" :class="[!isValidated  ? '':'', isValidated ? 'was-validated':'']" autocomplete="off" novalidate
    >
      <div v-if="isExperienceSectionVisible">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-3 form-group row no-gutters" v-if="jobTitle_input">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="jobTitle_id">
                Job Title
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="jobTitle_id"
                    placeholder="Job Title"
                    v-model="exp.jobTitle"
                    required
                />
                <div class="invalid-feedback">
                  <span class="w3-animate-opacity">Please Specify the Job Title</span>

                </div>
              </div>

            </div>
            <div class="mb-3  form-group row  no-gutters" v-if="company_input">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="company_id">
                Company
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="company_id"

                    v-model="exp.company"

                    placeholder="Company"
                    required
                />
                <div class="invalid-feedback">
                  <span class="w3-animate-opacity">Please specify the Company</span>

                </div>
              </div>

            </div>
            <div class="mb-3 form-group row no-gutters" v-if="industry_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="industry_id">
                Industry
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <select id="industry_id"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedIndustry"
                        required
                >
                  <option value="">Select Industry</option>
                  <option
                      v-for="option in industries"
                      :key="option.key"
                      :value="option.key"
                  >
                    {{ option.value }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="selectedIndustry == ''" class="w3-animate-opacity">Please select the Industry</span>

                </div>
              </div>

            </div>
            <div class="mb-3 form-group row no-gutters" v-if="manageTeam_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="manageTeam_id">
                Did you directly manage a team?
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <select id="manageTeam_id"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedManageTeam"
                        required
                >
                  <option value="">Choose One...</option>
                  <option
                      v-for="option in manageTeams"
                      :key="option.value"
                      :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="selectedManageTeam == ''" class="w3-animate-opacity">Please select from the option above</span>

                </div>
              </div>

            </div>
            <div class="mb-3 form-group row no-gutters" v-if="salary_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="salary_id">
                Salary (PKR)
<!--                <span class="text-danger">*</span>-->
              </label>
              <div class="col-sm-9">
                <select id="salary_id"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedSalary"

                >
                  <option value="">Choose One...</option>
                  <option
                      v-for="option in salaries"
                      :key="option.value"
                      :value="option.value"
                  >
                    {{ option.text }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="selectedSalary == ''" class="w3-animate-opacity">Please choose the salary</span>

                </div>
              </div>

            </div>
            <div class="mb-3 form-group row no-gutters w3-animate-opacity" v-if="location_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="location_id">
                Location
                <span class="text-danger">*</span>
              </label>

              <div class="col-sm-9">
                <select id="location_id"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedLocation"
                        required
                        @change="onCityChangeValue()"
                >
                  <option value="">Choose One...</option>
                  <option
                      v-for="option in cities"
                      :key="option.key"
                      :value="option.key"
                  >
                    {{ option.value }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="selectedLocation == ''" class="w3-animate-opacity">Please select from the location</span>

                </div>
              </div>

            </div>
            <div class="mb-3 form-group text-right">
              <a href="javascript:void(0);" class="mb-0 text-primary" @click="getOtherCountries"><span v-if="location_dd == true">Not in Pakistan?</span><span v-else="location_dd == false">In Pakistan?</span></a>
            </div>
            <div class="mb-3 form-group row no-gutters w3-animate-opacity" v-if="country1_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="country1_id">Country</label>
              <div class="col-sm-9">
                <select id="country1_id"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedCountry1"
                >
                  <option value="">Choose One...</option>
                  <option
                      v-for="option in countries"
                      :key="option.key"
                      :value="option.key"
                  >
                    {{ option.value }}
                  </option>
                </select>
              </div>

            </div>
            <!--    City Input            -->
            <div class="mb-3 form-group row no-gutters w3-animate-opacity" v-if="cityName_input">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="cityName_id">City</label>
              <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="cityName_id"
                    placeholder="City"
                    v-model="exp.cityName"
                    pattern="^[a-zA-Z\s]*$"
                />
                <div class="invalid-feedback">

                  <span v-if="!exp.cityName.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Invalid City Name, Only Characters are allowed Here</span>
                </div>
              </div>

            </div>

            <div class="mb-3" v-if="currentlyWorking_radio">
              <!--                          <label class="" >Currently Working</label>-->
              <div class="custom-row">
                <div class="mr-2">
                  <b-form-checkbox
                      id="currentlyWorking_id"
                      v-model="exp.currentlyWorking"
                      name="currentlyWorking"

                  >
                    Currently Working
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="mb-3  form-group row no-gutters" v-if="startDate_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" >
                Start Date
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <input
                    type="date"
                    id="datepicker2"
                    class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                    autocomplete="off"
                    required
                    v-model="exp.startDateMonthYear"
                />
                <div class="invalid-feedback">
                  <span class="w3-animate-opacity">Please choose the start date.</span>

                </div>

              </div>


            </div>

            <div class="mb-3  form-group row no-gutters" v-if="endDate_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" >
                End Date
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <input
                    type="date"

                    class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                    autocomplete="off"
                    required
                    :disabled="disabledCurrentlyWorking"
                    v-model="exp.endDateMonthYear"

                />
                <div class="invalid-feedback">
                  <span class="w3-animate-opacity">Please choose the end date.</span>

                </div>

              </div>


            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="mb-3" v-if="description1_input">
              <label class="" for="description1_id">Description</label>
              <div class="">
                                  <textarea
                                      type="text"
                                      class="form-control form-control-sm custom-input-gotopage"
                                      id="description1_id"
                                      v-model="exp.experienceDescription"
                                      placeholder="Description"
                                      maxlength="2000"
                                  />
              </div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 form-group row no-gutters" v-if="referenceEmail_input">
              <label class="col-sm-4 col-form-label col-form-label-sm" for="referenceEmail_id">
                Reference Email
<!--                <span class="text-danger">*</span>-->
              </label>
              <div class="col-sm-8">
                <input
                    type="email"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="referenceEmail_id"
                    placeholder="Reference Email"
                    v-model="exp.referenceEmail"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                />
                <div class="invalid-feedback">
                  <span v-if="exp.referenceEmail == ''" class="w3-animate-opacity">Please enter your Email address</span>
                  <span v-if="exp.referenceEmail != '' && !exp.referenceEmail.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)" class="w3-animate-opacity">Please enter the Valid Email Address</span>
                </div>
              </div>

            </div>
          </div>
          <div class= "col-md-6">

            <div class="mb-3 form-group row no-gutters" v-if="referenceNumber_input">
              <label class="col-sm-4 col-form-label col-form-label-sm" for="referenceNumber_id">Reference Phone #</label>
              <div class="col-sm-8">
                <vue-tel-input v-bind="vueTellProps"  v-model="exp.referenceNumber" ></vue-tel-input>
<!--                <input
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="referenceNumber_id"
                    placeholder="00-000-0000000"
                    v-mask="'##-###-#######'"
                    v-model="exp.referenceNumber"
                />-->
              </div>

            </div>


          </div>

        </div>
        <div class="row">
          <div class= "col-md-6">
            <div class="mb-3 form-group row no-gutters" v-if="country2_dd">
              <label class="col-sm-4 col-form-label col-form-label-sm" for="country2_id">Country</label>
              <div class="col-sm-8">
                <select id="country2_id"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedCountry2"
                >
                  <option value="">Choose One...</option>
                  <option
                      v-for="option in countries"
                      :key="option.key"
                      :value="option.key"
                  >
                    {{ option.value }}
                  </option>
                </select>
              </div>

            </div>


          </div>
          <div class="col-md-6"></div>
        </div>
      </div>

      <div v-if="isProjectSectionVisible">
        <div class="custom-row mb-2">
          <div class="flex-grow-1">
            <h4>Project</h4>
          </div>
          <div class="text-right">
            <a v-if="addProject_btn"
               href="javascript:void(0)"

               @click="addProject()"
               title="Add Project"

            >
              <div class="custom-icon icon">
                <plus-icon size="2x" class="custom-class"></plus-icon>

              </div>
            </a>
          </div>
        </div>

        <div class="row" v-for="(project, index) in projects" :key="index">
          <div class="col-md-10">
            <div class="row">
              <div class="col-md-12">


              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3 form-group row no-gutters" v-if="projectTitle_input">
                  <label class="col-sm-3 col-form-label col-form-label-sm" for="projectTitle_id">Project Title</label>
                  <div class="col-sm-9">
                    <input
                        type="text"
                        class="form-control form-control-sm custom-input-gotopage"
                        id="projectTitle_id"
                        placeholder="Project Title"
                        v-model="project.ProjectTitle"
                    />
                  </div>

                </div>



              </div>

            </div>

            <!--                        <div class="row">
                                      <div class="col-md-6">

                                        <div class="mb-3  form-group row no-gutters" v-if="projectStartDate1_dd">
                                          <label class="col-sm-3 col-form-label col-form-label-sm" >Start Date</label>
                                          <div class="col-sm-9">
                                            <b-form-datepicker
                                                id="projectStartDate1_id"
                                                size="sm"

                                                class="custom-input-gotopage custom-datepicker"
                                                placeholder="Choose Start Date"
                                                v-model="project.projectStartDateMonthYear"

                                            ></b-form-datepicker>
                                          </div>


                                        </div>


                                      </div>
                                      <div class="col-md-6">


                                        <div class="mb-3  form-group row no-gutters" v-if="projectEndDate1_dd">
                                          <label class="col-sm-3 col-form-label col-form-label-sm" >End Date</label>
                                          <div class="col-sm-9">
                                            <b-form-datepicker
                                                id="projectEndDate1_id"
                                                size="sm"
                                                :disabled="disabledCurrentlyOngoing"
                                                class="custom-input-gotopage custom-datepicker"
                                                placeholder="Choose End Date"
                                                v-model="project.projectEndDateMonthYear"

                                            ></b-form-datepicker>
                                          </div>


                                        </div>
                                      </div>
                                    </div>-->
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3" v-if="pdescription_input">
                  <label class="" for="pdescription_id">Description</label>
                  <div class="">
                                  <textarea
                                      type="text"
                                      class="form-control form-control-sm custom-input-gotopage"
                                      id="pdescription_id"
                                      v-model="project.ProjectDescription"
                                      placeholder="Description"
                                      maxlength="2000"
                                  />
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-right">
              <a
                  v-if="removeProject_btn"
                  href="javascript:void(0)"

                  @click="removeProject()"
                  title="Remove Project"

              >
                <b-icon font-scale="2" variant="danger" icon="trash"></b-icon
                >

              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">


        <div v-if="showCloseButton">
          <b-button
              id="showCloseButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              type="button"
              size="sm"
              @click="close"
              variant="outline-dark"
              data-dismiss="modal"
          >Close
          </b-button>
        </div>
        <div v-if="showResetButton">
          <b-button
              id="showResetButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              type="reset"
              size="sm"
              variant="outline-dark"
          >Reset
          </b-button>
        </div>
        <div>
          <b-button

              v-if="showSaveButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              @click="AddOrUpdate()"
              size="sm"
              variant="outline-dark"
          >Save
          </b-button>
        </div>
        <!-- <div>
          <b-button
            v-if="showReassignButton"
            class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
            @click="AddOrUpdate()"
            size="sm"
            variant="outline-dark"
            >Reassign
          </b-button>
        </div> -->
      </div>
    </form>
<!--    <CPModal :is-experience-section-visible="isExperienceSection"></CPModal>-->
  </div>
</template>
<script>
import axios from "axios";
import { PlusIcon } from "vue-feather-icons";
import {mask} from 'vue-the-mask'
//import CPModal from "@/components/employeeComponents/CompleteProfileModalComponent";
export default {
  name: 'AddEditExperienceComponent',
  components:{
    PlusIcon,
    // CPModal
  },
  directives: {mask},
  data(){
    return{
      vueTellProps:{
        mode: "international",
        defaultCountry: "PK",





        preferredCountries: ["PK"],
        onlyCountries: [],
        ignoredCountries: [],



        wrapperClasses: "",
        inputClasses: "",

        inputOptions: {
          showDialCode: true,
          name: "telephone",
          autocomplete: "off",
          placeholder: "Enter a phone number",
          required: true,
          type:'tel',
          maxlength:15

        },
        validCharactersOnly: true
      },
      modal:"modal",

      //For Other components
      isExperienceSection:true,
      //For Current Component
      isExperienceSectionVisible:false,
      isProjectSectionVisible:false,
      employeeId:0,
      //bool
      jobTitle_input:true,
      industry_dd:true,
      salary_dd:true,
      startDate_dd:true,
      projectStartDate1_dd:true,
      company_input:true,
      manageTeam_dd:true,
      location_dd:true,
      endDate_dd:true,
      projectEndDate1_dd:true,
      currentlyWorking_radio:true,
      description1_input:true,
      referenceEmail_input:true,
      referenceNumber_input:true,
      projectTitle_input:true,
      position_input:true,
      currentlyOngoing_radio:true,
      pdescription_input:true,

      currentlyWorkingState: false,



      cityName_input:false,
      country1_dd:false,
      country2_dd:true,

      //Form Validation Bool
      isValidated:false,


      //project Section
      addProject_btn:true,
      removeProject_btn:true,
      selectedCountry1:'',
      selectedCountry2:'',
      // countries:null,
      selectedLocation:'',
      // cities:null,


      selectedIndustry:'',
      industries:[],


      cityInput1:"",
      exp:{
        EmployeeId:0,
        empExperienceId:0,
        jobTitle: "",
        company: "",
        industryId: 0,
        teamManage: 0,
        salary: 0,
        cityId: 0,
        cityName:"",
        isPakistan: true,
        startDateMonthYear: null,
        endDateMonthYear: null,
        currentlyWorking: false,
        experienceDescription: "",
        referenceEmail: "",
        referenceNumber: "",
        countryId: "",
        countryId1: "",
        projectTitle: "",
        position: "",
        projectStartDateMonthYear: new Date(),
        projectEndDateMonthYear: new Date(),
        isProjectOngoing: false,
        projectDescription: ""
      },
      employeeExperienceId:0,
      selectedManageTeam:'',
      manageTeams:[

        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],
      selectedSalary : '',
      salaries:[

        { text: '0-5000', value: 1 },
        { text: '5000-15000', value: 2 },
        { text: '15000-25000', value: 3 },
        { text: '25000-35000', value: 4 },
        { text: '35000-50000', value: 5 },
        { text: '50000-70000', value: 6 },
        { text: '70000-100000', value: 7 },
        { text: '100000-125000', value: 8 },
        { text: '125000-150000', value: 9 },
        { text: '150000-175000', value: 10 },
        { text: '175000-200000', value: 11 },
        { text: '200000-250000', value: 12 },
        { text: '300000-400000', value: 13 },
        { text: '400000-500000', value: 14 },
        { text: '500000-600000', value: 15 },
        { text: '600000-700000', value: 16 },
        { text: '700000-800000', value: 17 },
        { text: '800000-900000', value: 18 },
        { text: '900000-1000000', value: 19 },
        { text: '1000000+', value: 20 },
      ],

      projects:[
        {
          ProjectId: 0,
          ProjectTitle: "",
          ProjectDescription: "",
          EmployeeId: 0
        }
      ],
      //Experience Detail End
      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,
      //showReassignButton: false,
      showSaveButton: true,


      employeelist:null,

      employeeExperience:null,




      info:null,
      rowid:null,
      loading: true,
      errored: false,
      componentKey: 0,
    };
  },
  props:{
    title:{
      type: String
    },
    experienceModal:{
      type: Boolean
    },
    emprecord:{
      type: Object
    },
    empprojectrecord:{
      type: Object
    }
  },
  computed: {
    cities(){
      return this.$store.getters.availableCities
    },
    countries(){
      return this.$store.state.countries;
    },
    disabledCurrentlyWorking() {
      return this.exp.currentlyWorking === true;
    },
    disabledCurrentlyOngoing() {
      return this.exp.isProjectOngoing === true;
    },

  },
  methods:{
    close(){
      this.$emit('toggle-modal');
      console.log("closetriggerrd")


    },
    resetPage(){
      this.$emit('reset-page');
      console.log("resettriggerrd")


    },
    navigate(){
      this.$emit('navigate-form');
      console.log("navigate: triggerrd")
    },
    expSectionState(value){
      this.isExperienceSection = value;
      this.$emit('change-state', this.isExperienceSection);
      console.log("state triggered")
    },
    getAll(){
      this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);
            this.employeeId = this.employeelist.employeeId;


          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },

    //Get Dropdowns List
    getCityList(){
      this.$store.dispatch("getCityList");
      /*this.cities = null;
      let model = {
        type: "City"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.cities = res.data.data;

            console.log("Add Response portion");

            console.log("cities : ", this.cities);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });*/
    },
    getCountryList(){
      this.$store.dispatch("getCountryList");
      /*this.countries = null;
      let model = {
        type: "Country"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.countries = res.data.data;

            console.log("Add Response portion");

            console.log("countries : ", this.countries);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });*/
    },
    getIndustryList(){
      this.industries = [];
      let model = {
        type: "Industry"
      };

      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.industries = res.data.data;

            console.log("Add Response portion");

            console.log("industries : ", this.industries);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {






            }
          });
    },

    /*getCountries() {
      this.countries = null;
      axios.get('/GeoNames/GetAllCountries')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            res.data
            /!*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*!/
            this.countries = res.data.data;
            console.log("countries : ", this.countries);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },*/
    /*getCities() {
      this.cities = null;


      axios.get('/GeoNames/GetCountryCities/').then(res => {
        if (res.status != '200') {
          console.log('error.....')
          const error = new Error('Failed to fetch!');
          return error;
        }

        this.cities = res.data.data;
        console.log("cities : ",this.cities)
      }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });



    },*/
    AddOrEditModal(){
      this.rerender();
      if(this.emprecord != null && this.emprecord != ""){
        console.log("Edit exp: ",this.title);
        console.log("Not Null: ",this.emprecord);
        this.EditModalExperience(this.emprecord);

      }
      else if(this.empprojectrecord != null && this.empprojectrecord != ""){
        console.log("Edit project: ",this.title);
        console.log("Not Null: ",this.empprojectrecord);
        this.EditModalProject(this.empprojectrecord);

      }
      else{
        console.log("add exp: ",this.title);
        console.log("Null: ",this.emprecord);
        this.NewModalExperience();

      }
    },
    //Add Modal Experience
    NewModalExperience() {
      //this.modalTitle = "Add New Experience";
      this.showCloseButton = true;
      this.showResetButton = true;
      this.reset();

      this.addProject_btn = true;
      this.removeProject_btn = true;
      this.showSaveButton = true;

      this.isExperienceSectionVisible = true;
      this.isProjectSectionVisible = true;
    },

    addProject(){
      this.projects.push({

        ProjectId: 0,
        ProjectTitle: "",
        ProjectDescription: "",
        EmployeeId: 0
      })
    },
    removeProject(index){
      this.projects.splice(index,1);
    },
    //EDIT MODAL Experience
    EditModalExperience(emprecord) {
      this.loading = true;
      console.log("emprecord : ",emprecord);

      this.showCloseButton = true;
      this.showResetButton = false;
      this.isExperienceSectionVisible = true;
      this.addProject_btn = true;
      this.removeProject_btn = true;
      this.isProjectSectionVisible = true;
      //Bind Model
      this.exp.EmployeeId = emprecord.employeeId;

      this.exp.empExperienceId = emprecord.empExperienceId;
      this.exp.jobTitle = emprecord.jobTitle;
      this.exp.company = emprecord.company;
      this.exp.startDateMonthYear = emprecord.startDateMonthYear;
      this.exp.endDateMonthYear = emprecord.endDateMonthYear;
      this.exp.experienceDescription = emprecord.experienceDescription;
      this.exp.referenceEmail = emprecord.referenceEmail;
      this.exp.referenceNumber = emprecord.referenceNumber;

      this.exp.projectTitle = emprecord.projectTitle;
      this.exp.projectStartDateMonthYear = emprecord.projectStartDateMonthYear;
      this.exp.projectEndDateMonthYear = emprecord.projectEndDateMonthYear;
      this.exp.position = emprecord.position;
      this.exp.projectDescription = emprecord.projectDescription;

      this.exp.cityName = (emprecord.cityName == "" || emprecord.cityName == null )? "" : emprecord.cityName;

      if(this.exp.cityName != "" && this.exp.cityName != null){
        this.getOtherCountries();
      }
      this.selectedCountry1 = (emprecord.countryId == '' || emprecord.countryId == null )? '' : emprecord.countryId
      this.selectedCountry2 = (emprecord.countryId1 == '' || emprecord.countryId1 == null )? '' : emprecord.countryId1

      this.selectedLocation = (emprecord.cityId == '' || emprecord.cityId == null )? null : Number(emprecord.cityId)




      this.selectedIndustry =emprecord.industryId;
      this.selectedSalary = emprecord.salary;
      this.selectedManageTeam = emprecord.teamManage;
      this.exp.currentlyWorking = emprecord.currentlyWorking;
      this.exp.isProjectOngoing = emprecord.isProjectOngoing;




      this.loading = false;


    },
    EditModalProject(empprojectrecord) {
      this.loading = true;
      console.log("empprojectrecord : ",empprojectrecord);

      this.showCloseButton = true;
      this.showResetButton = false;

      this.isExperienceSectionVisible = false;
      this.isProjectSectionVisible = true;
      this.addProject_btn = false;
      this.removeProject_btn = false;

      this.projects[0].ProjectId = empprojectrecord.projectId;
      this.projects[0].ProjectTitle = empprojectrecord.projectTitles;
      this.projects[0].ProjectDescription = empprojectrecord.projectDescription;
      console.log(this.projects[0].ProjectId)
      this.projects[0].EmployeeId = this.employeeId;
     /* //Bind Model
      this.exp.EmployeeId = emprecord.employeeId;
      this.exp.jobTitle = emprecord.jobTitle;
      this.exp.company = emprecord.company;
      this.exp.startDateMonthYear = emprecord.startDateMonthYear;
      this.exp.endDateMonthYear = emprecord.endDateMonthYear;
      this.exp.experienceDescription = emprecord.experienceDescription;
      this.exp.referenceEmail = emprecord.referenceEmail;
      this.exp.referenceNumber = emprecord.referenceNumber;

      this.exp.projectTitle = emprecord.projectTitle;
      this.exp.projectStartDateMonthYear = emprecord.projectStartDateMonthYear;
      this.exp.projectEndDateMonthYear = emprecord.projectEndDateMonthYear;
      this.exp.position = emprecord.position;
      this.exp.projectDescription = emprecord.projectDescription;

      this.selectedCountry2 = emprecord.countryId;

      this.selectedLocation = emprecord.cityId;
      this.selectedIndustry =emprecord.industryId;
      this.selectedSalary = emprecord.salary;
      this.selectedManageTeam = emprecord.teamManage;
      this.exp.currentlyWorking = emprecord.currentlyWorking;
      this.exp.isProjectOngoing = emprecord.isProjectOngoing;
      this.exp.empExperienceId = emprecord.empExperienceId;
*/


      this.loading = false;


    },
    AddOrUpdate() {

      //Experience Section
      if (this.title == "Edit Experience") {
        console.log("EditTitle : " + this.title);
        console.log("add : " + this.exp.EmployeeId);
        this.updateExperience();

      }
      //Project Section
      else if (this.title == "Edit Project") {
        console.log("EditTitle : " + this.title);
        console.log("add : " + this.projects[0].EmployeeId);
        this.updateProject();

      }
      else  {
        console.log("addTitle : " + this.title);
        //console.log("add : " + this.exp.EmployeeId);
       this.addExperience();
        //this.getAll();
      }


    },
    addExperience(){
      if (
          this.selectedIndustry != '' &&
          this.selectedManageTeam != '' &&
          this.exp.jobTitle != "" &&
          this.exp.company != "" &&
          this.exp.startDateMonthYear!= null
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.employeeId)
        this.exp.EmployeeId = Number(this.employeeId);
        this.exp.countryId = (this.selectedCountry1 == '' || this.selectedCountry1 == null )? null : Number(this.selectedCountry1)
        this.exp.countryId1 = (this.selectedCountry2 == '' || this.selectedCountry2 == null )? null : Number(this.selectedCountry2)
        this.exp.cityId = (this.selectedLocation == '' || this.selectedLocation == null )? null : Number(this.selectedLocation)
        this.exp.industryId = (this.selectedIndustry == '' || this.selectedIndustry == null )? null : Number(this.selectedIndustry)
        this.exp.salary = Number(this.selectedSalary);
        this.exp.teamManage = Number(this.selectedManageTeam);
        this.exp.currentlyWorking = Boolean(this.exp.currentlyWorking);
        this.exp.isProjectOngoing = Boolean(this.exp.isProjectOngoing);

        axios
            .post("/api/EMPExperience/InsertEMPExperience", this.exp)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);

              if(this.projects[0].ProjectTitle != "" && this.projects[0].ProjectDescription != ""){
                this.addProjects();
              }
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                //this.getAll();
                this.rerender();
                this.expSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");

              } else {
                //this.getAll();

                this.$bvToast.toast("Experience Inserted Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
                this.rerender();
                this.expSectionState(false);
                this.resetPage();

              }
            });
      } else {
        this.isValidated=true;
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
        this.navigate();

      }
    },
    updateExperience(){
      if (

          this.selectedIndustry != '' &&
          this.selectedManageTeam != '' &&
          this.exp.jobTitle != "" &&
          this.exp.company != "" &&
          this.exp.startDateMonthYear!= null
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.employeeId)
        // this.exp.empExperienceId = Number(this.employeeExperienceId);
        //console.log("empExperienceId : ",this.employeeExperienceId)
        this.exp.EmployeeId = Number(this.employeeId);
        this.exp.countryId = (this.selectedCountry1 == '' || this.selectedCountry1 == null )? null : Number(this.selectedCountry1)
        this.exp.countryId1 = (this.selectedCountry2 == '' || this.selectedCountry2 == null )? null : Number(this.selectedCountry2)
        this.exp.cityId = (this.selectedLocation == '' || this.selectedLocation == null )? null : Number(this.selectedLocation)
        this.exp.industryId = Number(this.selectedIndustry);
        this.exp.salary = Number(this.selectedSalary);
        this.exp.teamManage = Number(this.selectedManageTeam);
        this.exp.currentlyWorking = Boolean(this.exp.currentlyWorking);
        this.exp.isProjectOngoing = Boolean(this.exp.isProjectOngoing);

        axios
            .post("/api/EMPExperience/UpdateEmployeeExperience", this.exp)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
              if(this.projects[0].ProjectTitle != "" && this.projects[0].ProjectDescription != ""){
                this.addProjects();
              }
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                //this.getAll();
                this.rerender();
                this.expSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                //this.getAll();
                this.rerender();
                this.expSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Experience Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });


              }
            });
      } else {
        this.isValidated=true;
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
        this.navigate();
      }
    },
    addProjects(){
      /*if (

          this.projects[0].projectTitle != null &&
          this.projects[0].projectDescription != null
      ) {*/
      this.info = [];
      console.log("emloyeeid in add project : ",this.employeeId)
      console.log("Projects List Before: ", this.projects);
      for (let i in this.projects){
        this.projects[i].EmployeeId = Number(this.employeeId);
        console.log("Projects List inside For loop: ", this.projects[i]);
      }


      axios
          .post("/api/EMPExperience/AddProject", this.projects)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info);
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {
              //this.getAll();
              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            } else {
              //this.getAll();
              this.$bvToast.toast("Project Inserted Successfully ", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });


            }
          });
      /*} else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }*/
    },
    updateProject(){
      if (
          this.projects[0].ProjectTitle != "" &&
          this.projects[0].ProjectDescription != ""
      ) {
        this.info = [];
        console.log("emloyeeid in update project: ",this.employeeId);

        let model={
          projectId: Number(this.projects[0].ProjectId),
          projectTitle: this.projects[0].ProjectTitle,
          projectDescription: this.projects[0].ProjectDescription,
          employeeId: Number(this.employeeId)

        };

        axios
            .post("/api/EMPExperience/UpdateEmployeeProject", model)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                this.rerender();
                this.expSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.rerender();
                this.expSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Project Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });


              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },
    getOtherCountries(){
      if(this.location_dd == true){
        this.location_dd= false;
        this.selectedLocation = '',
        this.country1_dd = true;
        this.cityName_input = true;
      }
      else {
        this.location_dd= true;
        this.country1_dd = false;
        this.cityName_input = false;
        this.selectedCountry1 = '';
        this.exp.cityName = "";
      }

    },
    onCityChangeValue(){

    },
    reset() {


        this.exp.jobTitle= "";
        this.exp.company= "";
        this.exp.industryId= 0;
        this.exp.teamManage= 0;
        this.exp.salary= 0;
        this.exp.cityId= "";
        this.exp.cityName = "";
        this.exp.isPakistan= true;
        this.exp.startDateMonthYear= null;
        this.exp.endDateMonthYear= null;
        this.exp.currentlyWorking= false;
        this.exp.experienceDescription= "";
        this.exp.referenceEmail= "";
        this.exp.referenceNumber= "";
        this.exp.countryId= "";
        this.exp.countryId1= "";
        this.exp.projectTitle= "";
        this.exp.position= "";
        this.exp.projectStartDateMonthYear= new Date();
        this.exp.projectEndDateMonthYear= new Date();
        this.exp.isProjectOngoing= false;
        this.exp.projectDescription= "";

      this.selectedIndustry = ''
      this.selectedSalary = ''
      this.selectedManageTeam = ''
      this.selectedLocation = ''
      this.selectedCountry1 = ''
      this.selectedCountry2 = ''





      this.projects = [{
        ProjectId: 0,
        ProjectTitle: "",
        ProjectDescription: "",
        EmployeeId: 0
      }];

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
      this.$emit('update-Component-Key', this.componentKey);
    },

  },
  mounted() {
    this.getAll();
    this.getCityList();
    this.getCountryList();
    this.getIndustryList();
  /*  this.getCountries();
    this.getCities();*/
    this.AddOrEditModal();
  }
}
</script>
