<template>
  <div class="mt-5 mb-5 ml-auto mr-auto" :key="componentKey">
    <div class="container">
      <div v-if="showCompleteProfileAlert">
        <div class="alert alert-warning" role="alert">
          Please complete your profile first. Click Here to <router-link to="/employer-profile"
            class="custom-text-color text-decoration-none"><i>Update Your Profile.</i></router-link>
        </div>
      </div>
      <div v-if="showDashboard">
        <div class="row no-gutters w-100 mt-2">
          <div class="col-md-2">

            <div class="">


              <div class="card p-0 mb-20 w3-center custom-profile-card h-100">
                <div class="card-body pt-4 pb-4">

                  <div>

                    <div class="custom-profile-image-wrap custom-company-image-wrap">
                      <div v-if="body.companyLogoAddress !== null">
                        <img :src="url + body.companyLogoAddress" alt="" class="rounded-circle custom-profile-image">
                      </div>
                      <div v-else>
                        <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                      </div>
                    </div>
                  </div>
                  <div class=" w3-center custom-profile-detail">
                    <h5 class="font-weight-bold text-capitalize">
                      {{ body.companyName }}
                    </h5>
                  </div>
                </div>
                <div class="card-footer border-0 pl-0 pr-0">
                  <div class="custom-profile-contacts custom-profile-detail">
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray"
                        :href="phoneNumberLink + body.contactNumber">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
                        <span class="">{{ body.contactNumber }}</span>
                      </a>
                    </div>
                    <div class=" ">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="emailLink + body.companyEmail">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span>{{ body.companyEmail }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="custom-row justify-content-center mt-2 ">
                    <a :href="'https://' + body.facebookLink" target="_blank"
                      class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a :href="'https://' + body.googlePlusLink" target="_blank"
                      class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-google"></i>
                    </a>
                    <a :href="'https://' + body.linkedInLink" target="_blank"
                      class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a :href="'https://' + body.twitterLink" target="_blank"
                      class="custom-twitter-icon custom-icon-style custom-social-link">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card p-0 mb-20">
                <div class="card-body text-center" v-if="isPackageDataFetched">
                  <div class="  text-dark">
                    <h5 class="font-weight-bold text-capitalize">
                      Active Package
                    </h5>
                  </div>
                </div>
                <div class="card-footer text-center text-muted" v-if="packages.length > 0">
                  {{ packages[0].jobPostPackages.packageName }}
                  <br>
                  <br>
                  <p v-if="packages[0].unlimitedPosting">Jobs Limit: Unlimited Jobs </p>
                  <p v-else> Jobs Limit: {{ packages[0].jobPostPackages.jobPostingLimit }} </p>
                  <br>
                  <p> Jobs Consumed: {{ packages[0].totalPostedJobs }} </p>
                  <br>
                  Expiry Date: {{ packages[0].employerJobPostPackages.expiryDate | formatDateAndTime }}
                </div>
                <div class="card-footer text-center text-muted" v-else>
                  No Package Found
                </div>
              </div>
              <div class="card p-0">
                <div class="text-center">
                  <img src="../../assets/images/boost business-min.jpg" class="adsclass" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 mt-5">
            <div class="m-4 mt-0">
              <div class="row mb-2">
                <div class="col-lg-3 col-md-6">
                  <div class="card emp-dash-card mb-3 h-100">
                    <div class="card-body p-2 text-center text-dark">
                      <img src="../../assets/images/guideicon.jpeg" />
                      <p class="">
                        Step by step guide to your Dashboard
                      </p>
                    </div>
                    <div class="card-footer bg-white">
                      <a href="#showDetailModal" data-toggle="modal" @click="tutorialGuideModal"
                        title="Tutorial Guide">view more
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card emp-dash-card mb-3 h-100">
                    <div class="card-body p-2 text-center text-dark">
                      <img src="../../assets/images/backtowork2.jpeg" />
                      <p class="">
                        Hire our Super Mums Back to Work (Returners)
                      </p>
                    </div>
                    <div class="card-footer bg-white">
                      <a href="#showDetailModal" data-toggle="modal" @click="mumsBacktoWorkModal"
                        title="Mums Back to Work">view more
                      </a>
                    </div>
                  </div>
               </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card emp-dash-card mb-3 h-100">
                    <div class="card-body p-2 text-center text-dark">
                      <img src="../../assets/images/disable person vector.jpg" />
                      <p class="">
                        Match our differently abled candidates to your job
                      </p>
                    </div>
                    <div class="card-footer bg-white">
                      <a href="#showDetailModal" data-toggle="modal" @click="abledCandidatesModal"
                        title="Abled Candidates">view more
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card emp-dash-card mb-3 h-100 ">
                    <div class="card-body p-2 text-center text-dark">
                      <img src="../../assets/images/graduate icon light.png" />
                      <p class="">Why hire through a paid internship?</p>
                    </div>
                    <div class="card-footer bg-white">
                      <a href="#showDetailModal" data-toggle="modal" @click="freshGraduateModal"
                        title="Fresh Graduate">view more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Spacer-->
            <div class="pt-4"></div>
            <div class="m-4">
              <div class="row mb-4">
                <div class="col">
                  <div class="text-center clear-bottom">
                    <router-link class="btn btn-theme" :to="{ name: 'EmployerPostJob' }">
                      Post A Job</router-link>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{ dashboardData.jobStats.totalJobs }}</span>
                      </h4>


                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">

                      Total Jobs

                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{ dashboardData.jobStats.cVsRecieved }}</span>
                      </h4>




                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                      Received CVs
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{ dashboardData.jobStats.shortlistedCandidates }}</span>
                      </h4>

                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                      Candidates shortlisted
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="card h-100">
                    <div class="card-body pb-0">


                      <h4 class="mb-1 mt-1">
                        <span data-plugin="counterup">{{ dashboardData.jobStats.upcomingInterviews }}</span>
                      </h4>

                    </div>
                    <div class="card-footer bg-white border-0 pt-0 text-muted " style="font-size: 18px">
                      Upcoming interviews
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-3">
                <!-- <div
                  class="w3-card-2 pt-3 pb-3 ml-auto mr-auto"
                  style="width: 100%; height: 30%; overflow: auto"
                >
                  <header class="w3-container custom-row">
                    <h3>Applications</h3>
                    <router-link
                      to="/all-applications"
                      class="btn btn-outline-secondary ml-auto"
                      >View All Applications</router-link
                    >
                  </header>
                  <hr />
                  <div class="w3-container custom-row">
                    <img
                      src="../../assets/images/avatar-2.jpg"
                      alt="Avatar"
                      class="w3-left w3-circle w3-margin-right"
                      style="width: 60px"
                    />
                    <div>
                      <p class="font-weight-bold w3-text-pink m-0">Profile Name</p>
                      <span>Job Name</span>
                    </div>

                    <div class="ml-auto">
                      <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                      <a href="#">Portfolio</a>
                    </div>
                  </div>
                  <hr />
                  <div class="w3-container custom-row">
                    <img
                      src="../../assets/images/avatar-2.jpg"
                      alt="Avatar"
                      class="w3-left w3-circle w3-margin-right"
                      style="width: 60px"
                    />
                    <div>
                      <p class="font-weight-bold w3-text-pink m-0">Profile Name</p>
                      <span>Job Name</span>
                    </div>

                    <div class="ml-auto">
                      <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                      <a href="#">Portfolio</a>
                    </div>
                  </div>
                  <hr />
                  <div class="w3-container custom-row">
                    <img
                      src="../../assets/images/avatar-2.jpg"
                      alt="Avatar"
                      class="w3-left w3-circle w3-margin-right"
                      style="width: 60px"
                    />
                    <div>
                      <p class="font-weight-bold w3-text-pink m-0">Profile Name</p>
                      <span>Job Name</span>
                    </div>

                    <div class="ml-auto">
                      <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                      <a href="#">Portfolio</a>
                    </div>
                  </div>
                </div> -->
              </div>
              <!--ADVERTISEMENT START--->
              <div class="style-advertisement-portion">
                <div class="">
                  <div class="style-category-wrapper-item">
                    <div v-if="employerDashboardCenter.length != 0" class="w-100 text-center">
                      <a :href="employerDashboardCenter[0].url" :title="employerDashboardCenter[0].url" target="_blank"
                        class="navbar-brand mr-0">
                        <img :src="url + employerDashboardCenter[0].image" class="w-100" />
                      </a>
                    </div>
                    <a v-else href="#">
                      <div class="text-center">Advertisement</div>
                    </a>
                  </div>
                </div>
              </div>
              <!--SPACER-->
              <div class="style-spacer"></div>
              <!--SPACER-->
              <div class="style-spacer"></div>
              <div>
                <div class="row no-gutters">
                  <!-- <div class="col-xl-2"></div> -->
                  <div class="col-xl-12">
                    <div class="w3-card-2 w-100 h-100 ">
                      <div class="card-body">
                        <h4 class="card-title mb-4 text-center">
                          CVs recieved on my jobs
                        </h4>
                        <apexchart width="100%" type="bar" :options="lineChartOptions" :series="lineChartSeries">
                        </apexchart>
                      </div>
                    </div>
                    <!--end card-->
                  </div>
                  <div class="col-xl-2"></div>
                </div>
                <div class="style-spacer"></div>
                <div class="row">
                  <div class="col-lg-4 mt-3">
                    <div style="width: 100%;max-height: 800px;overflow: scroll;" class="w3-card-2 w-150 h-100">
                      <div class="card-body">
                        <h4 class="card-title mb-5 text-center">
                          Employees by cities
                        </h4>
                        <table class="table">
                          <thead>
                            <tr>
                              <td>Name</td>
                              <td>Count</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(job, index) in dashboardData.employeesByCity" :key="index">
                              <td><b> {{ job.name }} </b>
                              </td>
                              <td> {{ job.count }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-3">
                    <div style="width: 100%;max-height: 800px;overflow: scroll;" class="w3-card-2 w-150 h-100">
                      <div class="card-body">
                        <h4 class="card-title mb-5 text-center">Universities</h4>
                        <table class="table">
                          <thead>
                            <tr>
                              <td>Name</td>
                              <td>Count</td>


                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(job, index) in dashboardData.employeesByUniversity" :key="index">

                              <td><b> {{ job.name }} </b>
                              </td>
                              <td> {{ job.count }}

                              </td>

                            </tr>

                          </tbody>
                        </table>
                        <!-- <apexchart
                            type="pie"
                            width="100%"
                            :options="pieChartOptions"
                            :series="pieChartSeries"
                        ></apexchart> -->
                        <!--                    <div id="pie_chart" class="apex-charts" dir="ltr" style="margin-bottom:35px;">
                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-3">
                    <div style="width: 100%;max-height: 800px;overflow: scroll;" class="w3-card-2 w-150 h-100">
                      <div class="card-body">
                        <h4 class="card-title mb-5 text-center">
                          Applications by experience
                        </h4>
                        <table class="table">
                          <thead>
                            <tr>
                              <td>Name</td>
                              <td>Count</td>


                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(job, index) in dashboardData.employeesByExperiance" :key="index">

                              <td><b> {{ job.name }} </b>
                              </td>
                              <td> {{ job.count }}

                              </td>

                            </tr>

                          </tbody>
                        </table>
                        <!-- <apexchart
                            type="pie"
                            width="100%"
                            :options="pieChartOptions"
                            :series="pieChartSeries"
                        ></apexchart> -->
                        <!--                    <div id="pie_chart" class="apex-charts" dir="ltr" style="margin-bottom:35px;">
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="">
              <div class="card p-0 mb-20 w3-center h-100 ">
                <div class="card-body pt-4 pb-4">
                  <div>
                    <div class="custom-profile-image-wrap">
                      <div v-if="disEmployeeLogo !== null">
                        <img :src="url + disEmployeeLogo" alt="" class="rounded-circle custom-profile-image">
                      </div>
                      <div v-else>
                        <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                      </div>
                    </div>
                  </div>
                  <div class=" w3-center text-dark">
                    <h5 class="font-weight-bold text-capitalize">
                      {{ body.fullName }}
                    </h5>
                    <div class=" ">
                      <span>{{ body.designation }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div v-if="employerDashboardRightSide.length != 0" class="text-center">
                  <a :href="employerDashboardRightSide[0].url" :title="employerDashboardRightSide[0].url"
                    target="_blank">
                    <img :src="url + employerDashboardRightSide[0].image" class="adsclass" />
                  </a>
                </div> 
                <div v-else>
                  <img src="../../assets/images/no-image.png" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters w-100 mt-4">
          <div class="col-md-2">

          </div>
          <div class="col-md-8">

          </div>
          <div class="col-md-2">

          </div>
        </div>
        <!-- Modal -->
        <div class="modal fade custom-modal" id="showDetailModal" tabindex="-1" role="dialog" aria-labelledby="showDetailModalTitle"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="showDetailModalTitle">{{ modalTitle }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div v-if="isTutorialGuideVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
                <div v-if="isMumsBacktoWorkVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
                <div v-if="isAbledCandidatesVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
                <div v-if="isFreshGraduateVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
                <div v-if="isRemoteVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
                <div v-if="isViiLearnVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
                <div v-if="isShehriDirectoryVisible">
                  <p style="text-align: justify;">{{ modalcontent }}</p>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import { ArrowLeftCircleIcon } from "vue-feather-icons";
import { ArrowRightCircleIcon } from "vue-feather-icons";
import { VueperSlide, VueperSlides } from "vueperslides";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  name: "EmployerDashboard",
  components: {
    VueSlickCarousel,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
  },
  data() {
    return {
      url: axios.defaults.baseURL,
      modalTitle: "",
      modalcontent: "",
      isTutorialGuideVisible: false,
      isMumsBacktoWorkVisible: false,
      isAbledCandidatesVisible: false,
      isFreshGraduateVisible: false,
      isRemoteVisible: false,
      isViiLearnVisible: false,
      isShehriDirectoryVisible: false,
      showDashboard: false,
      showCompleteProfileAlert: false,
      lineChartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: !1,
          },
          theme: {
            palette: 'palette1' // upto palette10
          }
        },
        plotOptions: {
          bar: {
            horizontal: !1,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: !1,
        },
        stroke: {
          show: !0,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#5b73e8", "#f1b44c"],
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (e) {
              return e;
            },
          },
        },
        xaxis: {
          categories: ['job 1', 2, 3, 4, 5, 6, 7, 8, 9]
        }
      },
      lineChartSeries: [
        {
          name: "CVs Received",
          data: [46, 57, 59, 54, 62, 58, 64, 60, 66],
        },
      ],
      pieChartSeries: [44, 55, 13, 43, 22],
      pieChartOptions: {
        chart: {
          type: "pie",
        },
        labels: ["Jul-20", "Aug-20", "Sep-20", "Oct-20", "Nov-20"],
        colors: ["#34c38f", "#5b73e8", "#f1b44c", "#50a5f1", "#f46a6a"],
        legend: {
          show: !0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: !1,
          fontSize: "14px",
          offsetX: 0,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: !1,
              },
            },
          },
        ],
      },
      phoneNumberLink: "tel:",
      emailLink: "mailto:",
      employerList: null,
      disCompanyLogo: "",
      dashboardData: [],
      disEmployeeLogo: null,
      packages: [],
      isPackageDataFetched: false,
      body: {
        employerCompanyId: 0,
        companyEmail: "",
        companyName: "",
        companyLogoAddress: null,
        ceoName: "",
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: "",
        countryId: 106,
        cityId: 0,
        address: "",
        establishedIn: "",
        websiteUrl: "",
        contactNumber: "",
        googleMapIframe: "",
        linkedInLink: "",
        facebookLink: "",
        googlePlusLink: "",
        twitterLink: "",
        employeeImage: "",
        fullName: "",
        email: "",
        mobileNumber: null,
        designation: "",
      },
      allJobsList: null,
      componentKey: 0,
    };
  },
  computed: {
    employerDashboardLeftSide() {
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardLeftSide)
      return this.$store.getters.availableEmployerDashboardLeftSide
    },
    employerDashboardRightSide() {
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardRightSide)
      return this.$store.getters.availableEmployerDashboardRightSide
    },
    employerDashboardCenter() {
      console.log("topbarAdd : ", this.$store.getters.availableEmployerDashboardCenter)
      return this.$store.getters.availableEmployerDashboardCenter
    }
  },
  methods: {
    getAdds() {
      this.$store.dispatch("fetchAdds");
    },
    getAll() {
      this.rerender();
      this.employerList = null;
      axios.get('/api/EmployerCompany/GetEmployerCompany')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }
          let respData = res.data.data;
          console.log("res.data : ", respData);
          if (respData != 0) {
            this.employerList = respData;
            this.body.employerCompanyId = this.employerList.employerCompanyId
            this.body.companyEmail = this.employerList.companyEmail
            this.body.companyName = this.employerList.companyName
            this.body.ceoName = this.employerList.ceoName
            this.body.address = this.employerList.address
            this.body.contactNumber = this.employerList.contactNumber
            this.body.linkedInLink = this.employerList.linkedInLink;
            this.body.facebookLink = this.employerList.facebookLink;
            this.body.twitterLink = this.employerList.twitterLink;
            this.body.googlePlusLink = this.employerList.googlePlusLink;
            this.body.companyLogoAddress = this.employerList.companyLogoAddress;
            this.body.fullName = this.employerList.employee.fullName
            this.body.designation = this.employerList.employee.designation;
            this.body.mobileNumber = this.employerList.employee.mobileNumber;
            this.body.email = this.employerList.employee.email;
            this.disEmployeeLogo = this.employerList.employee.employeeImage;
            this.allJobsList = this.employerList.jobs;
            console.log("allJobsList : ", this.allJobsList);
            this.showDashboard = true;
          }
          else {
            this.showCompleteProfileAlert = true;
            this.showDashboard = false;
            console.log("No Data Found..")
          }
          // });
        }).catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    getGraphData() {
      this.rerender();
      //this.employerList= null;
      axios.get('/api/EmployerCompany/DashboardGraphs')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }
          let respData = res.data.data;
          this.dashboardData = respData;
          this.dashboardData.cVsRecievedOnJobs = this.dashboardData.cVsRecievedOnJobs.filter(item => item.count !== 0);
          this.lineChartSeries = [
            {
              name: "CVs Received",
              data: this.dashboardData.cVsRecievedOnJobs.map(({ count }) => count),
            },
          ];
          this.lineChartOptions = {
            xaxis: {
              categories: this.dashboardData.cVsRecievedOnJobs.map(({ job }) => job)
            }
          }
          console.log("res.data graph: ", respData);
          console.log("this.lineChartSeries.data: ", this.lineChartSeries);
          //console.log("res graph: ",  JSON.parse(res));
          if (respData != 0) {
          }
          else {
          }
          // });
        }).catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    getPackagesInfo() {
      axios.get('/api/EmployerJobPostPackage/GetAllJobPostPackagesByEmployeeId')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }
          this.packages = res.data.data;
          console.log("PACKAGES : ", this.packages.length);
          this.isPackageDataFetched = true
        }).catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    //Modals
    tutorialGuideModal() {
      this.modalTitle = "Tutorial Guide";
      this.modalcontent = "Tutorial Guide"
      this.isTutorialGuideVisible = true;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    mumsBacktoWorkModal() {
      this.modalTitle = "Mums Back to Work";
      this.modalcontent = "The world comprises males and females which means both genders contribute their fair share of responsibilities. There is a huge percentage of females that are career-driven and goal-oriented in their life. Most of the time these females study their whole life and gain experience with a well-defined skill set that helps any company to bloom. However, marriage and becoming a mother is an essential part of life therefore they might get a break. A female went through a lot of experiences as a mother. However most of the companies don’t consider hiring them considering it a female drawback. This is just a mere mindset that needs to be changed and altered. Being a mother should not be considered as a drawback rather it is an advantage to the company. According to the Hive report of Hive, women tend to work harder than men. They were more creative and were able to drive better ideas. A mother back to work especially is more determined than anyone. Mothers are divine humans who give time and effort in raising children. Therefore, when a mother wants to come back to work they should be more than welcomed rather than discouraged. They have more potential than any working employee and are able to induce quality work for the company.Companies need to change their mindset and update their policies so that they can create an easy and friendly environment for mothers as well. A working mother can have skills just like any other employee and they can execute best practices with quality. However, there are some obligations that they need to fulfill. Mothers have gone through such experiences that no human ever has therefore you can expect a good set of creative abilities from them."
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = true;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    abledCandidatesModal() {
      this.modalTitle = "Abled Candidates";
      this.modalcontent = "Diversity is the best way for innovation. Having a diverse team will bring out the best in everyone through a dynamic synergy. People with disabilities have the stereotype of lacking something but from a different perspective, they are the ones having unprecedented abilities. Most employers fail to recognize that a handicapped person is just as capable as any other employee in the workforce. Talent comes from within and therefore should not be rejected. There are many rights formed for the person with a disability but are not implemented in the companies right now.The provision of most of these companies needs to be upgraded in order to align with the needs of the handicapped. Having a disability is not a sin and they can generate ideas, and execute them efficiently. Different types of disability can limit their job position but it cannot eliminate them. The top-notch industry players in the market need to consider the problems of handicapped persons in applying for a job and alter the positions according to their needs. This will not only make the company stand out from others but will bring out-of-the-box ideas for the company as well. The disability of a person should not become the merit to judge their skills and a completely different criterion is to be introduced for them."
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = true;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    freshGraduateModal() {
      this.modalTitle = "Fresh Graduate";
      this.modalcontent = "The first professional experience of a student is very important for their career development. Fresh graduate jobs or internships for students is the best way to ignite the professional journey ahead. It is in human nature to expect something good after giving their best performance. A salary can be a reward for fresh graduates and motivate them to do their best. A paid internship is the best way to train young minds and help them to achieve exceptional results. A student needs a platform to practically implement the strategies they have learned throughout their lives.Paid internships are an integral part of a good company. It allows young minds to explore and induce their expertise with a fresh perspective. It is good for a company to hire interns as the workload can be divided easily and the young minds get to learn new things as well. The interns are enthusiastic learners as well as a brilliant support team. Internship is not only great for a company but also for the experienced staff as well. Paid internships are brilliant opportunities for the students as well as the companies to form a synergy that suits both of them."
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = true;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    remoteModal() {
      this.modalTitle = "Remote & Flexible Internship Program";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = true;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = false;
    },
    viiLearnModal() {
      this.modalTitle = "Vii Learn";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = true;
      this.isShehriDirectoryVisible = false;
    },
    shehriDirectoryModal() {
      this.modalTitle = "Shehri Directory";
      this.isTutorialGuideVisible = false;
      this.isMumsBacktoWorkVisible = false;
      this.isAbledCandidatesVisible = false;
      this.isFreshGraduateVisible = false;
      this.isRemoteVisible = false;
      this.isViiLearnVisible = false;
      this.isShehriDirectoryVisible = true;
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },
  },
  mounted() {
    this.getAdds();
    this.getAll();
    this.getPackagesInfo();
    this.getGraphData();
  },
};
</script>
<style scoped>
.adsclass{
  width: 100%;
}
.custom-modal .modal-dialog {
  max-width: 60%; 
}
@media screen and (max-width: 720px) {
  .adsclass{
  width: 200px;
}
.custom-modal .modal-dialog {
  max-width: 90%; 
}
}
</style>