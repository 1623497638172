<template>
  <div :key="componentKey">
    <form
        v-if="show"
        class="needs-validation" :class="[!isValidated  ? '':'', isValidated ? 'was-validated':'']" autocomplete="off" novalidate
    >
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="degreeLevel_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="degreeLevel_id">
              Degree Level
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <select id="degreeLevel_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedDegreeLevel"
                      @change="showOrHideUniversityDD(selectedDegreeLevel)"
                      required
              >
                <option value="">Select Degree Level</option>
                <option
                    v-for="option in degreeLevels"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
              <div class="invalid-feedback">
                <span v-if="selectedDegreeLevel == ''" class="w3-animate-opacity">Please select the degree level</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="degreeTitle_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="degreeTitle_id">
              Degree Title
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="degreeTitle_id"
                  placeholder="Degree Title"
                  v-model="edu.degreeTitle"
                  required
                  pattern="^[a-zA-Z\s]*$"
              />
              <div class="invalid-feedback">
                <span v-if="edu.degreeTitle == ''" class="w3-animate-opacity">Please enter the degree title</span>
                <span v-if="!edu.degreeTitle.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Invalid degree title, Only Characters are allowed Here</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="majorField_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="majorField_id">
              Major Field
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="majorField_id"
                  placeholder="Major Field"
                  v-model="edu.majorFieldId"
                  required
                  pattern="^[a-zA-Z\s]*$"
              />
              <div class="invalid-feedback">
                <span v-if="edu.majorFieldId == ''" class="w3-animate-opacity">Please enter the Major Field</span>
                <span v-if="!edu.majorFieldId.match(/^[a-zA-Z\s]*$/)" class="w3-animate-opacity">Only Characters are allowed Here</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
              <div class="mb-3 form-group row no-gutters" v-if="isNotInPakVisible">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="institute_id">
              Institute
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  placeholder="Institute"
                  v-model="edu.notInPakistan"
                  required
              />
              <div class="invalid-feedback">
                <span  class="w3-animate-opacity">Please Enter your institute</span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="mb-3 form-group row no-gutters" v-if="institute_input">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="institute_id">
                Institute
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control form-control-sm custom-input-gotopage"
                    id="institute_id"
                    placeholder="Institute"
                    v-model="edu.institute"
                    required
                />
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please Enter your institute</span>
                </div>
              </div>
            </div>
            <div class="mb-3 form-group row no-gutters" v-if="universities_dd">
              <label class="col-sm-3 col-form-label col-form-label-sm" for="universities_1d">
                University
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <select id="universities_1d"
                        class="custom-select-option form-control form-control-sm"
                        v-model="selectedUniversity"
                        required
                >
                  <option value="">Select University</option>
                  <option
                      v-for="option in universities"
                      :key="option.key"
                      :value="option.key"
                  >
                    {{ option.value }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  <span v-if="selectedUniversity == ''" class="w3-animate-opacity">Please select your institute</span>
                </div>
              </div>
            </div>
          </div>
              <div class="mb-3 form-group text-right">
                <a href="javascript:void(0);" class="mb-0 text-primary" @click="showOrHideInput"><span v-if="!isNotInPakVisible">Not In Pakistan?</span><span v-else>In Pakistan?</span></a>
              </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <h4>Are You Student or Graduated?</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group class="border-0">
            <b-form-radio-group
                v-model="selectedStudentOrGraduate"
                :options="studentOrGraduate"
                @change="showExpectedOrCompletionDate"
                name="radio-inline1"
                required
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <div class="mb-3  form-group row no-gutters" v-if="expectedDate_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" >
              Expected Graduation Date
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="date"
                  class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                  autocomplete="off"
                  required
                  v-model="edu.expectedGraduationYear"
              />
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Please select the Expected date of graduation.</span>
              </div>
            </div>
          </div>
          <div class="mb-3  form-group row no-gutters" v-if="completionDate_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" >
              Graduation Completion Date
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="date"
                  class="form-control form-control-sm custom-input-gotopage custom-datepicker"
                  autocomplete="off"
                  required
                  v-model="edu.graduationCompletionYear"
              />
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Please select the Graduation date.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <b-form-group class="border-0">
            <b-form-radio-group
                v-model="selectedResultType"
                :options="resultTypes"
                @change="showResultInput"
                name="radio-inline2"
                required
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="cgpa_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="cgpa_id">
              CGPA
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="number"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="cgpa_id"
                  placeholder="CGPA"
                  v-model="edu.cgpaPercentage"
                  required
              />
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Only numbers are allowed no characters are allowed.</span>
              </div>
            </div>
          </div>
          <div class="mb-3 form-group row no-gutters" v-if="percentage_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="percentage_id">
              Percentage
              <span class="text-danger">*</span>
            </label>
            <div class="col-sm-9">
              <input
                  type="number"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="percentage_id"
                  placeholder="Percentage"
                  v-model="edu.cgpaPercentage"
                  required
              />
              <div class="invalid-feedback">
                <span class="w3-animate-opacity">Only numbers are allowed no characters are allowed.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="country3_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="country_id">Country</label>
            <div class="col-sm-9">
              <select id="country_id"
                      class="custom-select-option form-control form-control-sm"
                      v-model="selectedCountry3"
              >
                <option value="">Choose Country</option>
                <option
                    v-for="option in countries"
                    :key="option.key"
                    :value="option.key"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3" v-if="description2_input">
            <label class="" for="description2_id">Description</label>
            <div class="">
                                <textarea
                                    type="text"
                                    class="form-control form-control-sm custom-input-gotopage"
                                    id="description2_id"
                                    v-model="edu.educationDescription"
                                    placeholder="Description"
                                    maxlength="2000"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3 form-group row no-gutters" v-if="academicProjectTitle_input">
            <label class="col-sm-3 col-form-label col-form-label-sm" for="academicProjectTitle_id">Academic Project Title</label>
            <div class="col-sm-9">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="academicProjectTitle_id"
                  placeholder="Academic Project Title"
                  v-model="edu.academicProjectDetail"
              />
            </div>
          </div>
          <div class="mb-3  form-group row no-gutters" v-if="projectStartDate2_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" >Project Start Date</label>
            <div class="col-sm-9">
              <b-form-datepicker
                  id="projectStartDate2_id"
                  size="sm"
                  class="custom-input-gotopage custom-datepicker"
                  placeholder="Choose Start Date"
                  v-model="edu.startDate"
              ></b-form-datepicker>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3  form-group row no-gutters" v-if="projectEndDate2_dd">
            <label class="col-sm-3 col-form-label col-form-label-sm" >Project End Date</label>
            <div class="col-sm-9">
              <b-form-datepicker
                  id="projectEndDate2_id"
                  size="sm"
                  class="custom-input-gotopage custom-datepicker"
                  placeholder="Choose End Date"
                  v-model="edu.endDate"
              ></b-form-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3" v-if="projectDetail_input">
            <label class="" for="projectDetail_id">Project Detail</label>
            <div class="">
                                <textarea
                                    type="text"
                                    class="form-control form-control-sm custom-input-gotopage"
                                    id="projectDetail_id"
                                    v-model="edu.projectDetails"
                                    placeholder="Project Detail"
                                    maxlength="2000"
                                />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-3 form-group row no-gutters" v-if="softSkill_input">
            <label class="col-sm-4 col-form-label col-form-label-sm" for="softSkill_id">Soft Skill</label>
            <div class="col-sm-8">
              <input
                  type="text"
                  class="form-control form-control-sm custom-input-gotopage"
                  id="softSkill_id"
                  placeholder="Soft Skill"
                  v-model="edu.softSkills"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div v-if="showCloseButton">
          <b-button
              id="showCloseButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              type="button"
              size="sm"
              @click="close"
              variant="outline-dark"
              data-dismiss="modal"
          >Close
          </b-button>
        </div>
        <div v-if="showResetButton">
          <b-button
              id="showResetButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              type="reset"
              size="sm"
              variant="outline-dark"
          >Reset
          </b-button>
        </div>
        <div>
          <b-button
              v-if="showSaveButton"
              class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
              @click="AddOrUpdate()"
              size="sm"
              variant="outline-dark"
          >Save
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { PlusIcon } from "vue-feather-icons";
export default {
  name: 'AddEditEducationComponent',
  components:{
    PlusIcon,
  },
  data(){
    return{
      modal:"modal",
      employeeId:0,
      isEducationSection:false,
      degreeLevel_dd:true,
      majorField_input:true,
      degreeTitle_input:true,
      institute_input:true,
      isNotInPakVisible:false,
      universities_dd:false,
      expectedDate_dd:false,
      completionDate_dd:false,
      country3_dd:true,
      cgpa_input:false,
      percentage_input:false,
      city_dd:false,
      city2_input:false,
      description2_input:true,
      academicProjectTitle_input:true,
      projectStartDate2_dd:false,
      projectEndDate2_dd:false,
      projectDetail_input:true,
      softSkill_input:false,


      //Form Validation Bool
      isValidated:false,


      //Education Object
      edu:{
        empEducationId:0,
        employeeId:0,
        degreeLevelId: 0,

        degreeTitle: "",
        majorFieldId: "",
        institute: "",
        expectedGraduationYear: new Date(),
        graduationCompletionYear: new Date(),
        studentType:0,
        resultType: 0,
        cgpaPercentage: 0,
        countryGNId: 0,
        educationDescription: "",
        academicProjectDetail: "",
        startDate: new Date(),
        endDate: new Date(),
        projectDetails: "",
        softSkills: "",
        notInPakistan:"",
        universityId: 0
      },
      selectedState:0,
      selectedCountry3:'',
      selectedDegreeLevel:'',
      degreeLevels:null,
      selectedUniversity:'',
      universities:null,
      selectedMajorField:0,
      majorFields:null,
      selectedStudentOrGraduate:1,
      studentOrGraduate:[
        { text: 'Student', value: 1},
        { text: 'Graduate', value: 2},
      ],
      selectedResultType:1,
      resultTypes:[
        { text: 'CGPA', value: 1},
        { text: 'Percentage', value: 2},
      ],
      cityInput2:"",
      //Education Detail End
      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,
      //showReassignButton: false,
      showSaveButton: true,
      employeelist:null,
      employeeExperience:null,

      info:null,
      rowid:null,
      loading: true,
      errored: false,
      componentKey: 0,
    };
  },
  props:{
    title:{
      type: String
    },
    educationModal:{
      type: Boolean
    },
    emprecord:{
      type: Object
    }
  },
  computed: {
    cities(){
      return this.$store.getters.availableCities
    },
    countries(){
      return this.$store.state.countries;
    },
    disabledCurrentlyWorking() {
      return this.exp.currentlyWorking === true;
    },
    disabledCurrentlyOngoing() {
      return this.exp.isProjectOngoing === true;
    },
  },
  methods:{
    close(){
      this.$emit('toggle-modal');
      console.log("closetriggerrd")
    },
    resetPage(){
      this.$emit('reset-page');
      console.log("resettriggerrd")
    },
    navigate(){
      this.$emit('navigate-form');
      console.log("navigate: triggerrd")
    },
    eduSectionState(value){
      this.isExperienceSection = value;
      this.$emit('change-state', this.isExperienceSection);
      console.log("state triggered")
    },
    getAll(){
      this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }
            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);
            this.employeeId = this.employeelist.employeeId;
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
            }
          });
    },

//Get Dropdowns List
    getCityList(){
      this.$store.dispatch("getCityList");
    },
    getCountryList(){
      this.$store.dispatch("getCountryList");
    },
    getDegreeLevelList(){
      this.degreeLevels = null;
      let model = {
        type: "DegreeLevel"
      };
      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.degreeLevels = res.data.data;
            console.log("Add Response portion");
            console.log("degreeLevels : ", this.degreeLevels);
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {
              console.log("Error : Sorry there is no response");
            } else {
            }
          });
    },
    getUniversitiesList(){
      this.universities = null;
      let model = {
        type: "Universities"
      };
      axios
          .post("/api/KeyValuePair/GetDropDownListByIds", model)
          .then(res => {
            // code that we will 'try' to run
            this.universities = res.data.data;
          })
          .catch(error => {
            console.log("Errorrrrrr : ",error);
            this.errored = true;
          })
          .finally(() => {
            if (this.errored) {
            } else {
            }
          });
    },
    showOrHideUniversityDD(dlevel){
      if (dlevel >= 83){
        this.institute_input = false;
        this.universities_dd = true;
      }
      else{
        this.institute_input = true;
        this.universities_dd = false;
      }
    },
    showOrHideInput(){
      this.isNotInPakVisible = !this.isNotInPakVisible;
    },
    showExpectedOrCompletionDate(checked){
      console.log("event : ",checked);
      if(checked == 1){
        this.expectedDate_dd = true;
        this.completionDate_dd = false;
      }
      else if(checked == 2){
        this.expectedDate_dd = false;
        this.completionDate_dd = true;
      }
      else{
        this.expectedDate_dd = false;
        this.completionDate_dd = false;
      }
    },
    showResultInput(checked){
      console.log("event : ",checked);
      if(checked == 1){
        this.cgpa_input = true;
        this.percentage_input = false;
      }
      else if(checked == 2){
        this.cgpa_input = false;
        this.percentage_input = true;
      }
      else{
        this.cgpa_input = false;
        this.percentage_input = false;
      }
    },
    AddOrEditModal(){
      this.rerender();
      if(this.emprecord != null && this.emprecord != ""){
        console.log("Not Null: ",this.emprecord);
        this.EditModalEducation(this.emprecord);
      }
      else{
        console.log("Null: ",this.emprecord);
        this.NewModalEducation();
      }
    },
    //Add Modal Education
    NewModalEducation() {
      this.showCloseButton = true;
      this.showResetButton = true;
      this.reset();
      this.showExpectedOrCompletionDate(this.selectedStudentOrGraduate);
      this.showResultInput(this.selectedResultType);
      this.showSaveButton = true;
    },

    //EDIT MODAL Education
    EditModalEducation(emprecord) {
      this.loading = true;
      console.log("emprecord : ",emprecord);
      this.showCloseButton = true;
      this.showResetButton = false;
      //Bind Model
      this.edu.empEducationId=emprecord.empEducationId;
      this.edu.employeeId = emprecord.employeeId;
      this.selectedCountry3= emprecord.countryGNId;
      // this.selectedState = emprecord.stateGNId;
      this.selectedDegreeLevel = emprecord.degreeLevelId;
      this.showOrHideUniversityDD(this.selectedDegreeLevel);
      this.selectedUniversity = emprecord.universityId;
      this.edu.majorFieldId = emprecord.majorFieldId;
      if(emprecord.studentType != 0 && emprecord.studentType != null && emprecord.studentType != ""){
        if(emprecord.studentType == "Graduate"){
          this.selectedStudentOrGraduate = 2;
          this.showExpectedOrCompletionDate(this.selectedStudentOrGraduate);
        }
        else {
          this.selectedStudentOrGraduate = 1;
          this.showExpectedOrCompletionDate(this.selectedStudentOrGraduate);
        }
      }
      else {
        this.selectedStudentOrGraduate = 1;
        this.showExpectedOrCompletionDate(this.selectedStudentOrGraduate);
      }
      if(emprecord.resultType != 0 && emprecord.resultType != null && emprecord.resultType != ""){
        this.selectedResultType = emprecord.resultType;
        this.showResultInput(this.selectedResultType);
      }
      else {
        this.selectedResultType = 1;
        this.showResultInput(this.selectedResultType);
      }
      this.edu.cgpaPercentage = emprecord.cgpaPercentage;
      this.edu.degreeTitle = emprecord.degreeTitle;
      this.edu.institute = (emprecord.institute == "" || emprecord.institute == null )? null : emprecord.institute;
      this.edu.expectedGraduationYear = emprecord.expectedGraduationYear;
      this.edu.graduationCompletionYear = emprecord.graduationCompletionYear;
      this.edu.educationDescription = emprecord.educationDescription;
      this.edu.academicProjectDetail = emprecord.academicProjectDetail;
      this.edu.startDate = emprecord.startDate;
      this.edu.endDate = emprecord.endDate;
      this.edu.projectDetails = emprecord.projectDetails;
      this.edu.softSkills = emprecord.softSkills;
      this.edu.notInPakistan = emprecord.notInPakistan;
      this.loading = false;
    },
    AddOrUpdate() {
      //Experience Section
      if (this.title == "Edit Education") {
        console.log("EditTitle : " + this.title);
        this.updateEducation();
      }
      else  {
        console.log("addTitle : " + this.title);
        this.addEducation();
      }
    },
    addEducation(){
      if (
          this.edu.majorFieldId!=  "" &&
          this.edu.cgpaPercentage!=  0 &&
          this.selectedStudentOrGraduate!= 0 &&
          this.selectedResultType !=  0 &&
          this.selectedDegreeLevel !=  ''
      ) {
        this.info = [];
        console.log("emloyeeid : ",this.employeeId)
        this.edu.employeeId = Number(this.employeeId);
        this.edu.countryGNId = (this.selectedCountry3 == '' || this.selectedCountry3 == null )? null : Number(this.selectedCountry3);
        // this.edu.stateGNId = Number(this.selectedState);
        this.edu.degreeLevelId = (this.selectedDegreeLevel == '' || this.selectedDegreeLevel == null )? null : Number(this.selectedDegreeLevel);
        this.edu.universityId = (this.selectedUniversity == '' || this.selectedUniversity == null )? null : Number(this.selectedUniversity)
        this.edu.studentType = Number(this.selectedStudentOrGraduate);
        this.edu.resultType = Number(this.selectedResultType);
        this.edu.cgpaPercentage = Number(this.edu.cgpaPercentage);
        axios
            .post("/api/EMPEducation/AddEducations", this.edu)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;
              console.log("Add Response portion");
              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                this.rerender();
                this.eduSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
              } else {
                this.rerender();
                this.eduSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Education Inserted Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  solid: true
                });
              }
            });
      } else {
        this.isValidated=true;
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
        this.navigate();
      }
    },
    updateEducation(){
      if (
          this.edu.majorFieldId !=  "" &&
          this.edu.cgpaPercentage !=  0 &&
          this.selectedStudentOrGraduate != 0 &&
          this.selectedResultType !=  0 &&
          this.selectedDegreeLevel !=  ''
      ) {
        this.info = [];
        this.edu.countryGNId = (this.selectedCountry3 == '' || this.selectedCountry3 == null )? null : Number(this.selectedCountry3);
        this.edu.degreeLevelId = (this.selectedDegreeLevel == '' || this.selectedDegreeLevel == null )? null : Number(this.selectedDegreeLevel);
        this.edu.universityId = (this.selectedUniversity == '' || this.selectedUniversity == null )? null : Number(this.selectedUniversity)
        this.edu.studentType = Number(this.selectedStudentOrGraduate);
        this.edu.resultType = Number(this.selectedResultType);
        this.edu.cgpaPercentage = Number(this.edu.cgpaPercentage);
        axios
            .post("/api/EMPEducation/UpdateEmployeeEducation", this.edu)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;
              console.log("Add Response portion");
              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {
                this.rerender();
                this.eduSectionState(false);
                this.resetPage();
                console.log("Error : Sorry there is no response");
              } else {
                this.rerender();
                this.eduSectionState(false);
                this.resetPage();
                this.$bvToast.toast("Education Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  solid: true
                });
              }
            });
      } else {
        this.isValidated=true;
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
        this.navigate();
      }
    },
    reset() {
        this.edu.degreeLevelId= 0;
        this.edu.degreeTitle= "";
        this.edu.majorFieldId= "";
        this.edu.institute= "";
        this.edu.expectedGraduationYear= new Date();
        this.edu.graduationCompletionYear= new Date();
        this.edu.resultType= 0;
        this.edu.cgpaPercentage= 0;
        this.edu.countryGNId= 0;
        this.edu.educationDescription= "";
        this.edu.academicProjectDetail= "";
        this.edu.startDate= new Date();
        this.edu.endDate= new Date();
        this.edu.projectDetails= "";
        this.edu.softSkills= "";
        this.edu.notInPakistan="";
        this.selectedStudentOrGraduate=1;
        this.selectedResultType = 1;
        this.selectedDegreeLevel = '';
        this.selectedUniversity = '';
        this.selectedCountry3 = '';
     // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
      this.$emit('update-Component-Key', this.componentKey);
    },
  },
  mounted() {
    this.getAll();
    this.getCountryList();
    this.getCityList();
    this.getDegreeLevelList();
    this.getUniversitiesList();
    this.AddOrEditModal();
  }
}
</script>
