<template>
  <div class="container">
    <!--content area start-->
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="employee-detail-tab"
                  data-toggle="tab"
                  href="#specialNeedPerson-detail"
                  role="tab"
                  aria-controls="specialNeedPerson-detail"
                  aria-selected="true"
              >
                <span> Special need Person </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="specialNeedPerson-detail"
            role="tabpanel"
            aria-labelledby="specialNeedPerson-detail-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">

                  <form @reset="onResetModal" v-if="show" autocomplete="off">

<!--                    <div class=" mb-3">
                      <h5 class="card-title">Career Pause</h5>
                    </div>-->

                    <div class="row mb-3 mt-4">


                      <div class="col-md-12">
                        <div class="mb-3 " v-if="disabilityInformation_input" >
                          <label class="" for="disabilityInformation_id">Disability Information</label>
                          <div class="">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="disabilityInformation_id"
                                placeholder="Disability Information"
                                v-model="disabilityInformation"
                                required
                            />
                          </div>

                        </div>



                      </div>
                      <div class="col-md-12">
                        <div class="mb-3 " v-if="disabilityType_dd">
                          <label class="" for="disabilityType_id">Disability Type</label>
                          <div class="">
                            <select id="disabilityType_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedDisabilityType"
                            >
                              <option value="-1">Select Type</option>
                              <option
                                  v-for="(option, index) in disabilityType"
                                  :key="index"
                                  :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>

                        </div>

                      </div>

                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="disabilityCertificate_radio">
                          <label class="" >Disability Certificate</label>
                          <div class="">
                            <b-form-group class="border-0" >
                              <b-form-radio-group
                                  v-model="selectedDisabilityCertificate"
                                  :options="disabilityCertificateList"

                                  name="radio-inline"
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="disabilitySeverity_radio">
                          <label class="" >Disability Severity</label>
                          <div class="custom-row">
                            <div class="mr-2">
                              <b-form-checkbox
                                  id="checkbox-1"
                                  v-model="selectedDisabilitySeverityPhysical"
                                  name="checkbox-1"
                                  value=1
                                  unchecked-value=0
                              >
                                Physical
                              </b-form-checkbox>
                            </div>

                            <div class="mr-2">
                              <b-form-checkbox
                                  id="checkbox-2"
                                  v-model="selectedDisabilitySeverityHearingSpeech"
                                  name="checkbox-1"
                                  value=1
                                  unchecked-value=0
                              >
                                Hearing & Speech
                              </b-form-checkbox>
                            </div>

                            <div class="mr-2">
                              <b-form-checkbox
                                  id="checkbox-3"
                                  v-model="selectedDisabilitySeverityVisual"
                                  name="checkbox-1"
                                  value=1
                                  unchecked-value=0
                              >
                                Visual
                              </b-form-checkbox>
                            </div>

                            <div class="mr-2">
                              <b-form-checkbox
                                  id="checkbox-4"
                                  v-model="selectedDisabilitySeverityIntellectual"
                                  name="checkbox-1"
                                  value=1
                                  unchecked-value= 0
                              >
                                Intellectual
                              </b-form-checkbox>
                            </div>


                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="signLanguage_radio">
                          <label class="" >Do You Know The Sign Language?</label>
                          <div class="">
                            <b-form-group class="border-0" >
                              <b-form-radio-group
                                  v-model="selectedSignLanguage"
                                  :options="signLanguageList"

                                  name="radio-inline2"
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="row mb-3 mt-4">


                      <div class="col-md-12">

                        <div class="mb-3 " v-if="causeOfDisability_dd">
                          <label class="" for="causeOfDisability_id">Cause Of Disability</label>
                          <div class="">
                            <select id="causeOfDisability_id"
                                    class="custom-select-option form-control form-control-sm"
                                    v-model="selectedCauseOfDisability"
                            >
                              <option value="-1">Select Cause Of Disability</option>
                              <option
                                  v-for="(option, index) in causesOfDisability"
                                  :key="index"
                                  :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>

                        </div>


                      </div>
                      <div class="col-md-12">
                        <div class="mb-3 " v-if="specialAssistance_input" >
                          <label class="" for="specialAssistance_id">What Special Assistence is required at Workplace?</label>
                          <div class="">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="specialAssistance_id"
                                placeholder="Type Here"
                                v-model="specialAssistance"
                                required
                            />
                          </div>

                        </div>


                      </div>
                      <div class="col-md-12">
                        <div class="mb-3 " v-if="jobType_input" >
                          <label class="" for="jobType_id">What Kind of Job You are looking for?</label>
                          <div class="">
                            <input
                                type="text"
                                class="form-control form-control-sm custom-input-gotopage"
                                id="jobType_id"
                                placeholder="Type Here"
                                v-model="jobType"
                                required
                            />
                          </div>

                        </div>


                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="mb-3" v-if="fillForm_radio">
                          <label class="" >How Did You Fill your form?</label>
                          <div class="">
                            <b-form-group class="border-0" >
                              <b-form-radio-group
                                  v-model="selectedFillForm"
                                  :options="fillFormList"

                                  name="radio-inline3"
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>

                        </div>
                      </div>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--content area end-->

  </div>
</template>
<script>
export default {
  name:"SpecialNeededPerson",
  data(){
    return{
      apiURL: "",
      currentPage: 1,
      total: 1,

      //bool
      disabilityInformation_input:true,
      disabilityType_dd:true,
      disabilityCertificate_radio:true,
      disabilitySeverity_radio:true,
      signLanguage_radio:true,
      causeOfDisability_dd:true,
      specialAssistance_input:true,
      jobType_input:true,
      fillForm_radio:true,

      disabilityInformation:"",
      selectedDisabilityType:"-1",
      disabilityType:null,
      selectedDisabilitySeverityPhysical:0,
      selectedDisabilitySeverityHearingSpeech:0,
      selectedDisabilitySeverityVisual:0,
      selectedDisabilitySeverityIntellectual:0,
      selectedCauseOfDisability:"-1",
      causesOfDisability:null,
      selectedDisabilityCertificate: 1,
      disabilityCertificateList: [
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],
      selectedSignLanguage:1,
      signLanguageList:[
        { text: 'Yes', value: 1 },
        { text: 'No', value: 2 }
      ],
      specialAssistance:"",
      jobType:"",

      selectedFillForm:1,
      fillFormList:[
        { text: 'My Own', value: 1 },
        { text: 'With Other Help', value: 2 }
      ],

      months:[
        { value: "-1", text:"Select Month" },
        { value: "1", text:"1" },
        { value: "2", text:"2" },
        { value: "3", text:"3" },
        { value: "4", text:"4" },
        { value: "5", text:"5" },
        { value: "6", text:"6" },
        { value: "7", text:"7" },
        { value: "8", text:"8" },
        { value: "9", text:"9" },
        { value: "10", text:"10" },
        { value: "11", text:"11" },
        { value: "12", text:"12" }

      ],
      selectedMiles:"-1",
      miles:[
        {value : "1", text: "0-5"},
        {value : "2", text: "5-10"},
        {value : "3", text: "10-20"},
        {value : "4", text: "20-30"},
        {value : "5", text: "30-40"},
        {value : "6", text: "40-50"},
        {value : "7", text: "50-60"},
        {value : "8", text: "60-70"},
        {value : "9", text: "70-80"},
        {value : "10", text: "80-90"},
        {value : "11", text: "90-100"},
        {value : "12", text: "More than 100"}
      ],
      selectedTravelPercent:"-1",
      travels:[
        {value : "0", text: "not at all"},
        {value : "1", text: "5"},
        {value : "2", text: "10"},
        {value : "3", text: "20"},
        {value : "4", text: "30"},
        {value : "5", text: "40"},
        {value : "6", text: "50"},
        {value : "7", text: "60"},
        {value : "8", text: "70"},
        {value : "9", text: "80"},
        {value : "10", text: "90"},
        {value : "11", text: "100"}

      ],
      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,






    };

  },
  methods:{

    onResetModal(){
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  mounted() {

  }


}
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-nav-tabs {
  /* border-bottom: 3px solid #19aa4b !important; */
}

.custom-nav-tabs .custom-nav-link:hover {
  color: #242424 !important;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000 !important;
  background-color: #fff;
  border-color: #EA216C;
  opacity: 1;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>