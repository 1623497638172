<template>
  <div class="w-100 p-0 m-0" :key="componentKey">
    <div class="custom-modal w3-animate-opacity cpModal" >
      <div class="modal-dialog" :class="[!$auth.check() ? '':'', $auth.check() ? 'modal-lg':'']" role="document">
        <div class="modal-content">
          <div class="custom-row pt-2 pl-3 pr-3">
            <h5 class="modal-title flex-grow-1" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" @click="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
                  <span class="mr-4 ml-2">
                          <b-icon
                              icon="exclamation-triangle"
                              variant="danger"
                              scale="2"
                          ></b-icon>
                  </span>
                  <span> Are you sure you want to delete this {{ title }}? </span>

          </div>
          <div class="modal-footer">
            <div>
              <b-button
                  class="form-control form-control-sm custom-form-btn custom-close-btn"
                  type="button"
                  size="sm"
                  variant="secondary"
                  @click="close"

              >
                Close
              </b-button>
            </div>
            <div>
              <b-button
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  @click="deleteRecord"
                  type="button"
                  size="sm"
                  variant="outline-dark"
                  data-dismiss="modal"
              >
                Delete
              </b-button>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</template>
<script>

import axios from "axios";

export default {

  name:"DeleteComponent",
  components:{

  },
  data() {
    return {
      info:null,
      componentKey:0,

      url:"",

    };
  },

  props:{
    title:{
      type: String
    },

    expRecord:{
      type: Object
    },
    proRecord:{
      type: Object
    },
    eduRecord:{
      type: Object
    },
    skillRecord:{
      type: Object
    },


  },

  methods:{
    close(){
      this.$emit('toggle-modal');

    },
    resetPage(){
      this.$emit('reset-page');
      console.log("resettriggerrd")


    },
    //Delete
    getRecord(){
      this.rerender();
      if(this.expRecord != null && this.expRecord != ""){
        this.rowid = this.expRecord.empExperienceId;
        console.log("exp delete id : "+this.rowid);
        this.url = "/api/EMPExperience/DeleteEMPExperience?Id="+this.rowid;
        console.log("exp url : "+this.url);
      }
      else if(this.proRecord != null && this.proRecord != ""){
        this.rowid = this.proRecord.projectId;
        console.log("pro delete id : "+this.rowid);
        this.url = "/api/EMPExperience/DeleteEMPProject?Id="+this.rowid;
        console.log("pro url : "+this.url);
      }
      else if(this.eduRecord != null && this.eduRecord != ""){
        this.rowid = this.eduRecord.empEducationId;
        console.log("edu delete id : "+this.rowid);
        this.url = "/api/EMPEducation/DeleteEMPEducation?Id="+this.rowid;
        console.log("edu url : "+this.url);
      }
      else if(this.skillRecord != null && this.skillRecord != ""){
        this.rowid = this.skillRecord.empSkillId;
        console.log("skill delete id : "+this.rowid);
        this.url = "/api/EMPSkill/DeleteEMPSkill?Id="+this.rowid;
        console.log("skill url : "+this.url);
      }
      console.log("final delete id : "+this.rowid);
      // let model = {
      //       "userId": this.rowid,


      //       };





    },
    deleteRecord(){
      console.log("exp url : "+this.url);
      console.log("pro url : "+this.url);
      console.log("edu url : "+this.url);
      console.log("skill url : "+this.url);

      axios
         .get(this.url)
         .then((response) => {
           this.info = response.data.data;
           console.log("one", this.info);

         })
         .catch(error => {
           console.log(error);
           this.errored = true;
         })
         .finally(() => {
           if (this.errored) {
             this.resetPage();
             console.log("Error : Sorry there is no response");
             // this.$bvToast.toast("Sorry there is no response ", {
             //   toaster: "b-toaster-top-center",
             //   variant: "danger",

             //   solid: true,
             // });
           } else {
             this.resetPage();
             this.$bvToast.toast("Deleted Successfully ", {
               toaster: "b-toaster-top-center",
               variant: "success",

               solid: true
             });


           }
         });
    },

    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },

  },
  mounted() {
    this.getRecord();
  }
}
</script>
<style scoped>
.cpModal{
  padding: 0 !important;
}
.custom-modal{
  background:rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1072;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}


</style>