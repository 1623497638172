<template>
  <header class="custom-mainmenu">
    <div class="custom-topbar">
      <div class="custom-topbar-wrap">
        <nav class="custom-navbar-topbar navbar navbar-expand-lg navbar-light">
          <button
            id="btn-open-sidebar"
            class="custom-user-button navbar-toggler custom-sidebar-toggler"
            type="button"
            @click="w3_open()"
          >
            <!-- <b-icon
              class="custom-sidebar-toggler-icon w3-animate-opacity"
              icon="list"
            ></b-icon> -->
            <!-- <img src="../assets/images/MainMenuLogos/bars-solid.svg" height="30px" width="30px"> -->
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
          </button>
          <button
            id="btn-close-sidebar"
            class="custom-user-button navbar-toggler custom-sidebar-toggler"
            type="button"
            @click="w3_close()"
          >
            <b-icon
              class="custom-sidebar-toggler-icon w3-animate-left"
              icon="x"
            ></b-icon>
          </button>
          <div class="custom-contact-info">
            <a
              class="text-decoration-none w3-hover-text-light-gray"
              href="tel:0092 423 7243810"
            >
              <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
              <!--              <span>Phone: </span> -->
              <span class="custom-user-contacts custom-contact-info"
                >0092 423 7243810</span
              >
            </a>
          </div>
          <div class="custom-border-right"></div>
          <div class="custom-contact-info">
            <a
              class="text-decoration-none w3-hover-text-light-gray"
              href="mailto:info@shemeanswork.com"
            >
              <span><i class="fas fa-envelope fa-md fa-fw mr-2"></i></span>
              <!--            <span>Email:</span>-->

              <span class="custom-user-contacts custom-contact-info"
                >info@shemeanswork.com</span
              >
            </a>
          </div>

          <!--           <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#topbarSupportedContent"
            aria-controls="topbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>-->

          <div
            class="custom-navbar-collapse navbar-collapse mr-3"
            id="topbarSupportedContent"
          >
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.facebook.com/Shemeanswork.offical"
                  target="_blank"
                >
                  <i class="fab fa-facebook-square fa-md fa-fw"></i>
                  <span class="sr-only">(current)</span></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://twitter.com/shemeanswork1"
                  target="_blank"
                >
                  <i class="fab fa-twitter fa-md fa-fw"></i>
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.linkedin.com/company/shemeanswork/"
                  target="_blank"
                >
                  <i class="fab fa-linkedin fa-md fa-fw"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw"
                  target="_blank"
                >
                  <i class="fab fa-youtube fa-md fa-fw"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.instagram.com/shemeanswork.official/"
                  target="_blank"
                >
                  <i class="fab fa-instagram fa-md fa-fw"></i>
                </a>
              </li>
            </ul>
          </div>
          <!--     Vertical Border     -->
          <div v-if="!$auth.check()" class="custom-border-right"></div>
          <div v-if="!$auth.check()" class="row no-gutters align-items-center">
            <!-- <div>
              <a
                class="custom-collapse-dropdown custom-nav-link nav-link py-0 dropdown-toggle"
                href="#"
                id="navbar-menu-dropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <b-icon
                  class="custom-ellipsis-icon"
                  icon="three-dots-vertical"
                ></b-icon>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right w3-animate-right custom-menu-dropdown"
                aria-labelledby="navbar-menu-dropdown"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link custom-nav-link py-0" href="#">
                      <i class="fab fa-skype fa-md fa-fw"></i>
                      <span class="sr-only">(current)</span></a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link custom-nav-link py-0" href="#">
                      <i class="fa fa-calendar-check fa-md fa-fw"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link custom-nav-link py-0" href="#">
                      <i class="fas fa-sign-out-alt fa-md fa-fw"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link custom-nav-link py-0" href="#">
                      <i class="fas fa-cog fa-md fa-fw"></i>
                    </a>
                  </li>
                </ul>

              </div>
            </div> -->
            <div class="custom-user-button">
              <router-link
                :to="{ name: 'SignUp',params: { id: 1 } }"
                class="nav-link custom-nav-link py-0"
              >
                <i class="fas fa-user fa-md fa-fw"></i>
                <span class="custom-user-contacts custom-contact-info"
                  >SIGN UP</span
                >
              </router-link>

              <!--              <a class="nav-link custom-nav-link py-0" href="javascript:void(0)"
                 @click="signUp">
                <i class="fas fa-user fa-md fa-fw"></i>
                SIGN UP
              </a>-->
            </div>
          </div>
          <!--     Vertical Border     -->
          <div v-if="!$auth.check()" class="custom-border-right"></div>
          <div v-if="!$auth.check()" class="row no-gutters align-items-center">
            <div class="custom-user-button">
              <router-link
                :to="{ name: 'SignIn' }"
                class="nav-link custom-nav-link py-0"
                id="signinicon"
              >sign in
                <i class="fas fa-sign-in-alt fa-md fa-fw"></i>
                <span class="custom-user-contacts custom-contact-info"
                  ></span
                >
              </router-link>
            </div>
          </div>
          <!--     Vertical Border     -->
          <div v-if="$auth.check()" class="custom-border-right"></div>
          <div v-if="$auth.check()" class="row no-gutters align-items-center">
            <div class="ml-4">
              <a
                class="
                  custom-collapse-dropdown custom-nav-link
                  nav-link
                  py-0
                  dropdown-toggle
                  custom-avatar-icon
                "
                href="#"
                id="navbar-user-dropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <!--                            <user-icon
                      size="1.3x"
                      stroke-width="1.3"
                      class="custom-class"
                  ></user-icon>-->

                <span class="b-avatar badge-info rounded-circle">
                  <span
                    v-if="$auth.user().user.role == 'Employer'"
                    class="b-avatar-img"
                  >
                    <span v-if="$auth.user().employeeImage != null">
                      <img :src="url + $auth.user().employeeImage" />
                    </span>
                    <span v-else>
                      <img src="../assets/images/no-image.png" />
                    </span>
                    <!--                                  {{ usernamefirstletter }}-->
                    <!--                        <img src="../assets/images/avatar-2.jpg">-->
                  </span>
                  <span v-else class="b-avatar-img">
                    <!--                                  {{ usernamefirstletter }}-->
                    <span v-if="$auth.user().employeeImage != null">
                      <img :src="url + $auth.user().employeeImage" />
                    </span>
                    <span v-else>
                      <img src="../assets/images/no-image.png" />
                    </span>
                  </span>
                </span>

                <span
                  class="text-capitalize custom-user-name custom-contact-info"
                  >{{ $auth.user().user.fullName }}</span
                >

                <!-- <b-icon
                    class="custom-ellipsis-icon"
                    icon="person-circle"
                  ></b-icon> -->
              </a>
              <div
                class="dropdown-menu dropdown-menu-right custom-user-dropdown"
                aria-labelledby="navbar-user-dropdown"
              >
                <!--                            <div class="custom-row dropdown-item custom-dropdown-item">-->
                <!--                              <div>-->
                <!--                                <span class="b-avatar rounded-circle custom-avatar">-->
                <!--                                  <span class="b-avatar-text custom-avatar-text">-->
                <!--                                    <span>{{ usernamefirstletter }}</span>-->
                <!--                                  </span>-->
                <!--                                </span>-->
                <!--                              </div>-->
                <!--                              <div class="ml-3">-->
                <!--                                <span class="font-weight-bold text-capitalize">{{-->
                <!--                                    userName-->
                <!--                                  }}</span-->
                <!--                                ><br />-->
                <!--                                <span>{{ email }}</span>-->
                <!--                              </div>-->
                <!--                            </div>-->
                <!--                            <div class="dropdown-divider"></div>-->
                <a
                  class="custom-row dropdown-item custom-dropdown-item"
                  href="javascript:void(0)"
                  @click="navigateToLogin"
                >
                  <div>
                    <b-icon
                      class="custom-ellipsis-icon"
                      icon="box-arrow-right"
                    ></b-icon>
                  </div>
                  <div class="ml-3">Logout</div>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
// var sidebar = document.getElementById("mySidebar");
import { UserIcon } from 'vue-feather-icons'
import axios from "axios";
export default {
  name: "Topbar",
  components:{
    UserIcon
  },
  data(){
    return{
      status:0,

      // bool
      loggedOut:true,
      loggedIn:false,
     //userName: this.$auth.user().fullName,
       //email: this.$auth.user().email,
      //phoneNo:this.$auth.user().phoneNumber,
      //mailtoEmail:"mailto:"+this.$auth.user().email,

      url:axios.defaults.baseURL,

      jobSearchByNameOrCity:{
        jobTitle: "",
        cityId: null
      },
      employeelist:null,
      emp:{
        FullName:"",
        FatherName:"",
        CNIC:"",
        DateofBirth:new Date(),
        Gender:0,
        MaritalStatus:0,
        Email:"",
        Address:"",
        Address1:"",
        Area:"",
        CityId:0,
        EmployeeImage:"",
        Resume:"",
        FileName:"",
        CountryId:0,
        code:0,
        mobileNumber:"",
        PhoneNumber:"",
        Domicile:"",
        Languages:"",
        Sector:"",
        YearOfExperience:0,
        ExperienceLevel:"",
        CurrentSalary:0,
        ExpectedSalary:0,
        JobTypId:0,
        ProfessionalSummary:"",
        LinkedInLink:"",
        FacebookLink:"",
        GooglePlusLink:"",
        TwitterLink:"",
        MomReturn:0,
        SpecialNeedyPerson:0,
        Graduate:0,
        //Graduate
        AvailableDate: new Date(),
        GraduateWorkPlacementTypeId: 0,
        ServiceDescription: "",
        SkillsCategoryId: 0,
        SkillsSubCategoryId: 0,


        //Special Needy
        DisabilityInformationTitle: "",
        DisabilityTypeId: 0,
        DisabilityCertificate: 0,
        DisabilitySeverityPhysical: 0,
        DisabilitySeverityHearingSpeech: 0,
        DisabilitySeverityVisual: 0,
        DisabilitySeverityIntellectual: 0,
        IsKnowSignLangauge: 0,
        CauseOfDiabilityTypeId: 0,
        SpecialAssistanceRequirment: "",
        KindofJob: "",
        FillBy:0,

        //Mom Return
        CurrentEmployementStatus: 0,
        CPStartMonth: 0,
        CPStartYear: 0,
        CPEndMonth:0,
        CPEndYear: 0,
        CPDescription: "",
        WeeklyWorkingHour: 0,
        WorkLocation: 0,
        TravelMiles: 0,
        TravelTimePercent: 0,


      },

    };

  },
  computed:{
    topBarAdd(){
      // console.log("topbarAdd : ", this.$store.getters.availableTopBarAdds)
      return this.$store.getters.availableTopBarAdds
    }
  },
  mounted(){
    this.getAdds();
    //this.getAll();


  },



  methods: {
    getAdds(){
      this.$store.dispatch("fetchAdds");
    },
    getAll(){
      //this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            this.employeelist = res.data.employee;
            // console.log("employeelist : ", this.employeelist);




            this.emp.FullName = this.employeelist.fullName;


            this.emp.mobileNumber = this.employeelist.mobileNumber;
            this.emp.Email = this.employeelist.email;

            this.emp.EmployeeImage = this.employeelist.employeeImage;


            console.log("img : ", this.url+this.emp.EmployeeImage);




            this.emp.Gender = this.employeelist.gender;








          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },

    setSearchString(){
      // console.log("type", this.$route.name)

      if(this.$route.name == "AllJobs"){
        /*this.$router.push(
            { name: 'AllJobs', params: { searchString: this.jobTitle }
            })*/
        this.getJobsByName(this.jobSearchByNameOrCity);
      }
      else {

        this.$router.push(
            { name: 'AllJobs' })
        this.getJobsByName(this.jobSearchByNameOrCity);


      }

    },
    getJobsByName(jobFilter){
      console.log("jobFilter : ", jobFilter)
      this.$store.dispatch("getJobsByNameAndCityId", jobFilter)
      //this.$router.push({name: 'AllJobs', params: { jobTitle }})
    },

    navigateToLogin(){
      /*this.$bvToast.toast(" ", {
        toaster: "b-toaster-top-center",
        variant: "danger",

        solid: true,
      });*/
      this.$auth.logout();
      this.handleClickSignOut();

    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        this.isSignIn = this.$gAuth.isAuthorized;
        // console.log("isSignIn", this.$gAuth.isAuthorized);
      } catch (error) {
        console.error(error);
      }
    },
    w3_open: function() {
      document.getElementById("mySidebar").style.display = "block";

      document.getElementById("btn-open-sidebar").style.display = "none";
      document.getElementById("btn-close-sidebar").style.display = "block";
    },
    w3_close: function() {
      document.getElementById("mySidebar").style.display = "none";

      document.getElementById("btn-open-sidebar").style.display = "block";
      document.getElementById("btn-close-sidebar").style.display = "none";
    },


  },
};
</script>

<style scoped>
svg{fill:#ffffff !important}

#signinicon{
  margin-top: -11px;
  font-size: larger;
}
@media screen and (max-width: 720px) {
  #signinicon{
    margin-top: 0px;
  font-size: larger;
}
}
</style>

