<template>
  <div class="w-100 p-0 m-0" :key="componentKey">
<!--    <div class="custom-modal w3-animate-opacity" id="cpModal">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="custom-row pt-2 pl-3 pr-3">
            <h5 class="modal-title flex-grow-1" id="exampleModalLabel">{{ title }}</h5>
            <button type="button" class="close" @click.prevent="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


          </div>


        </div>
      </div>
    </div>-->

    <div v-if="profileStrengthSection" class="w-100 text-center" style="position: relative">

      <div class="custom-profile-heading mb-4">
        <span class=" custom-underline w-100 pb-2">
          Profile Strength
        </span>
      </div>
      <div class="mt-3 custom-progress-bar">
        <vue-ellipse-progress

            :progress="profileStrength"

            color="#EA216C"


            :thickness="15"
            emptyThickness="8%"
            fontSize="3rem"



        >
          <span slot="legend-value">%</span>
          <!--          <p slot="legend-caption">GOOD JOB</p>-->


        </vue-ellipse-progress>
      </div>



    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name:"ProfileStrengthComponent",
  data() {
    return {




      employeelist:null,
      employeeSkill:null,
      employeeExperience:null,
      employeeEducation:null,
      profileStrength:0,
      componentKey:0,

      empProfileStrength:false,
      empExperienceStrength:false,
      empEducationStrength:false,
      empSkillStrength:false,


    };
  },

  props:{
    title:{
      type: String
    },
    profileStrengthSection:{
      type: Boolean
    },
    rerenderComponentKey:{
      type:Number
    },




  },
  mounted() {
    this.getAll();


  },
  methods:{
    close(){
      this.$emit('toggle-modal');
    },


    getAll(){
      this.rerender();
      this.profileStrength = 0;
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            this.employeelist = res.data.employee;
            console.log("employeelist : ", this.employeelist);
            //this.employeeId = this.employeelist.employeeId;




            this.employeeSkill = res.data.eMPSkills;
            console.log("employeeSkill : ", this.employeeSkill);
            this.employeeExperience = res.data.eMPExperiences;



            this.employeeEducation = res.data.empEducations;
            console.log("employeeEducation : ", this.employeeEducation);


            this.CalculatePercentage();

            this.postProfileStrength();




          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    CalculatePercentage() {

      //Employee

      let profilePercentUponSignUp = 50;
      let profilePercent = 40;
      // let expPercent = 20;
      // let eduPercent = 20;
      let skillPercent = 10;

      let percent = 0;

      if(this.employeelist != {}){
        if((this.employeelist.fullName != "" || this.employeelist.fullName != null) && (this.employeelist.mobileNumber != "" || this.employeelist.mobileNumber != null)){
          percent += profilePercentUponSignUp;
          console.log("percent in signup : ", percent)
        }

        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);

        this.strengthState();
        // if (this.employeelist.fatherName != "" && this.employeelist.fatherName != null && this.employeelist.cnic != "" && this.employeelist.dateofBirth != null && this.employeelist.gender != 0 && this.employeelist.gender != null) {
        // && this.employeelist.maritalStatusId != 0 && this.employeelist.maritalStatusId != null && this.employeelist.departmentId != 0 && this.employeelist.departmentId != null && this.employeelist.cityName != 0 && this.employeelist.cityName != null
        if (this.employeelist.gender != 0 && this.employeelist.gender != null) {
          percent += profilePercent;
          this.empProfileStrength = true;
          console.log("percent in Profile : ", percent)
        }
        this.profileStrength = Math.ceil(percent);
        console.log("total after Employee section : ", this.profileStrength);

        this.strengthState();

      }
      else{
        this.empProfileStrength = false;
      }
      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);
      /*console.log("total after Employee section : ", profilePercent);
      if(profilePercent > 40){
        this.empProfileStrength = true;
      }
      else{
        this.empProfileStrength = false;
      }*/
      this.strengthState();



      // if(this.employeeExperience.length != 0){
      //   percent += expPercent;
      //   this.empExperienceStrength = true;
      // }
      // else {
      //   this.empExperienceStrength = false;
      // }
      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Experience section : ", this.profileStrength);

      // this.strengthState();



      // if(this.employeeEducation.length != 0){
      //   percent += eduPercent;
      //   this.empEducationStrength = true;
      // }
      // else {
      //   this.empEducationStrength = false;
      // }
      // this.profileStrength = Math.ceil(percent);
      // console.log("total after Employee Education section : ", this.profileStrength);

      // this.strengthState();



      if(this.employeeSkill.length != 0){
        percent += skillPercent;
        this.empSkillStrength = true;
      }
      else {
        this.empSkillStrength = false;
      }
       this.profileStrength = Math.ceil(percent);
       console.log("total after Employee Skill section : ", percent);

      console.log(this.empProfileStrength, this.empExperienceStrength, this.empEducationStrength, this.empSkillStrength);
      this.strengthState();

    },

    strengthState(){
      this.$emit("strength-state", this.empProfileStrength, this.empExperienceStrength, this.empEducationStrength, this.empSkillStrength);
      this.postProfileStrength();
    },
      postProfileStrength(){
      this.$emit('get-strength', this.profileStrength);
    },
    rerender() {
      this.componentKey += 1;
    },
  }
}
</script>
