<template>
  <div class="jumbotron text-center">
    <h2 class=" font-weight-bold">This Page is Currently in Maintenance Mode...</h2>
  </div>

</template>
<script>
export default {
  name: "PurchaseHistory",
  data() {
    return{

    };
  },
}
</script>