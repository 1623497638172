import Vue from 'vue';
import "jquery";
import "popper.js";
import "bootstrap";

import VueEllipseProgress from "vue-ellipse-progress";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueApexCharts from 'vue-apexcharts'
import VueTelInput from 'vue-tel-input';



// Vue.use(VueApexCharts)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueEllipseProgress );
Vue.use(VueTelInput);
