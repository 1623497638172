<template>
  <div class="container">
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="license-agreement-tab"
                  data-toggle="tab"
                  href="#license-agreement"
                  role="tab"
                  aria-controls="license-agreement"
                  aria-selected="true"
              >
                <span> End-User License Agreement </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="license-agreement"
            role="tabpanel"
            aria-labelledby="license-agreement-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body text-justify">

                  Updated at 2021-01-04

                  <h5 class="font-weight-bold">Definitions and key terms</h5>
                  To help explain things as clearly as possible in this Eula, every time any of these terms are referenced, are strictly defined as:
                  <ul>
                    <li>
                      <b>Cookie:</b> small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information
                    </li>
                    <li>
                      <b>Company:</b> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road Lahore that is responsible for your information under this Privacy Policy.
                    </li>
                    <li>
                      <b>Country:</b> where Shemeanswork or the owners/founders of Shemeanswork are based, in this case is Pakistan
                    </li>

                    <li>
                      <b>Device:</b> any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ‘Shemeanswork and use the services.
                    </li>
                    <li>
                      <b>Service:</b> refers to the service provided by Shemeanswork as described in the relative terms (if available) and on this platform.
                    </li>
                    <li>
                      <b>Third-party service:</b> refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.
                    </li>
                    <li>
                      <b>Website:</b> Shemeanswork's site, which can be accessed via this URL:
                      <a class="text-decoration-none w3-hover-text-gray" href="https://www.shemeanswork.com" target="_blank">
                        <!--              <span>Phone: </span> -->
                        <span class="custom-user-contacts">https://www.shemeanswork.com</span>
                      </a>.
                    </li>
                    <li>
                      <b>You:</b> a person or entity that is registered with Shemeanswork to use the Services including a personal.
                    </li>

                  </ul>

                  <h5 class="font-weight-bold">Introduction</h5>
                  <p>This End User License Agreement (the “Agreement’) is a binding agreement between you ("End User’ "you" or “your’) and LBS Pvt LTD (Company’, “we", ‘us’ or “our’). This Agreement governs the relationship between you and us, and your use of the Company ‘Shemeanswork. Throughout this Agreement, End User and Company may each be referred to as a “Party” or collectively, the “Parties” If you are using the website on behalf of your employer or other entity (an “Organization’) for whose benefit you utilize the website or who owns or otherwise controls the means through which you utilize or access the website, then the terms “End User’, "you", and “your” shall apply collectively to you as an individual and to the Organization. If you use, or purchase a license or to, the website on behalf of an Organization, you hereby acknowledge, warrant, and covenant that you have the authority to 1) purchase a license to the website on behalf of the Organization; 2) bind the Organization to the terms of this Agreement.</p>
                  <p>By downloading, installing, accessing, or using the website you: (a) affirm that you have all of the necessary permissions and authorizations to access and use the website; (b) if you are using the website pursuant to a license purchased by an organization, that you are authorized by that organization to access and use the website(c) acknowledge that you have read and that you understand this agreement; (d) represent that you are of sound mind and of legal age (18 years of age or older) to enter into a binding agreement; and (e) accept and agree to be legally bound by the terms and conditions of this agreement.</p>
                  <p>If you do not agree to these terms, do not download, install, access, or use the software. if you have already downloaded the software, delete it from your computing device.</p>
                  <p>‘The Application is licensed, not sold, to you by Shemeanswork for use strictly in accordance with the terms of this Agreement.</p>
                  <h5 class="font-weight-bold">License</h5>
                  <p>‘Subject to the terms of this Agreement and, if applicable, those terms provided in the License Agreement, Shemeanswork grants you a limited, non-exclusive, perpetual, revocable, and non-transferable license to:</p>
                  <ol type="a">
                    <li>
                      download, install and use the Software on one (1) Computing Device per single user license that you have purchased and been granted. If you have multiple Computer Devices in which you wish to use the Software, you agree to acquire a license for the number of devices you intend to use;
                    </li>
                    <li>
                      access, view, and use on such Computing Device the End User Provided Materials made available in or otherwise accessible through the Software, strictly in accordance with this Agreement, and any other terms and conditions applicable to such End User Provided Materials;
                    </li>
                    <li>
                      install and use the trial version of the Software on any number of Computing Devices for a trial period of fifteen (15) unique days after installation
                    </li>
                    <li>
                      receive updates and new features that become available during the one (1) year period from the date on which you purchased the license to the Software
                    </li>

                  </ol>

                  <h5 class="font-weight-bold">Restrictions</h5>
                  You agree not to, and you will not permit others to:
                  <ul>
                    <li>
                      License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the Application ‘or make the Application available to any third party.
                    </li>
                    <li>
                      Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the Application.
                    </li>
                    <li>
                      Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of Shemeanswork or its
                    </li>
                    <li>
                      affiliates, partners, suppliers or the licensors of the Application,
                    </li>
                  </ul>

                  <h5 class="font-weight-bold">Intellectual Property</h5>
                  <p>All intellectual property rights, including copyrights, patents, patent disclosures and inventions (whether patentable or not), trademarks service marks, trade secrets, know-how and other confidential information, trade dress, trade names, logos, corporate ‘names and domain names, together with all of the good will associated there with, derivative works and all other rights (collectively, “Intellectual Property Rights") that are part of the Software that are otherwise owned by Shemeanswork shall always remain the exclusive property of Shemeanswork (or of its suppliers or licensors, if and when applicable). Nothing in this Agreement grants you (or any Organization) a license to Shemeanswork’s Intellectual Property Rights.</p>
                  <p>You agree that this is Agreement conveys a limited license to use Shemeanswork's Intellectual Property Rights, solely as part of the Software (and not independently of it), and only for the effective Term of the license granted to you hereunder. Accordingly, your use of any of Shemeanswork’s Intellectual Property Rights independently of the Software or outside the scope of this Agreement shall be considered an infringement of Shemeanswork’s Intellectual Property Rights. This shall not limit, however, any claim Shemeanswork ‘may have for a breach of contract in the event you breach a term or condition of this Agreement. You shall use the highest standard of care to safeguard all Software (including all copies thereof) from infringement, misappropriation, theft, misuse or unauthorized access. Except as expressly granted in this Agreement, Shemeanswork reserves and shall retain all rights, title, and interest in the ‘Software, including all copyrights and copyrightable subject matter, trademarks and trademark able subject matter, patents and patentable subject matter, trade secrets, and other intellectual property rights, registered, unregistered, granted, applied-for, or both ‘now in existence or that may be created, relating to the thereto.</p>
                  <p>You (or the Organisation, if and as applicable) shall retain ownership of all Intellectual Property Rights in and to the work products that you create through or with the assistance of the Software.</p>
                  <h5 class="font-weight-bold">Your Suggestions</h5>
                  <p>Any feedback, comments, ideas, improvements or suggestions (collectively, "Suggestions”) provided by you to Shemeanswork with respect to the Application shall remain the sole and exclusive property of Shemeanswork</p>
                  <p>‘Shemeanswork shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</p>

                  <h5 class="font-weight-bold">Modifications to Application</h5>
                  ‘Shemeanswork reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to you.
                  <h5 class="font-weight-bold">Updates to Application</h5>
                  <p>‘Shemeanswork may from time to time provide enhancements or improvements to the features/ functionality of the Application, which ‘may include patches, bug fixes, updates, upgrades and other modifications ("Updates").</p>
                  <p>Updates may modify or delete certain features and/or functionalities of the Application. You agree that Shemeanswork has no</p>
                  <p>obligation to (i) provide any Updates, or (ji) continue to provide or enable any particular features and or functionalities of the</p>
                  <p>Application to you further agree that all Updates will be () deemed to constitute an integral part of the Application, and (ii) subject to the terms and conditions of this Agreement.</p>
                  <h5 class="font-weight-bold">Third-Party Services</h5>
                  The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services ("Third- Party Services”) You acknowledge and agree that Shemeanswork shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Shemeanswork does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services. Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.
                  <h5 class="font-weight-bold">Term and Termination</h5>
                  <p>This Agreement shall remain in effect until terminated by you or Shemeanswork.</p>
                  <p>‘Shemeanswork may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.</p>
                  <p>This Agreement will terminate immediately, without prior notice from Shemeanswork, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the Application and all copies thereof from your ‘computer.</p>
                  <p>Upon termination of this Agreement, you shall cease all use of the Application and delete all copies of the Application from your ‘computer.</p>
                  <p>Termination of this Agreement will not limit any of Shemeanswork's rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>

                  <h5 class="font-weight-bold">Indemnification</h5>
                  You agree to indemnify, defend and hold harmless Shemeanswork and its officers, directors, employees, agents, affiliates, successors, and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees, arising from ‘or relating to: i) your use or misuse of the Software: ii) your failure to comply with any applicable law, regulation, or government directive; ii) your breach of this Agreement; or iv) your agreement or relationship with an Organization (if applicable) or any third party. Furthermore, you agree that Shemeanswork assumes no responsibility for the information or content you submit or make available through this Software or the content that is made available to you by third parties.
                  <h5 class="font-weight-bold">No Warranties</h5>
                  <p>‘The Application is provided to you "AS IS" and "AS AVAILABLE” and with all faults and defects without warranty of any kind. To the ‘maximum extent permitted under applicable law, Shemeanswork, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, tile and non- infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without imitation to the foregoing, Shemeanswork provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected</p>
                  <p>Without limiting the foregoing, neither Shemeanswork nor any Shemeanswork's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free: (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, sent from or on behalf of Shemeanswork are free of viruses, scripts, trojan horses, worms, malware, time bombs or other harmful components</p>
                  <p>‘Some jurisdictions do not allow the exclusion of or of a consumer, so some or all of the above exclusions and limitations may not apply to you.</p>

                  <h5 class="font-weight-bold">Limitation of Liability</h5>
                  <p>Notwithstanding any damages that you might incur, the entire liability of Shemeanswork and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you for the Application.</p>
                  <p>To the maximum extent permitted by applicable law, in no event shall Shemeanswork or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if Shemeanswork or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                  <h5 class="font-weight-bold">Severability</h5>
                  If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                  <h5 class="font-weight-bold">Waiver</h5>
                  No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall ‘operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern
                  <h5 class="font-weight-bold">Amendments to this Agreement</h5>
                  ‘Shemeanswork reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion by continuing to access or use our Application after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Application
                  <h5 class="font-weight-bold">Governing Law</h5>
                  The laws of Pakistan, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.


                  <h5 class="font-weight-bold">Changes to this agreement</h5>
                  <p>We reserve the exclusive right to make changes to this Agreement from time to time. Your continued access to and use of the website constitutes your agreement to be bound by, and your acceptance of, the terms and conditions posted at such time. You acknowledge and agree that you accept this Agreement (and any amendments thereto) each time you load, access, or use the website. Therefore, we encourage you to review this Agreement regularly</p>
                  <p>If, within thirty (30) days of us posting changes or amendments to this Agreement, you decide that you do not agree to the updated terms, you may withdraw your acceptance to the amended terms by providing us with written notice of your withdrawal. Upon providing us with the written notice of the withdrawal of your acceptance, you are no longer authorized to access or use the website.</p>
                  <h5 class="font-weight-bold">Equitable Relief</h5>
                  You acknowledge and agree that your breach of this Agreement would cause Shemeanswork irreparable harm for which money damages alone would be inadequate. In addition to damages and any other remedies to which Shemeanswork may be entitled, you acknowledge and agree that we may seek injunctive relief to prevent the actual, threatened or continued breach of this Agreement.
                  <h5 class="font-weight-bold">Headings</h5>
                  ‘The headings in this Agreement are for reference only and shall not limit the scope of, or otherwise affect, the interpretation of this Agreement.

                  <h5 class="font-weight-bold">Geographic Restrictions</h5>
                  ‘The Company is based in Pakistan and provided for access and use primarily by persons located in Pakistan, and is maintains compliance with Pakistan laws and regulations. If you use the website from outside Pakistan, you are solely and exclusively responsible for compliance with local laws.

                  <h5 class="font-weight-bold">Limitation of Time to File Claims</h5>
                  ‘Any cause of action or claim you may have arising out of or relating to this agreement or the website must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred,

                  <h5 class="font-weight-bold">Entire Agreement</h5>
                  <p>‘The Agreement constitutes the entire agreement between you and Shemeanswork regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between you and Shemeanswork.</p>
                  <p>You may be subject to additional terms and conditions that apply when you use or purchase other Shemeanswork's services, which ‘Shemeanswork will provide to you at the time of such use or purchase.</p>
                  <h5 class="font-weight-bold">Contact Us</h5>
                  Don't hesitate to contact us if you have any questions.
                  <ul>
                    <li>Via
                      <a class="text-decoration-none w3-hover-text-gray" href="mailto:info@shemeanswork.com">
                        <span><i class="fas fa-envelope fa-md fa-fw mr-2"></i></span>
                        <!--            <span>Email:</span>-->



                        <span class="custom-user-contacts">info@shemeanswork.com</span>
                      </a>
                    </li>
                    <li>Via
                      <a class="text-decoration-none w3-hover-text-gray" href="tel:0092 423 7243810">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
                        <!--              <span>Phone: </span> -->
                        <span class="custom-user-contacts">0092 423 7243810</span>
                      </a>
                    </li>
                    <li>Via
                      <a class="text-decoration-none w3-hover-text-gray" href="https://www.shemeanswork.com" target="_blank">
                        <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                        <!--              <span>Phone: </span> -->
                        <span class="custom-user-contacts">https://www.shemeanswork.com</span>
                      </a>
                    </li>
                    <li>Via <span><i class="fas fa-map-marker fa-md fa-fw mr-2"></i></span> 23 The Mall Road Lahore</li>
                  </ul>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "EndUserLicenseAgreement",
  components:{

  },
}
</script>