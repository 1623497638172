<template>
  <div id="app">
    <div v-if="$auth.ready()">
      <Topbar class="mobileTopBar sticky-top" />
      <Sidebar />
      <div class="webTopBar">
        <Topbar />
      </div>
      <MainMenu class="webTopBar" />
      <BannerHeader />
      <EmployerMenu class="webTopBar" v-if="$auth.check()" />

      <Sidebar />
      <Content />
      <Footer :componentId="topBarId" @scrollTo-top="scrollTo()" />
    </div>
    <div v-else>
      <Topbar class="mobileTopBar sticky-top" />
      <Sidebar />
      <div class="webTopBar">
        <Topbar />
      </div>
      <MainMenu class="webTopBar" />
      <BannerHeader />
      <EmployerMenu class="webTopBar" v-if="$auth.check()" />

      <Sidebar />
      <Content />
      <Footer :componentId="topBarId" @scrollTo-top="scrollTo()" />
    </div>
    <!--    <div v-else>-->
    <!--      <Topbar />-->
    <!--      &lt;!&ndash;    <Header></Header>&ndash;&gt;-->

    <!--      <Sidebar />-->
    <!--      <Content />-->
    <!--      <Footer />-->
    <!--    </div>-->
  </div>
</template>

<script>
import Content from '@/components/Content.vue'
import Sidebar from '@/components/Sidebar.vue'
import Topbar from './components/Topbar'
import BannerHeader from "./components/BannerHeader";
import MainMenu from "./components/MainMenu";
import EmployerMenu from "./components/EmployerMenu";
//import Header from './components/Header.vue'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Content,
    //Header,
    Topbar,
    BannerHeader,
    MainMenu,
    EmployerMenu,

    Sidebar,
    Footer
  },
  data(){
    return{
      topBarId:"topbar_id",
  
    }
  },
  created() {
    let that = this;
    let checkGauthLoad = setInterval(function () {
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      if (that.isInit) clearInterval(checkGauthLoad);
    }, 1000);
  },
  methods:{
    scrollTo(){
      let yOffset = -245;
      /*if(id.match("accordion")){
        yOffset = -250;
      }*/

      let element = null;
      element = document.getElementById("app");
      console.log("element id : ",element)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      console.log("element position : ",y)
      //$("#content_id").animate({ scrollBottom: y }, "fast");
      window.scrollTo({top: y, behavior: 'smooth'});


    },
  }
}
</script>

<style>
</style>
