<template>
  <div class="text-center">
    <h2 class="mt-3 mb-0">Success !</h2>
    <p class=" mb-1">Your account has been verified. Click below button to use our site.</p>
    <div class="d-inline-block w-100">
      <router-link to="/sign-in" class="btn btn-primary mt-3">Back to Home</router-link>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  name:"EmailVerification",
  data(){
    return{
      userId:"",
      info:null
    }
  },
  methods:{
    getVerification(){
      this.userId = this.$route.params.id;

      console.log("userid : ", this.userId);
        if(this.userId != ""){
          let model = {

          };
          axios
              .post("/api/Employee/VerifyEmployeeAccount", this.userId)
              .then(res => {
                // code that we will 'try' to run
                this.info = res.data;

                console.log("Add Response portion");

                console.log("response : ", this.info);
              })
              .catch(error => {
                console.log(error);
                this.errored = true;
              })
              .finally(() => {
                if (this.errored) {

                  console.log("Error : Sorry there is no response");
                  // this.$bvToast.toast("Sorry there is no response ", {
                  //   toaster: "b-toaster-top-center",
                  //   variant: "danger",

                  //   solid: true,
                  // });
                } else {
                  this.$bvToast.toast("Verified Successfully ", {
                    toaster: "b-toaster-top-center",
                    variant: "success",

                    solid: true
                  });
                  this.$router.push({name:"SignIn"});


                }
              });

        }
        else {
          this.$router.push({name:"SignUp"});
        }
      }

  },
  mounted() {
    this.getVerification();
  }
}
</script>