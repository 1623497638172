<template>
  <div class="container">
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="disclaimer-tab"
                  data-toggle="tab"
                  href="#disclaimer"
                  role="tab"
                  aria-controls="disclaimer"
                  aria-selected="true"
              >
                <span> Disclaimer </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="disclaimer"
            role="tabpanel"
            aria-labelledby="disclaimer-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body text-justify">




                    Updated at 2021-01-04
                    <h5 class="font-weight-bold">Definitions and key terms</h5>
                    To help explain things as clearly as possible in this Return & Refund Policy, every time any of these terms are referenced, are strictly defined as:
                    <ul>

                      <li>
                        <b>Company:</b> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to LBS Pvt LTD, 23 The Mall Road Lahore that is responsible for your information under this Privacy Policy.
                      </li>

                      <li>
                        <b>Customer:</b> refers to the company, organization or person that signs up to use the Shemeanswork Service to manage the relationships with your consumers or service users.
                      </li>
                      <li>
                        <b>Device:</b> any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ‘Shemeanswork and use the services.
                      </li>

                      <li>
                        <b>Service:</b> refers to the service provided by Shemeanswork as described in the relative terms (if available) and on this platform.
                      </li>

                      <li>
                        <b>Website:</b> Shemeanswork's site, which can be accessed via this URL:
                        <a class="text-decoration-none w3-hover-text-gray" href="https://www.shemeanswork.com" target="_blank">
                          <!--              <span>Phone: </span> -->
                          <span class="custom-user-contacts">https://www.shemeanswork.com</span>
                        </a>.
                      </li>
                      <li>
                        <b>You:</b> a person or entity that is registered with Shemeanswork to use the Services including a personal.
                      </li>

                    </ul>


                    <h5 class="font-weight-bold">Return & Refund Policy</h5>
                    <p>‘Thanks for shopping at Shemeanswork. We appreciate the fact that you like to buy the stuff we build, we also want to make sure you have a rewarding experience while you're exploring, evaluating, and purchasing our products.</p>
                    <p>‘As with any shopping experience, there are terms and conditions that apply to transactions at Shemeanswork. We'll be as brief as</p>
                    <p>‘our attorneys will allow. The main thing to remember is that by placing an order or making a purchase at Shemeanswork, you agree to the terms set forth below along with Shemeanswork’s Privacy Policy.</p>
                    <p>If there's something wrong with the product you bought, or if you are not happy with it, you have () to issue a refund and return your item.</p>
                    If you would like to return a product, the only way would be if you follow the next guidelines:
                    <ul>
                      <li>
                        The package needs to come back with the original receipt of the purchase.
                      </li>
                      <li>
                        The product has to be in the packaging we sent in the first place.
                      </li>
                      <li>
                        The product has to be damage free, if we find any damage on the product we will cancel your refund immediately.
                      </li>
                    </ul>

                    <h5 class="font-weight-bold">Refunds</h5>
                    <p>We at Shemeanswork commit ourselves to serving our customers with the best products. Every single product that you choose is thoroughly inspected, checked for defects and packaged with utmost care. We do this to ensure that you fall in love with our products.</p>
                    <p>Sadly, there are times when we may not have the product(s) that you choose in stock, or may face some issues with our inventory ‘and quality check. In such cases, we may have to cancel your order. You will be intimated about it in advance so that you don't have to worry unnecessarily about your order. If you have purchased via Online payment (not Cash on Delivery), then you will be refunded ‘once our team confirms your request.</p>
                    <p>We carry out thorough quality check before processing the ordered item. We take utmost care while packing the product. At the same time we ensure that the packing is good such that the items won't get damaged during transit. Please note that Shemeanswork is not liable for damages that are caused to the items during transit or transportation.</p>
                    <p>We will revise your returned product as soon as we receive it and if it follows the guidelines addressed above, we will proceed to issue a refund of your purchase. Your refund may take a couple of days to process but you will be notified when you receive your money.</p>
                    <h5 class="font-weight-bold">Shipping</h5>
                    ‘Shemeanswork is not responsible for return shipping costs. Every shipping has to be paid by the customer, even if the item had free shipping in the first place, the customer has to pay for the shipping in return.!
                    <h5 class="font-weight-bold">Your Consent</h5>
                    By using our platform, registering an account, or making a purchase, you hereby consent to our Return & Refund Policy and agree to its terms.

                    <h5 class="font-weight-bold">Changes To Our Return & Refund Policy</h5>
                    ‘Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use the Service, you will be bound by the updated Return & Refund Policy. If you do not want to agree to this or any updated Return & Refund Policy, you can delete your account.

                    <h5 class="font-weight-bold">Contact Us</h5>
                    If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.
                    <ul>
                      <li>Via
                        <a class="text-decoration-none w3-hover-text-gray" href="mailto:info@shemeanswork.com">
                          <span><i class="fas fa-envelope fa-md fa-fw mr-2"></i></span>
                          <!--            <span>Email:</span>-->



                          <span class="custom-user-contacts">info@shemeanswork.com</span>
                        </a>
                      </li>
                      <li>Via
                        <a class="text-decoration-none w3-hover-text-gray" href="tel:0092 423 7243810">
                          <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
                          <!--              <span>Phone: </span> -->
                          <span class="custom-user-contacts">0092 423 7243810</span>
                        </a>
                      </li>
                      <li>Via
                        <a class="text-decoration-none w3-hover-text-gray" href="https://www.shemeanswork.com" target="_blank">
                          <span><i class="fas fa-globe fa-md fa-fw mr-2"></i></span>
                          <!--              <span>Phone: </span> -->
                          <span class="custom-user-contacts">https://www.shemeanswork.com</span>
                        </a>
                      </li>
                      <li>Via <span><i class="fas fa-map-marker fa-md fa-fw mr-2"></i></span> 23 The Mall Road Lahore</li>
                    </ul>







                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "Disclaimer",
  components:{

  },
}
</script>