<template>
  <div class="m-5">

    <div class="container">
      <!--content area start-->
      <div class="mt-2">
        <!--      <div class="custom-row">
                <router-link :to="{ name: 'Employees' }">
                  <span class="btn custom-back-btn custom-btn-gotopage"
                  ><arrow-left-icon
                      size="1.5x"
                      class="custom-class"
                  ></arrow-left-icon>
                  </span>
                </router-link>

              </div>-->

        <div
            class="custom-row custom-options-menu justify-content-between align-items-center"
        >
          <div class="customHeading">
            <nav>
              <div
                  class="nav nav-tabs custom-nav-tabs"
                  id="nav-tab"
                  role="tablist"
              >
                <a
                    class="nav-item nav-link custom-nav-link custom-nav-heading active"
                    id="employee-detail-tab"
                    data-toggle="tab"
                    href="#employee-detail"
                    role="tab"
                    aria-controls="employee-detail"
                    aria-selected="true"
                >

                  <span v-if="$route.params.employerType == 0"> Top Employers </span>
                  <span v-else> All Employers </span>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="custom-accordion-separator">
        <div class="custom-separator"></div>
      </div>
      <div :key="componentKey">
        <div  class=" row-slider-item" v-for="jt in list" :key="jt.employerCompanyId">
          <div class="row no-gutters align-items-center">
            <div class="col-sm-9">
<!--              <router-link
                  class="text-decoration-none"
                  :to="{ name: 'JobDetail',
                               params: { id: jt.jobId }
                             }"
                  title="Job Detail">

              </router-link>-->
              <div class="row no-gutters align-items-center">

                <div class="col-sm-2">
                  <div class="style-left-img">
                    <span class="b-avatar badge-info rounded-circle">
                    <span  class="b-avatar-img">
                      <span v-if="jt.companyLogoAddress != null && jt.companyLogoAddress != ''">
                         <img :src="url+jt.companyLogoAddress">
                       </span>
                       <span v-else>

                            <img src="../../assets/images/no-image.png" >


                       </span>
                      <!--                                  {{ usernamefirstletter }}-->
                      <!--                        <img src="../assets/images/avatar-2.jpg">-->

                    </span>

                  </span>
<!--                    <img v-if="jt.companyLogoAddress !== null" :src="url+jt.companyLogoAddress" alt="Avatar" class=" w3-circle" style="width:60px">
                    <img v-else src="../../assets/images/company.jpg" alt="Avatar" class=" w3-circle" style="width:60px">-->

                  </div>
                </div>
                <div class="col-sm-10">


                  <div class="style-company custom-muted-text style-text-14">{{ jt.companyName }}</div>
                  <div class="style-job-metas">

                    <div class="location custom-muted-text style-text-14">
                        <span>
                                  <i
                                      class="text-second fa fa-map-marker"
                                      aria-hidden="true"
                                      data-v-3c8a640f=""
                                  ></i>
                                </span>
                      <span>{{ jt.cityName }}, {{ jt.countryName }}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="col-sm-3">

            </div>
          </div>
        </div>
      </div>

      <!--      <div class="w3-card-2 pt-3 pb-3 ml-auto mr-auto" style="width:100%;height: 30%;overflow: auto">
      &lt;!&ndash;        <header class="w3-container custom-row ">
                <h3>Applications</h3>
                <button class="btn btn-outline-secondary ml-auto">View All Applications</button>
              </header>
              <hr>&ndash;&gt;
              <div class="w3-container custom-row">

                <img src="../../assets/images/avatar-2.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                <div>
                  <p class="font-weight-bold text-info m-0">Profile Name</p>
                  <span>Job Name</span>
                </div>


                <div class="ml-auto">
                  <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                  <a href="#">Portfolio</a>
                </div>
              </div>
              <hr>
              <div class="w3-container custom-row">

                <img src="../../assets/images/avatar-2.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                <div>
                  <p class="font-weight-bold text-info m-0">Profile Name</p>
                  <span>Job Name</span>
                </div>


                <div class="ml-auto">
                  <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                  <a href="#">Portfolio</a>
                </div>
              </div>
              <hr>
              <div class="w3-container custom-row">

                <img src="../../assets/images/avatar-2.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                <div>
                  <p class="font-weight-bold text-info m-0">Profile Name</p>
                  <span>Job Name</span>
                </div>


                <div class="ml-auto">
                  <p class="font-weight-bold text-black m-0">4 Hours ago</p>
                  <a href="#">Portfolio</a>
                </div>
              </div>

            </div>-->

      <!--content area end-->
    </div>

  </div>
</template>
<script>
import axios from "axios";

import moment from "moment";
export default {
  name: "AllEmployers",
  components:{

  },
  data(){
    return{
      url:axios.defaults.baseURL,
      list:[],



      componentKey:0,

    };
  },
  methods:{
    getAll(){
      this.list = [];

      axios.get('/api/EmployerCompany/GetCompany')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }







            let list = res.data.data;

            let companies = []

            for (let job in list){
              if(list[job].isFeature === true){
                companies.push(list[job]);
              }

            }
            this.list = companies.slice().reverse();
            console.log("companies : ", this.list);

            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },

    rerender() {
      this.componentKey += 1;
    },
  },
  mounted() {
    this.getAll();
  }
}
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-nav-tabs {
  /* border-bottom: 3px solid #19aa4b !important; */
}

.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #EA216C;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>
