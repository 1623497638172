<template>
  <div class="container">
    <div class="mt-2">


      <div
          class="custom-row custom-options-menu justify-content-between align-items-center"
      >
        <div class="customHeading">
          <nav>
            <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
            >
              <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading active"
                  id="employee-detail-tab"
                  data-toggle="tab"
                  href="#employee-detail"
                  role="tab"
                  aria-controls="employee-detail"
                  aria-selected="true"
              >
                <span> Personal Information </span>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="custom-accordion-separator">
      <div class="custom-separator"></div>
    </div>

    <div class="mb-5">
      <div class="tab-content" id="nav-tabContent">
        <div
            class="tab-pane fade show active"
            id="employee-detail"
            role="tabpanel"
            aria-labelledby="employee-detail-tab"
        >
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <AddProfileDetail :is-graduate-section="true" :is-mums-return-to-work-section="true" :is-special-need-person-section="true"></AddProfileDetail>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import AddProfileDetail from "@/components/employeeComponents/AddProfileDetailComponent";
export default {
  name: "AddProfile",
  components:{
    AddProfileDetail
  },
}
</script>