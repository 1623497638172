<template>
  <div class="container">
    <div class="row mt-30">
      <div class="style-spacer"></div>
      <div class="col-md-12">
        <!-- <div class="w3-card openjobs-public p-3">
        <h3>Open Jobs </h3>
          <a href="#"> <p> Job 1 </p>
          <button class="btn btn-primary right"> Apply</button>
          </a>
          <a href="#"> <p> Job 1 </p></a>
          <a href="#"> <p> Job 1 </p></a>
          <a href="#"> <p> Job 1 </p></a>
          <a href="#"> <p> Job 1 </p></a>
          <hr>
          <a href="#"> <p> view all </p></a>

      </div> -->

        <div class="w3-card-2 pt-3 pb-3 ml-auto mr-auto" style="">
          <header class="w3-container custom-row">
            <h3>Package Purchase History</h3>
          </header>
          <hr />
          <div class="w3-container custom-row">
            <table class="table table-responsive table-bordered">
              <thead>
                <tr>
                  <th scope="col">Order Id</th>
                  <th scope="col">Package/Product Detail</th>
                  <th scope="col">Total Job Post Allowed</th>
                  <th scope="col">Total Jobs Posted</th>
                  <th scope="col">Purchase Date</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Purchase ID</th>
                  <th scope="col">Invoice #</th>
                  <th scope="col">Paid/Unpaid</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pac in packages" :key="pac.employerJobPostPackageId">
                  <td>{{ pac.employerJobPostPackageId }}</td>
                  <td>{{ pac.jobPostPackages.packageName }}</td>
                  <td v-if="pac.jobPostPackages.unlimitedPosting">Unlimited</td>
                  <td v-else>{{ pac.jobPostPackages.jobPostingLimit }}</td>
                  <td>{{ pac.totalPostedJobs }}</td>
                  <td>
                    {{
                      pac.employerJobPostPackages.purchaseDate
                        | formatDateAndTime
                    }}
                  </td>
                  <td>
                    {{
                      pac.employerJobPostPackages.expiryDate | formatDateAndTime
                    }}
                  </td>
                  <td style="min-width: 150px">
                    Regular: {{ pac.jobPostPackages.regularPrice }} PKR
                    <br />
                    Sale: {{ pac.jobPostPackages.salePrice }} PKR
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    {{
                      pac.jobPostPackages.status | formatEmpPackagePaymentStatus
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <iframe
            id="easypay-iframe"
            name="easypay-iframe"
            :src="srciFrame"
            width="100%"
            height="500px"
          ></iframe> -->
        </div>
      </div>

      <div class="style-spacer"></div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import CryptoJS from 'crypto-js';
import aesjs from 'aes-js'; 
// Object.definePrototype(Vue.prototype, '$aesjs', { value: aesjs });

export default {
  name: "PackageHistory",  components: {},
  data() {
    return {
      has_error: false,
      isDataFetched : false,
      isLoading: false,
              EmployerId: this.$auth.user().employeeId,

      packages : [],
      srciFrame : 'https://easypay.easypaisa.com.pk/tpg/?storeId=15973&orderId=1221&transactionAmount=10.0&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=&bankIdentificationNumber=&encryptedHashRequest=rTNcc%2Fe5Gk1qY4mhHVr89NZ%2BpdUiqD0k%2Fiv%2FIh9kYco75pzopBsOO6Q7aqm%2Bw%2F3YypPfRiq0Crwkh2rAviRo1yZsqVR8KxfzlBQSoZeGO64uw3qqtzsqLBr29OGhrIl7a7BBTSimBb9qIwXGQUXsr2bSEqatFpFpuacKFdzN0sFlGZ6CpXLX8mUYkRFAQO9J&merchantPaymentMethod=&postBackURL=https%3A%2F%2Fwww.google.com%2F&signature=',
      body: {
        employerCompanyId: 0,
        companyEmail: null,
        companyName: null,
        ceoName: null,
        employeeId: this.$auth.user().employeeId,
        industryId: 0,
        ownerShipTypeId: 0,
        companyDesciption: null,
        countryId: 106,
        cityId: 0,
        address: null,
        establishedInYear: 0,
        websiteUrl: null,
        contactNumber: null,
        googleMapIframe: null
      }
    };
  },
  computed: {},
  mounted() {
    // /EmployerCompany/GetEmployerCompany
    this.getProfileInfo()
    this.encryptData()


  },
  methods: {
     getProfileInfo(){
      axios.get('/api/EmployerJobPostPackage/GetAllJobPostPackagesByEmployeeId')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            this.packages = res.data.data;
            console.log("PACKAGES : ",  this.packages );


            // if(res.data == 0){
            //   this.body.employerCompanyId = resdata.employerCompanyId
            //   this.body.industryId = resdata.industryId
            //   this.body.cityId = resdata.cityId
            //   this.body.ownerShipTypeId = resdata.ownerShipTypeId
            //   this.body.countryId = resdata.countryId
            //   this.body.establishedInYear = resdata.establishedInYear
            //   this.body.companyEmail = resdata.companyEmail
            //   this.body.companyName = resdata.companyName
            //   this.body.ceoName = resdata.ceoName
            //   this.body.companyDesciption = resdata.companyDesciption
            //   this.body.address = resdata.address
            //   this.body.websiteUrl = resdata.websiteUrl
            //   this.body.contactNumber = resdata.contactNumber
            //   this.body.googleMapIframe = resdata.googleMapIframe
            // }
            // else {

            // }
            this.isDataFetched = true

            // });
          }).catch(error => {
        console.log(error);
      })
          .finally(() => {

          });
    },
    encryptData(){
      //console.log(CryptoJS)
      const d = new Date(); 
      // at the time of request, time should be selected
      var sampleString = "amount=5.0&orderRefNum=123&paymentMethod=InitialRequest&storeId=15530&timeStamp="+d.getFullYear()+"-"+d.getMonth()+"-"+d.getDate()+"T"+d.getHours()+":"+d.getMinutes()+":00";
      console.log(sampleString)
      //   var aesjs = CryptoJS
      //   const keyBuffer = aesjs.enc.Utf8.parse("CQR6L3SFOG7OV50P");
      //   const inputBuffer = aesjs.pad.Pkcs7.pad(aesjs.enc.Utf8.parse(sampleString));
      //   const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
      //   const encryptedBytes = escEcb.encrypt(inputBuffer);
      //   const encryptedData = Buffer.from(encryptedBytes).toString('base64');
      //   console.log("encryptData" , encryptedData)



      /////////////////////////////////////////////////////////
      var aesjs1 = aesjs
      console.log(aesjs1)
      const keyBuffer = aesjs1.utils.utf8.toBytes("CQR6L3SFOG7OV50P");
      const inputBuffer = aesjs1.padding.pkcs7.pad(aesjs1.utils.utf8.toBytes(sampleString));
      const escEcb = new aesjs1.ModeOfOperation.ecb(keyBuffer);
      const encryptedBytes = escEcb.encrypt(inputBuffer);
      const encryptedData = Buffer.from(encryptedBytes).toString('base64');
      console.log(encryptedData)
      var finalString = "https://easypay.easypaisa.com.pk/tpg/?storeId=15530&orderId=1221&transactionAmount=10.0&mobileAccountNo=&emailAddress=&transactionType=InitialRequest&tokenExpiry=&bankIdentificationNumber=&encryptedHashRequest=" + encryptedData + "&merchantPaymentMethod=&postBackURL=https%3A%2F%2Fwww.google.com%2F&signature="
      console.log('finallllllll', finalString)
    
    },
    postData() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      ///this.body.jobTitle = this.jobTitle;

      var app = this;
      this.body.industryId = Number(this.body.industryId)
      this.body.cityId = Number(this.body.cityId)
      this.body.ownerShipTypeId = Number(this.body.ownerShipTypeId)
      this.body.countryId = Number(this.body.countryId)
      this.body.establishedInYear = Number(this.body.establishedInYear)

       console.log(this.body)

       axios
            .post("/api/EmployerCompany/InsertEmployerCompany", this.body)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              this.isLoading = false
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",
                  title: "Company Profile",
                  solid: true
                });
                /*if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }*/

              }
            });



    },
  },
};

</script>
<style>
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border: 1px solid #8a8e9e;
  background: #1a1f31;
  color: white;
}
</style>
