<template>
  <div class="m-5 ml-auto mr-auto">
    <div class="container">
      <!--content area start-->
      <div class="container-fluid">
        <div class="custom-row">
          <router-link :to="{ name: 'EmployeeDashboard' }">
            <span class="btn custom-back-btn custom-btn-gotopage"
              ><arrow-left-icon
                size="1.5x"
                class="custom-class"
              ></arrow-left-icon>
            </span>
          </router-link>
          <h3 class="custom-owner-name text-capitalize"></h3>
        </div>

        <div
          class="
            custom-row custom-options-menu
            justify-content-between
            align-items-center
          "
        >
          <div class="customHeading">
            <nav>
              <div
                class="nav nav-tabs custom-nav-tabs"
                id="nav-tab"
                role="tablist"
              >
                <a
                  class="
                    nav-item nav-link
                    custom-nav-link custom-nav-heading
                    active
                  "
                  id="appliedJobs-tab"
                  data-toggle="tab"
                  href="#appliedJobs"
                  role="tab"
                  aria-controls="appliedJobs"
                  aria-selected="true"
                >
                  <span> Applied Jobs </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="shortlistedJobs-tab"
                  data-toggle="tab"
                  href="#shortlistedJobs"
                  role="tab"
                  aria-controls="shortlistedJobs"
                  aria-selected="false"
                >
                  <span> Shortlisted Jobs </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="hiredJobs-tab"
                  data-toggle="tab"
                  href="#hiredJobs"
                  role="tab"
                  aria-controls="hiredJobs"
                  aria-selected="false"
                >
                  <span> Hired </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="rejectedJobs-tab"
                  data-toggle="tab"
                  href="#rejectedJobs"
                  role="tab"
                  aria-controls="rejectedJobs"
                  aria-selected="false"
                >
                  <span> Rejected Jobs </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="interviews-tab"
                  data-toggle="tab"
                  href="#interviews"
                  role="tab"
                  aria-controls="interviews"
                  aria-selected="false"
                >
                  <span> Interviews </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="contactedJobs-tab"
                  data-toggle="tab"
                  href="#contactedJobs"
                  role="tab"
                  aria-controls="contactedJobs"
                  aria-selected="false"
                >
                  <span> Contacted Jobs </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="savedJobs-tab"
                  data-toggle="tab"
                  href="#savedJobs"
                  role="tab"
                  aria-controls="savedJobs"
                  aria-selected="false"
                  @click="getSavedJobs"
                >
                  <span> Saved Jobs </span>
                </a>
                <a
                  class="nav-item nav-link custom-nav-link custom-nav-heading"
                  id="expiredJobs-tab"
                  data-toggle="tab"
                  href="#expiredJobs"
                  role="tab"
                  aria-controls="expiredJobs"
                  aria-selected="false"
                  @click="getExpiredJobs"
                >
                  <span> Expired Jobs </span>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>

      <div class="custom-accordion-separator">
        <div class="custom-separator"></div>
      </div>

      <div class="container-fluid">
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="appliedJobs"
            role="tabpanel"
            aria-labelledby="appliedJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in jobsStatuses"
                  :key="job.jobApplicantId"
                >
                  <div
                    v-if="job.jobsStatus === 'Applied'"
                    class="mt-2 mb-2 row"
                  >
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Applied Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.appliedDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobsStatus }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="shortlistedJobs"
            role="tabpanel"
            aria-labelledby="shortlistedJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in jobsStatuses"
                  :key="job.jobApplicantId"
                >
                  <div
                    v-if="job.jobsStatus === 'ShortListed'"
                    class="mt-2 mb-2 row"
                  >
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Shortlisted Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.shortlistedDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobsStatus }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Remarks:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.shortListedRemark }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="hiredJobs"
            role="tabpanel"
            aria-labelledby="hiredJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in jobsStatuses"
                  :key="job.jobApplicantId"
                >
                  <div v-if="job.jobsStatus === 'Hired'" class="mt-2 mb-2 row">
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <span
                    v-if="
                              job.companyName != null &&
                              job.companyName != ''
                            "
                    >
                    <div class="col-md-11"  >
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Accepted Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.hiredDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobsStatus }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Remarks:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.hiredRemark }}</span>
                        </div>
                      </div>
                    </div>
                  </span>
                    <span v-else>
                            <img src="../../assets/images/Nodata.jpg" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="rejectedJobs"
            role="tabpanel"
            aria-labelledby="rejectedJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in jobsStatuses"
                  :key="job.jobApplicantId"
                >
                  <div
                    v-if="job.jobsStatus === 'Rejected'"
                    class="mt-2 mb-2 row"
                  >
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Rejected Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.rejectedDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobsStatus }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Remarks:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.rejectedRemark }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="interviews"
            role="tabpanel"
            aria-labelledby="interviews-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in jobsStatuses"
                  :key="job.jobApplicantId"
                >
                  <div
                    v-if="job.jobsStatus === 'SetupInterView'"
                    class="mt-2 mb-2 row"
                  >
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Interviewed Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{
                            job.setupInterviewDate | formatDateAndTime
                          }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Interview Type:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.interviewType }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Interview Address:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.interViewAddress }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobsStatus }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Remarks:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.setupInterViewRemark }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Message:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span class="text-justify">{{ job.message }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                              <hr>
                              <div class="w3-container custom-row">

                                <img src="../../assets/images/company.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                                <div>
                                  <p class="font-weight-bold w3-text-pink m-0">Job Name</p>
                                  <span>Full Time</span>
                                </div>


                                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more">More</button>
                                  </div>
                                </div>
                              </div>
                -->
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="contactedJobs"
            role="tabpanel"
            aria-labelledby="contactedJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in jobsStatuses"
                  :key="job.jobApplicantId"
                >
                  <div
                    v-if="job.jobsStatus === 'Contacted'"
                    class="mt-2 mb-2 row"
                  >
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Contacted Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.contactedDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobsStatus }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Remarks:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.contactedRemark }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                              <hr>
                              <div class="w3-container custom-row">

                                <img src="../../assets/images/company.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                                <div>
                                  <p class="font-weight-bold w3-text-pink m-0">Job Name</p>
                                  <span>Full Time</span>
                                </div>


                                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more">More</button>
                                  </div>
                                </div>
                              </div>
                -->
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="savedJobs"
            role="tabpanel"
            aria-labelledby="savedJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in savedJobs.slice().reverse()"
                  :key="job.jobApplicantId"
                >
                  <div class="mt-2 mb-2 row">
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <span
                            v-if="
                              job.companyLogoAddress != null &&
                              job.companyLogoAddress != ''
                            "
                          >
                            <img :src="url + job.companyLogoAddress" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Saved Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.saveDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>Saved</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                              <hr>
                              <div class="w3-container custom-row">

                                <img src="../../assets/images/company.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                                <div>
                                  <p class="font-weight-bold w3-text-pink m-0">Job Name</p>
                                  <span>Full Time</span>
                                </div>


                                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more">More</button>
                                  </div>
                                </div>
                              </div>
                -->
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="expiredJobs"
            role="tabpanel"
            aria-labelledby="expiredJobs-tab"
          >
            <div>
              <div
                class="w3-card pt-3 pb-3 ml-auto mr-auto"
                style="width: 100%; height: 30%; overflow: auto"
              >
                <div
                  class="w3-container"
                  v-for="job in expiredJobs.slice().reverse()"
                  :key="job.jobId"
                >
                  <div class="mt-2 mb-2 row">
                    <!--                    <img v-if=" job.companyLogoAddress !== ''" :src="url+job.companyLogoAddress" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px; height:60px">
                    <img v-else src="../../assets/images/no-image.png" alt="Avatar" class=" w3-left w3-circle w3-margin-right" style="width:60px">-->
                    <div class="col-md-1 align-self-center">
                      <span
                        class="b-avatar badge-info rounded-circle text-center"
                        style="
                          width: 4rem;
                          height: 4rem;
                          background-color: #fff;
                        "
                      >
                        <span class="b-avatar-img">
                          <!--                          companyLogoAddress-->
                          <span
                            v-if="
                              job.employeeImage != null &&
                              job.employeeImage != ''
                            "
                          >
                            <img :src="url + job.employeeImage" />
                          </span>
                          <span v-else>
                            <img src="../../assets/images/no-image.png" />
                          </span>
                        </span>
                      </span>
                    </div>

                    <div class="col-md-11">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Title:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobTitle }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Company Name:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.companyName }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Expiry Date:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>{{ job.jobExpiryDate | formatDate }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span class="font-weight-bold custom-text-color m-0"
                            >Job Status:
                          </span>
                        </div>
                        <div class="col-md-9">
                          <span>Expired</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--
                              <hr>
                              <div class="w3-container custom-row">

                                <img src="../../assets/images/company.jpg" alt="Avatar" class="w3-left w3-circle w3-margin-right" style="width:60px">
                                <div>
                                  <p class="font-weight-bold w3-text-pink m-0">Job Name</p>
                                  <span>Full Time</span>
                                </div>


                                <div class="ml-auto">
                                  <p class="font-weight-bold text-black mb-2">4 Hours ago</p>
                                  <div>
                                    <button class="btn btn-primary btn-apply mb-2">APPLY</button>
                                  </div>

                                  <div>
                                    <button class="btn btn-primary btn-freelance mb-2">Save</button>
                                  </div>
                                  <div>
                                    <button class="btn btn-primary btn-more">More</button>
                                  </div>
                                </div>
                              </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--content area end-->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { ArrowLeftIcon } from 'vue-feather-icons'
import moment from "moment";
Vue.use(VueAxios, axios);
export default {
  name:"EmployeeJobs",
  components:{
    ArrowLeftIcon
  },
  data(){
    return{
      url:axios.defaults.baseURL,
      jobsStatuses : [],
      shortListedJobs:[],
      appliedJobs:[],
      rejectedJobs:[],
      interviewedJobs:[],
      hiredJobs:[],
      contactedJobs:[],
      savedJobs:[],
      expiredJobs:[],
    };
  },
  mounted() {
    this.getAllJobsStatus();
    this.navigate();

  },
  methods:{
    getAllJobsStatus(){
      this.jobsStatuses = [];
      axios.get('/api/Job/GetAllJobStatus')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            let jobList = res.data.data;

            let list=[];

            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
            for(let job in jobList){
              if(moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                this.jobsStatuses.reverse().push(jobList[job]);
              }
            }

            //this.jobsStatuses = list.slice().reverse()
            console.log("jobsStatuses : ", this.jobsStatuses);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    getSavedJobs(){
      this.savedJobs = null;
      axios.get('/api/Job/GetSaveJobList')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.savedJobs = res.data.data;
            console.log("savedJobs : ", this.savedJobs);




            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    getExpiredJobs(){
      this.expiredJobs = [];


      axios.get('/api/Job/GetAllJobStatus')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/

            let jobList = res.data.data;
            let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
            for(let job in jobList){
              if(jobList[job].jobsStatus == "Applied" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') <= currentDate){
                this.expiredJobs.push(jobList[job]);
              }
            }

            console.log("expiredJobs : ", this.expiredJobs);







            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });
    },
    navigate(){
      if (this.$route.params.status === 1){
        $('#nav-tab a:nth-child(1)').tab('show')
      }
      else if (this.$route.params.status === 2){
        $('#nav-tab a:nth-child(2)').tab('show')
      }
      else if (this.$route.params.status === 3){
        $('#nav-tab a:nth-child(3)').tab('show')
      }
      else if (this.$route.params.status === 4){
        $('#nav-tab a:nth-child(4)').tab('show')
      }
      else if (this.$route.params.status === 5){
        $('#nav-tab a:nth-child(5)').tab('show')
      }
      else if (this.$route.params.status === 6){
        $('#nav-tab a:nth-child(6)').tab('show')
      }
      else if (this.$route.params.status === 7){
        this.getSavedJobs();
        $('#nav-tab a:nth-child(7)').tab('show')
      }
      else if (this.$route.params.status === 8){
        this.getExpiredJobs();
        $('#nav-tab a:nth-child(8)').tab('show')
      }
    }
  }
}
</script>
<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #ea216c;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>
